import React from 'react';
import { Modal } from 'react-bootstrap';

interface EmailDetailsModalProps {
  email: any;
  show: boolean;
  onHide: () => void;
}

const EmailDetailsModal: React.FC<EmailDetailsModalProps> = ({ email, show, onHide }) => {

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className='fs-3'>Détails d'email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className='text-info mb-5'>Sujet :</h3>
        <p className='ms-5'>
          <span className='fs-5 fw-bold'>
            {`${email?.subject}`}
          </span>
        </p>

        <hr className='hr-tag' />

        <h3 className='text-info mb-5'>Message :</h3>
        <p className='ms-5'>
          <span className='fs-5 fw-bold'>
            {`${email?.message}`}
          </span>
        </p>

      </Modal.Body>
    </Modal>
  );
};

export default EmailDetailsModal;