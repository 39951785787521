import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { CategorieProps, ReOrderCategorieProps } from '../../helpers/Props'
import { useApiRequest } from '../../helpers/ApiRequest'
import { DeleteNotify, FrCustomeErrorNorify } from '../../helpers/Toastify'
import { useReOrderCategorieContext } from '../../context/ReOrderCardsContext'
import ReOrderCategorieModal from '../CategorieOrder/ReOrderCategorieModal'

const CategorieList: React.FC = () => {

  const [categories, setCategories] = useState<CategorieProps[]>([]);
  const apiRequest = useApiRequest()
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { card, setCard } = useReOrderCategorieContext();

  const showOrderModal = () => {
    setShowModal(true);
  };

  const hideOrderModal = () => {
    setShowModal(false);
  };



  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: 'categories-with-parent',
        method: 'GET',
      });

      setCategories(response.data)
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };
  useEffect(() => {
    fetchData();
  }, [])

  const DeleteCategorie = async (categorieId: number) => {
    try {
      const response = await apiRequest({
        route: `categories/${categorieId}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Categorie');
        fetchData();
      }
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  let data = [];

  if (card.length > 0) {
    data = card
  } else {
    data = categories
  }

  const ReOrderData = data.map((item: ReOrderCategorieProps) => {
    return {
      id: item.id,
      title: item.title,
      orderNumber: item.orderNumber,
    };
  });


  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des Categorie</span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/categorie/add-categorie' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Categorie
          </Link>
          <button onClick={() => showOrderModal()} className='btn btn-dark btn-sm ms-2 fw-bold'  >
            <i className='bi bi-filter fs-4'></i>
            Change Order
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-300px rounded-start'>Image</th>
                <th className='ps-4 min-w-200px'>Nom de categorie</th>
                <th className='min-w-200px'>Description</th>
                <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${process.env.REACT_APP_API_UPLOAD}/category_images/${item.image}`}
                            className='w-100'
                            alt=''
                          />
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.title}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item.description ? item.description : 'Aucune Description'}
                      </span>
                    </td>
                    <td className='text-end'>
                      <Link to={`/categorie/update-categorie/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                        <i className='bi bi-pen-fill fs-4'></i>
                      </Link>
                      <button onClick={() => DeleteCategorie(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ReOrderCategorieModal order={ReOrderData} show={showModal} onHide={hideOrderModal} fetch={() => fetchData()} />
    </div>
  )
}

export default CategorieList