import React, { ChangeEvent, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { AddingNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { useApiRequest } from '../../helpers/ApiRequest';
import SubmitButton from '../../components/submitButton/submitButton';
import { compressAndAppendImage } from '../../helpers/imageProccessing/Imagecompressor';

const sliderSchema = (isUpdate: boolean = false) => {
  let schema = Yup.object().shape({
    title: Yup.string()
    // description: Yup.string(),
  });

  if (!isUpdate) {
    schema = schema.shape({
      image: Yup.string().required("L'image est obligatoire"),
    });
    schema = schema.shape({
      mobileImage: Yup.string().required("L'image est obligatoire"),
    }).test("fileType", "Unsupported file format", (value) => {
      return value && ["image/jpeg", "image/png", "image/svg", "image/jpg", "image/webp"].includes(value.type)
    });
  }

  return schema;
};

const AddSlider: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [imagefile, setImage] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");
  const apiRequest = useApiRequest()

  const formik = useFormik({
    initialValues: {
      title: "",
      // description: "",
      image: "",
      mobileImage: "",

    },
    validationSchema: sliderSchema,
    onSubmit: async (values, { resetForm }) => {

      setIsLoading(true)
      const formData = new FormData();
      formData.append('title', values.title);
      // formData.append('description', values.description);

      await compressAndAppendImage(values.image as unknown as File, formData, 'imageFile', 0.8);
      await compressAndAppendImage(values.mobileImage as unknown as File, formData, 'mobileImageFile', 0.8);

      try {
        const response = await apiRequest({
          route: `sliders`,
          method: 'POST',
          body: formData
        });

        if (response.status === 201) {
          AddingNotify('Slider')
          setIsLoading(false)
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)
      }

      resetForm()

    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Slider</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Title</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Title'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>

            <div className="row mb-6">
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image | Version Desktop</label>
                <input
                  type="file"
                  name="image"
                  accept=".png, .jpg, .jpeg, .webp"
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  required
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files[0]) {
                      formik.setFieldValue('image', e.target.files[0])
                    }
                  }} />
                {formik.touched.image && formik.errors.image && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.image}</div>
                  </div>
                )}

              </div>
            </div>

            <div className="row mb-6">
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image | Version Mobile</label>
                <input
                  type="file"
                  name="mobileImage"
                  accept=".png, .jpg, .jpeg, .webp"
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  required
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files[0]) {
                      formik.setFieldValue('mobileImage', e.target.files[0])
                    }
                  }} />
                {formik.touched.mobileImage && formik.errors.mobileImage && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.mobileImage}</div>
                  </div>
                )}

              </div>
            </div>

            {/* <div className='row mb-6'>
              <div className='col-lg-12 col-md-12 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <textarea
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
            </div> */}

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddSlider
