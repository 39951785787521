import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReOrderSliderProps as ReOrderInterface } from "../../helpers/Props";
import ReOrderSlider from "./ReOrderSlider";

export default function ReOrderWrapper({
    sliders
}: {
    sliders: ReOrderInterface[];
}) {
    return (
        <DndProvider backend={HTML5Backend}>
            <ReOrderSlider sliders={sliders} />
        </DndProvider>
    );
}
