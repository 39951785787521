import React from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { CategorieProps } from '../../helpers/Props';

interface CategoryItemProps {
    category: CategorieProps;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ category }) => {
    return (
        <tr key={category.id}>
            <td>
                <div className='d-flex align-items-center'>
                    <div className='symbol symbol-70px'>
                        <img
                            src={`${process.env.REACT_APP_API_UPLOAD}/category_images/${category.image}`}
                            className='w-100'
                            alt=''
                        />
                    </div>
                </div>
            </td>

            <td>
                <span className='text-dark fw-bold d-block mb-1 fs-6'>
                    {category.title}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold d-block mb-1 fs-6'>
                    {category.orderNumber}
                </span>
            </td>
        </tr>
    );
};

export default CategoryItem;
