import { FC, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";

export interface CardProps {
    id: any;
    image: string;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
    index: number;
    orderNumber: string;
    type: string;
}

export const ItemTypes = {
    CARD: "card",
};

export const ReOrderCard: FC<CardProps> = ({ id, image, index, moveCard }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;


            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drag(drop(ref));
    return (
        <div
            ref={ref}
            className={`radius-5px bg-light shadow-2 cursor-move py-2 px-4 mb-3  ${isDragging ? "opacity-0" : "opacity-100"
                }`}
            data-handler-id={handlerId}
        >
            <div className="w-100 d-flex aline-items-center">
                {/* <i className="bi bi-grip-vertical clr-gold fs-14px pt-3px pe-3px"></i> */}
                {/* <span className="">{title}</span> */}
                <div className='symbol symbol-30px'>
                    <img
                        src={`${process.env.REACT_APP_API_UPLOAD}/slider_images/${image}`}
                        className='w-100'
                        alt=''
                    />
                </div>
            </div>
        </div>
    );
};
