import React from 'react';
import { Loading } from '../Loading';

interface SubmitButtonProps {
  isLoading: boolean;
  buttonLabel: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ isLoading, buttonLabel }) => {
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <button type='submit' className='btn btn-primary' >
          {buttonLabel}
        </button>
      )}
    </>
  );
};

export default SubmitButton;


{/* <button type='submit' className='btn btn-primary' disabled={loading}>
  {!loading && buttonLabel}
  {loading && (
    <span className='indicator-progress' style={{ display: 'block' }}>
      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>
  )}
</button> */}