import React, { useRef, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { Loading } from "../../components/Loading";
import { generateSlug, useApiRequest } from "../../helpers/ApiRequest";
import { UpdateNotify, errorNotify, FrCustomeErrorNorify } from "../../helpers/Toastify";
import { useCategoriesContext } from "../../context/CategoriesContext";
import { processAndAppendImage } from "../../helpers/imageProccessing/Imagecompressor";

type Props = {
  className: string;
  data: any;
  categorieId: number;
  fetchData: any;
  deleteSubCategorie: (id: number) => void;
};

const SubCategoryItem: React.FC<Props> = ({
  className,
  data,
  categorieId,
  fetchData,
  deleteSubCategorie,
}) => {
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState<string>(data.description === null ? '' : data.description);
  const [SubCategoryParent, setSubCategoryParent] = useState(data.id);
  const [image, setImage] = useState(data.image);
  const [SubCategory, setSubCategory] = useState(data.id);
  const { categories } = useCategoriesContext();
  const [newImage, setNewImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };
  const [subId, setSubId] = useState(data.id);
  const [loading, setLoading] = useState(false);
  const body = {
    title: title,
    description: description,
    parent: SubCategoryParent,
  };
  const apiRequest = useApiRequest()


  const updateCategorie = async (id: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('title', body["title"]);
    formData.append('description', body["description"]);

    const slug = generateSlug(body["title"]);
    formData.append('slug', slug);

    let parentId = body["parent"] === SubCategory ? String(categorieId) : body["parent"];

    if (newImage) {
      await processAndAppendImage(newImage as File, formData, 'imageFile')

    }

    formData.append('parent', parentId);

    try {
      const response = await apiRequest({
        route: `update-subcategorie/${id}`,
        method: 'POST',
        body: formData,
      });


      if (response.status === 200) {
        setLoading(false);
        fetchData();
        UpdateNotify('Sous Categorie');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }

    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  return (
    <tr key={data.key}>

      <td>
        <div className='symbol symbol-70px ms-2'>
          {newImage ? (
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-70px'>
                <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
              </div>
            </div>
          ) : (
            <img
              src={`${process.env.REACT_APP_API_UPLOAD}/category_images/${image}`}
              className='w-100 shadow'
              alt=''
            />
          )}
        </div>
      </td>

      <td>
        <input
          ref={fileInputRef}
          type="file"
          name="image"
          accept=".png, .jpg, .jpeg, .webp"
          className='form-control form-control-lg form-control-solid'
          onChange={handleImageChange} />
      </td>


      <td>

        <input
          className='form-control form-control-lg form-control-solid'
          placeholder='Nom de categorie'
          type="text"
          name="title"
          value={title}
          onChange={(e) => { setTitle(e.target.value) }}
        />
      </td>
      <td>
        <input
          className='form-control form-control-lg form-control-solid'
          placeholder='Description'
          name="description"
          value={description ? description : ''}
          onChange={(e) => setDescription(e.target.value)}
        />
      </td>
      <td>
        <select
          name='parent'
          className="form-select"
          value={SubCategoryParent}
          onChange={(e) => setSubCategoryParent(e.target.value)}
        >
          <option value={categorieId}>{categories.find((cat) => cat.id === categorieId)?.title}</option>
          {categories.map((categorie: any, index) => {
            if (categorie.id !== categorieId) {
              return (
                <option key={index} value={categorie.id}>
                  {categorie.title}
                </option>
              );
            }
            return null;
          })}
        </select>

      </td>
      <td>
        <div className="d-flex justify-content-end flex-shrink-0">
          <button
            type={"submit"}
            title="save"
            className="btn btn-icon btn-light-primary btn-sm me-2"
            onClick={() => updateCategorie(subId)}
          >
            {loading ? (
              <Loading />
            ) : (
              <KTSVG
                path="/media/icons/duotune/general/gen005.svg"
                className="svg-icon-3"
              />
            )}
          </button>

          <button onClick={() => deleteSubCategorie(subId)} className='btn btn-icon btn-light-danger btn-sm'>
            <i className='bi bi-trash-fill fs-4'></i>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default SubCategoryItem;