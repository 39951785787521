import axios from "axios";
import { useContext, useState } from "react";
// import moment from "moment";
// import { toast } from "react-toastify";
import { string } from "yup";
import Compressor from 'compressorjs';
import slugify from 'slugify';
const API_ROUTE = process.env.REACT_APP_API_URL;


interface IApiRequest {
    route: string;
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "IGET";
    requiresAuth?: boolean;
    body?: any;
    headers?: any;
    formData?: boolean;
    params?: {
        startIndex?: number,
        stopIndex?: number,
    }
}


export const ApiRequest = async ({
    route,
    method,
    requiresAuth = false,
    body,
    formData,
    params = {
        startIndex: 1,
        stopIndex: 1,
    }
}: IApiRequest) => {
    //  try {
    //     const headers: any = requiresAuth
    //       ? {
    //           Authorization: `Bearer ${getLocalUser().token}`,
    //         }
    //       : null;
    //     let response: any;

    //     const r = `${API_ROUTE}/${route}`;
    //     console.log(r);

    //     if (method === "POST") {
    //       response = await axios.post(r, body, {
    //         headers,
    //       });
    //     } else if (method === "GET") {
    //       response = await axios.get(r, {
    //         headers,
    //       });
    //     } else if (method === "DELETE") {
    //       response = await axios.delete(r, {
    //         headers,
    //       });
    //     } else if (method === "PUT") {
    //       response = await axios.put(r, body, {
    //         headers,
    //       });
    //     }

    //     return response.data;
    //   } catch (error) {
    //     throw error;
    //   } 
};

const isFile = (input: any) => "File" in window && input instanceof File;
const isFileList = (input: any) =>
    "File" in window && input instanceof FileList;

export function useApiRequest() {

    const ApiRequest = async ({
        route,
        method,
        requiresAuth = false,
        body,
        formData,
        headers,
        params = {
            startIndex: 1,
            stopIndex: 1,
        }
    }: IApiRequest) => {

        let dataToSend: any = body;

        try {

            let response: any;

            const r = `${API_ROUTE}/${route}`;
            const getRoute = `${API_ROUTE}/${route}?page=${params.startIndex}`;
            // console.log(r);

            if (method === "POST") {
                response = await axios.post(r, dataToSend, {

                });
            } else if (method === "GET") {
                response = await axios.get(r, {

                });

            } else if (method === "IGET") {
                response = await axios.get(getRoute, {

                });

            } else if (method === "DELETE") {
                response = await axios.delete(r, {
                });
            } else if (method === "PUT") {
                response = await axios.put(r, dataToSend, {
                    headers: {
                        "Content-Type": "application/json"
                    }

                });
            } else if (method === "PATCH") {
                response = await axios.patch(r, dataToSend, {

                });
            }

            return response;
        } catch (error) {
            throw error;
        }
    };

    return ApiRequest;
}


// -------------------------------------------


export function useApiCall() {
    const [axiosProgress, setAxiosProgress] = useState<number>(0);

    const ApiCall = async ({
        route,
        method,
        requiresAuth = false,
        body,
        formData,
        headers,
    }: IApiRequest) => {

        let dataToSend: any = body;

        try {

            let response: any;
            const r = `${API_ROUTE}/${route}`;

            if (method === "POST") {
                let lastProgress = 0;
                response = await axios.post(r, dataToSend, {
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);

                        if (percentCompleted !== lastProgress) {
                            setAxiosProgress(percentCompleted);
                            lastProgress = percentCompleted;
                        }
                    },
                });
            } else if (method === "GET") {
                response = await axios.get(r, {

                });

            } else if (method === "DELETE") {
                response = await axios.delete(r, {
                });
            } else if (method === "PUT") {
                response = await axios.put(r, dataToSend, {
                    headers: {
                        "Content-Type": "application/json"
                    }

                });
            } else if (method === "PATCH") {
                response = await axios.patch(r, dataToSend, {

                });
            }

            return response;
        } catch (error) {
            throw error;
        }
    };

    return { ApiCall, axiosProgress };
}

// -------------------------------------------

export function createFormData(data: any, files?: any) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    });
    if (files) {
        files.map((file: any) => {
            formData.append("images[]", file)
        })
    }

    return formData;
}

const encodeHtmlAssociations: { [key: string]: string } = {
    ["<"]: "@lt",
    [">"]: "@gt",
}
const decodeHtmlAssociations: { [key: string]: string } = {
    ["@lt"]: "<",
    ["@gt"]: ">",
}
export function encodeHtmlTags(html: string): string {
    Object.keys(encodeHtmlAssociations).forEach((key: string) => {
        html = html.replace(new RegExp(key, "g"), encodeHtmlAssociations[key]);
    });
    return html
}

export function decodeHtmlTags(html: any): any {
    Object.keys(decodeHtmlAssociations).forEach((key: string) => {
        html = html.replace(new RegExp(key, "g"), decodeHtmlAssociations[key]);
    });
    return html
}





// export const getDate = (date: any) => {
//     return moment(date).format("MM/DD/YYYY");
// };

export const getMoneyAmount = (amount: number) => {
    return `${amount} EUR`;
};

// export const showToast = (
//   message: string | string[],
//   type: "info" | "success" | "warning" | "error" | "default"
// ) => {
//   toast(message, {
//     type,
//     hideProgressBar: true,
//   });
// };

export const getUserPhoto = (photo: any) => {
    return photo
        ? `${process.env.NEXT_PUBLIC_STORAGE_ROUTE}/${photo}`
        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
};

export const getRangeYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    let startYear = currentYear - 50;

    while (startYear <= currentYear + 50) {
        years.push(startYear++);
    }

    return years;
};

export function XformatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate() + 1).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
}

export function formatDate(dateWithTimezone: string | any) {
    const dateWithoutTime = dateWithTimezone.slice(0, 10);
    const [year, month, day] = dateWithoutTime.split('-');
    return `${day}-${month}-${year}`;
}


export function UFormatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${year}-${month}-${day}`;
}

export function formDataToObject(formData: FormData): Record<string, any> {
    let object: Record<string, any> = {};
    formData.forEach((value, key) => {
        if (object.hasOwnProperty(key)) {
            let current = object[key];
            if (!Array.isArray(current)) {
                current = object[key] = [current];
            }
            current.push(value);
        } else {
            object[key] = value;
        }
    });
    return object;
};

export const QuillModules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        [{ 'color': [] }, { 'background': [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            // { List: "unordered " },
            // { List: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image", "video"],
    ],
};

export const convertImageToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result) {
                resolve(reader.result.toString());
            } else {
                reject('Error converting image to base64');
            }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};


export function arrayToString(items: string[]): string {
    return items.join(',');
}

export const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};


export function TruncateText({ text, maxLength }: any) {
    if (text.length <= maxLength) {
        return <span>{text}</span>;
    }

    const truncatedText = text.slice(0, maxLength) + '...';

    return <span>{truncatedText}</span>;
}

export function formatDatex(inputDate: string): string {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}-${month}-${year}`;
}

export function formatPrice(price: string): string {
    const numericPrice = parseFloat(price);

    if (isNaN(numericPrice)) {
        return price;
    }

    return numericPrice.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).replace(',', ' ');
}

export function stripHtmlTags(input: any) {
    return input.replace(/<[^>]*>/g, '');
}

export async function compressImage(imageFile: File | Blob) {
    return new Promise((resolve, reject) => {
        new Compressor(imageFile, {
            quality: 0.2,
            success(result) {
                resolve(result);
            },
            error(err) {
                reject(err);
            },
        });
    });
}

export function generateSlug(inputString: string): string {
    let string = inputString.replace(/&/g, 'et').replace(/'|’/g, '-').replace('$', '');

    const transliterated = slugify(string, {
        lower: true,
        remove: /[^a-zA-Z0-9 -]/g,
    });

    const slug = transliterated.replace(/ /g, '-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

    return slug;
}

export const getFirstWord = (inputString: string): string => {
    const words = inputString.split(' ');
    if (words.length === 1) {
        return words[0];
    }
    const initials = words.map((word) => word.charAt(0));
    return initials.join('').replace('&', '').replace('-', '');
};












// -----------------------

async function fetchImage(imageUrl: string): Promise<Blob | null> {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error(`Failed to fetch image: ${response.statusText}`);
        }
        return await response.blob();
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
}

export async function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
}

export const handleImageForPdf = async (imageUrl: string): Promise<string | null> => {
    const imageBlob = await fetchImage(imageUrl);
    if (!imageBlob) {
        return null;
    }
    const base64 = await convertBlobToBase64(imageBlob);
    // Now you have the base64 string, use it in your PDF library
    return base64;
}