/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { BiCategory } from 'react-icons/bi'
import img from '../../../assets/images/brand (1).png'
import box from '../../../assets/images/box.png'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Hbeauty</span>
        </div>
      </div>


      {/* Order Start */}
      <AsideMenuItemWithSub
        to='/pages/order-management'
        title='Commandes'
        reactIcon={<BiCategory className='react-icon' />}
      >
        <AsideMenuItem to='/order/order-list' title='Liste Des Commandes' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* Order End */}


      {/* Marque Start */}
      <AsideMenuItemWithSub
        to='/pages/marque-management'
        title='Marques'
        reactIcon={<BiCategory className='react-icon' />}
      // imgIcon={img}
      >
        <AsideMenuItem to='/marque/add-marque' title='Ajouter Marque' hasBullet={true} />
        <AsideMenuItem to='/marque/marque-list' title='Liste Des Marque' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* Marque End */}


      {/* Categorie Start */}
      <AsideMenuItemWithSub
        to='/pages/categorie-management'
        title='Categories'
        reactIcon={<BiCategory className='react-icon' />}
      >
        <AsideMenuItem to='/categorie/add-categorie' title='Ajouter Categorie' hasBullet={true} />
        <AsideMenuItem to='/categorie/categorie-list' title='Liste Des Categorie' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* Categorie End */}

      {/* Product Start */}
      <AsideMenuItemWithSub
        to='/pages/product-management'
        title='Produits'
        reactIcon={<BiCategory className='react-icon' />}
      // imgIcon={box}

      >
        <AsideMenuItem to='/product/add-product' title='Ajouter Produit' hasBullet={true} />
        <AsideMenuItem to='/product/product-list' title='Liste Des Produit' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* Product End */}


      {/* Pack Start */}
      <AsideMenuItemWithSub
        to='/pages/pack-management'
        title='Packs'
        reactIcon={<BiCategory className='react-icon' />}
      >
        <AsideMenuItem to='/pack/add-pack' title='Ajouter pack' hasBullet={true} />
        <AsideMenuItem to='/pack/pack-list' title='Liste Des packs' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* Pack End */}


      {/* slider Start */}
      <AsideMenuItemWithSub
        to='/pages/slider-management'
        title='Sliders'
        reactIcon={<BiCategory className='react-icon' />}
      >
        <AsideMenuItem to='/slider/add-slider' title='Ajouter Slider' hasBullet={true} />
        <AsideMenuItem to='/slider/slider-list' title='Liste Des Slider' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* slider End */}


      {/* Code Promo Start */}
      <AsideMenuItemWithSub
        to='/pages/codepromo-management'
        title='Code Promo'
        reactIcon={<BiCategory className='react-icon' />}
      >
        <AsideMenuItem to='/codepromo/add-codepromo' title='Ajouter Code Promo' hasBullet={true} />
        <AsideMenuItem to='/codepromo/codepromo-list' title='Liste Des Code Promo' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* Code Promo End */}


      {/* Promotion Start */}
      <AsideMenuItemWithSub
        to='/pages/promotion-management'
        title='Promotions'
        reactIcon={<BiCategory className='react-icon' />}
      >
        <AsideMenuItem to='/promotion/add-promotion' title='Ajouter Promotion' hasBullet={true} />
        <AsideMenuItem to='/promotion/promotion-list' title='Liste Des Promotions' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* Promotion End */}


      {/* Reviews Start */}
      <AsideMenuItemWithSub
        to='/pages/reviews-management'
        title='Reviews'
        reactIcon={<BiCategory className='react-icon' />}
      >
        <AsideMenuItem to='/reviews/reviews-list' title='Liste Des Avis' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* Reviews End */}


      {/* user Start */}
      <AsideMenuItemWithSub
        to='/pages/user-management'
        title='Utilisateurs'
        reactIcon={<BiCategory className='react-icon' />}
      >
        <AsideMenuItem to='/user/add-user' title='Ajouter Un Administrateur' hasBullet={true} />
        <AsideMenuItem to='/user/user-list' title='Liste Des Utilisateurs' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* user End */}



      {/* Email Start */}
      <AsideMenuItemWithSub
        to='/pages/email-management'
        title='Emails'
        reactIcon={<BiCategory className='react-icon' />}
      >
        <AsideMenuItem to='/email/email-list' title='Liste Des Emails' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* Email End */}












      {/* <AsideMenuItemWithSub
        to='/pages'
        title='Pages'
        icon='/media/icons/duotune/ecommerce/ecm007.svg'
      >
        <AsideMenuItemWithSub to='/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
      >
        <AsideMenuItem to='/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/produits'
        title='Produits'
        icon='/media/icons/duotune/communication/com006.svg'
      >
        <AsideMenuItem to='/produit/ListDesProduits' title='Liste Des Produits' hasBullet={true} />
        <AsideMenuItem to='/produit/AjouterProduit' title='Ajouter Produit' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      {/* <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
