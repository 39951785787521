import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReOrderCategorieProps as ReOrderInterface } from "../../helpers/Props";
import ReOrderCategorie from "./ReOrderCategorie";

export default function ReOrderWrapper({
    categories
}: {
    categories: ReOrderInterface[];
}) {
    return (
        <DndProvider backend={HTML5Backend}>
            <ReOrderCategorie categories={categories} />
        </DndProvider>
    );
}
