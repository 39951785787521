import React, { ChangeEvent, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { generateSlug, useApiRequest } from '../../helpers/ApiRequest';
import { AddingNotify, errorNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { processAndAppendImage } from '../../helpers/imageProccessing/Imagecompressor';
import SubmitButton from '../../components/submitButton/submitButton';

const Schema = Yup.object().shape({
  title: Yup.string().required('Le nom de marque est obligatoire'),
  image: Yup.string().required("L'image de marque est obligatoire"),
  description: Yup.string(),
})

const AddMarque: React.FC = () => {

  const apiRequest = useApiRequest()
  const [isloading, setIsLoading] = useState(false)

  const processAndPostData = async (formData: FormData) => {
    try {
      setIsLoading(true)
      await processAndAppendImage(formik.values.image as unknown as File, formData, 'imageFile');

      const response = await apiRequest({
        route: `marques`,
        method: 'POST',
        body: formData
      });

      if (response.status === 201) {
        AddingNotify('Marque')
        setIsLoading(false)
      }

    } catch (error) {
      FrCustomeErrorNorify()
      setIsLoading(false)

    }

  }

  const formik = useFormik({
    initialValues: {
      title: "",
      image: "",
      description: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      setIsLoading(true)

      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);

      const slug = generateSlug(values.title);

      try {

        const response = await apiRequest({
          route: `check-marque-by-slug/${slug}`,
          method: 'GET',
        });

        if (response.status === 200) {
          if (response.data.message === 'Found') {
            errorNotify('Marque déjâ exist')
            setIsLoading(false)

          } else {
            formData.append('slug', slug);
            processAndPostData(formData)
          }

        } else {
          FrCustomeErrorNorify()
          setIsLoading(false)
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)
      }


    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Une Marque</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de marque</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nom de marque'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>

                    <input
                      type="file"
                      name="image"
                      accept=".png, .jpg, .jpeg, .webp"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files && e.target.files[0]) {
                          formik.setFieldValue('image', e.target.files[0]);
                        }
                      }} />
                    {formik.touched.image && formik.errors.image && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.image}</div>
                      </div>
                    )}

                  </div>

                </div>
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-12 col-md-12 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <textarea
                  rows={5}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddMarque