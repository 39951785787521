import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCategoriesContext } from '../../context/CategoriesContext'
import { QuillModules, generateSlug, getFirstWord, stripHtmlTags, useApiRequest } from '../../helpers/ApiRequest'
import { CategorieProps, MarqueProps, ProductProps } from '../../helpers/Props'
import { DeleteNotify, FrCustomeErrorNorify, UpdateNotify, errorNotify } from '../../helpers/Toastify'
import { useParams } from 'react-router-dom'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ProductsStyle.css'
import { processAndAppendImage } from '../../helpers/imageProccessing/Imagecompressor'
import { Loading } from '../../components/Loading'
import SubmitButton from '../../components/submitButton/submitButton';
import Barcode from 'react-barcode';


const UpdateProduct: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [files, setFiles] = useState<File[]>([]);
  const [imageLoadingStates, setImageLoadingStates] = useState<Record<number, boolean>>({});

  const [newImagefile, setNewImageFile] = useState<File | any>(null);
  const [newImage, setNewImage] = useState<string>("");
  const [newMultiImage, setNewMultiImage] = useState<File | any>(null);
  const { categories } = useCategoriesContext();
  const [products, setProducts] = useState<ProductProps>()
  const { id } = useParams()
  const [multiImages, setMultiImages] = useState<File[]>([]);
  const [description, setDescription] = useState('');
  const [disponibiliteState, setDisponibiliteState] = useState(1);
  const [subCategories, setSubCategories] = useState<CategorieProps[]>([]);
  const [infoComplementaire, setInfoComplementaire] = useState('');
  const [imageProcessingProgress, setImageProcessingProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const newFileInputRef = useRef<HTMLInputElement>(null);

  const apiRequest = useApiRequest()
  const [marques, setMarques] = useState<MarqueProps[]>([]);

  const fetchMarquesData = async () => {
    try {
      const response = await apiRequest({
        route: 'marques.json',
        method: 'GET',
      });

      setMarques(response.data)
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };
  useEffect(() => {
    fetchMarquesData();
  }, [])

  const fetchProductData = async () => {
    try {
      const response = await apiRequest({
        route: `products/${id}.json`,
        method: 'GET',
      });

      if (response.status === 200) {
        setValues({
          image: response.data.image,
          title: response.data.title,
          reference: response.data.reference,
          barcode: response.data.barcode ? response.data.barcode : '',
          categorie: response.data.categorie[0].id,
          subcategorie: response.data.categorie[1] ? response.data.categorie[1].id : '',
          marque: response.data.marque.id,
          tarif_regulier: response.data.tarif_regulier,
          poids_kg: response.data.poids_kg,
          longueur_cm: response.data.longueur_cm,
          largeur_cm: response.data.largeur_cm,
          hauteur_cm: response.data.hauteur_cm,
          video_url: response.data.video_url,
        })

        setProducts(response.data);
        if (response.data.description !== undefined) {
          setDescription(response.data.description)
        }
        else {
          setDescription('')
        }

        if (response.data.infoComplementaire !== undefined) {
          setInfoComplementaire(response.data.infoComplementaire)
        }
        else {
          setInfoComplementaire('')
        }

        setDisponibiliteState(response.data.disponibilite === 'rupture' ? 0 : 1)

        const filteredSubCategories = categories.find((category) => category.id === Number(response.data.categorie[0].id))?.subCategory || [];
        setSubCategories(filteredSubCategories);
      }

    } catch (error) {
      FrCustomeErrorNorify()
    }
  };
  useEffect(() => {
    fetchProductData();
  }, [])


  const Schema = Yup.object().shape({
    title: Yup.string().required('Ce champ est obligatoire'),
    reference: Yup.string()
      .required('Ce champ est obligatoire')
      .matches(/^[a-zA-Z0-9]+$/, 'Ce champ doit contenir uniquement des lettres et des chiffres'),
    barcode: Yup.string()
      .required('Ce champ est obligatoire')
      .matches(/^[a-zA-Z0-9]+$/, 'Ce champ doit contenir uniquement des lettres et des chiffres'),
    categorie: Yup.string()
      .required('Ce champ est obligatoire')
      .oneOf(
        categories.map((category) => category.id.toString()),
        'Sélectionnez une catégorie valide'
      ),
    subcategorie: Yup.string()
      .required('Ce champ est obligatoire')
      .oneOf(
        subCategories.map((subCategory) => subCategory.id.toString()),
        'Sélectionnez une sous-catégorie valide'
      ),
    marque: Yup.string()
      .required('Ce champ est obligatoire')
      .oneOf(
        marques.map((marque) => marque.id.toString()),
        'Sélectionnez une marque valide'
      ),
    tarif_regulier: Yup.number()
      .required('Ce champ est obligatoire')
      .typeError('Ce champ doit être un nombre')
      .positive('Ce champ doit être un nombre positif'),

    poids_kg: Yup.string(),
    longueur_cm: Yup.string(),
    largeur_cm: Yup.string(),
    hauteur_cm: Yup.string(),
    video_url: Yup.string(),
  });


  const formik = useFormik({
    initialValues: {
      image: "",
      title: "",
      reference: "",
      barcode: "",
      categorie: "",
      subcategorie: "",
      marque: "",
      tarif_regulier: "",
      poids_kg: "",
      longueur_cm: "",
      largeur_cm: "",
      hauteur_cm: "",
      video_url: "",
    },

    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      const formData = new FormData();
      setIsLoading(true)

      // ------- Start check Product Slug

      // const idP = 3
      const idP = products?.id
      const idC = products?.categorie[0].id

      const selectedMarque = marques.find((c) => c.id === Number(values.marque))
      const marqueTitle = getFirstWord(selectedMarque?.title as string)

      const selectedCategorie = categories.find((c) => c.id === Number(values.categorie))
      const categorieTitle = getFirstWord(selectedCategorie?.title as string)

      const slugWithTitle = `${generateSlug(values.title)}`
      const slugWithRef = `${generateSlug(values.title)}-${values.reference}`
      const slugWithMarque = `${generateSlug(values.title)}-${generateSlug(marqueTitle as string)}`
      const slugWithMarqueCategorie = `${generateSlug(values.title)}-${generateSlug(marqueTitle as string)}-${generateSlug(categorieTitle as string)}`

      const checkSlug = async () => {

        const checkBySlugResponse = await apiRequest({
          route: `check-product-by-sid/${idP}/${slugWithTitle}`,
          method: 'GET',
        });

        if (checkBySlugResponse.status === 200) {
          if (checkBySlugResponse.data.message === 'Found') {
            const checkBySlugMarqueResponse = await apiRequest({
              route: `check-product-by-smid/${idP}/${slugWithMarque}/${selectedMarque?.id}`,
              method: 'GET',
            });

            if (checkBySlugMarqueResponse.status === 200) {
              if (checkBySlugMarqueResponse.data.message === 'Found') {
                const checkBySlugMarqueCatResponse = await apiRequest({
                  route: `check-product-by-smcid/${idP}/${slugWithMarqueCategorie}/${selectedMarque?.id}/${selectedCategorie?.id}`,
                  method: 'GET',
                });

                if (checkBySlugMarqueCatResponse.status === 200) {
                  if (checkBySlugMarqueCatResponse.data.message === 'Found') {
                    formData.append('slug', slugWithRef);

                  } else {
                    formData.append('slug', slugWithMarqueCategorie);
                  }

                } else {
                  FrCustomeErrorNorify()
                  setIsLoading(false)
                  return
                }

              } else {
                formData.append('slug', slugWithMarque);
              }

            } else {
              FrCustomeErrorNorify()
              setIsLoading(false)
              return
            }

          } else {
            formData.append('slug', slugWithTitle);
          }

        } else {
          FrCustomeErrorNorify()
          setIsLoading(false)
          return
        }

      }

      if (products?.title !== values.title || products?.marque.id !== selectedMarque?.id) {
        checkSlug()
        if (idC !== selectedCategorie) {
          checkSlug()
        }
      }

      // ------- End check Product Slug


      // ------- Start Images Proccesing
      if (newImagefile) {
        setIsProcessing(true)
        setImageProcessingProgress(10);

        const imagefileFieldName = 'newImageFile';
        await processAndAppendImage(newImagefile as File, formData, imagefileFieldName);

        setImageProcessingProgress(20);
      }

      if (files.length > 0) {
        setIsProcessing(true)
        const totalTasks = files.length;
        let completedTasks = 0;

        const imagePromises = files.map(async (file) => {
          const fieldName = "productImages[]";
          await processAndAppendImage(file, formData, fieldName);
          completedTasks++;

          const newProgressWidth = (completedTasks / totalTasks) * 90 + 10;
          setImageProcessingProgress(newProgressWidth);
        });

        await Promise.all(imagePromises);
      } else {
        setImageProcessingProgress(100);
      }
      // ------- End Images Proccesing


      const meta = stripHtmlTags(description)
      const metaDescription = meta.substring(0, 256)
      const disponibilite = disponibiliteState === 1 ? 'enstock' : 'rupture';

      // const selectedMarque = marques.find((c) => c.id === Number(values.marque))
      // const marqueTitle = getFirstWord(selectedMarque?.title as string)
      // const slug = `${generateSlug(values.title)}-${generateSlug(marqueTitle as string)}`


      formData.append('title', values.title);
      formData.append('reference', values.reference);
      formData.append('barcode', values.barcode);
      // formData.append('slug', slug);
      formData.append('disponibilite', disponibilite);
      formData.append('description', description);
      formData.append('infoComplementaire', infoComplementaire);
      formData.append('meta_description', metaDescription);
      formData.append('subcategorie', values.subcategorie);
      formData.append('tarif_regulier', values.tarif_regulier);
      formData.append('categorie', values.categorie);
      formData.append('marque', values.marque);
      formData.append('video_url', values.video_url ? values.video_url : '');
      formData.append('poids_kg', values.poids_kg ? values.poids_kg : '');
      formData.append('longueur_cm', values.longueur_cm ? values.longueur_cm : '');
      formData.append('largeur_cm', values.largeur_cm ? values.largeur_cm : '');
      formData.append('hauteur_cm', values.hauteur_cm ? values.hauteur_cm : '');

      try {
        const response = await apiRequest({
          route: `update-product/${id}`,
          method: 'POST',
          body: formData
        });

        if (response.status === 200) {
          UpdateNotify('Produit');
          fetchProductData();
          setNewImageFile(null);
          setIsProcessing(false)
          setImageProcessingProgress(0);
          if (newFileInputRef.current) {
            newFileInputRef.current.value = '';
          }
          setFiles([])
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          setIsLoading(false)
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)
      }

    },
  })

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(URL.createObjectURL(e.target.files[0]));
      setNewImageFile(e.target.files[0]);
    }
  };

  const handleMultiImageChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.files && e.target.files[0]) {
      const updatedImages = [...multiImages];
      updatedImages[index] = e.target.files[0];
      setMultiImages(updatedImages);
      setNewMultiImage(e.target.files[0]);
    }
  };

  const DeleteImage = async (imageId: number) => {
    try {
      const response = await apiRequest({
        route: `product_images/${imageId}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Image');
        fetchProductData();
      }
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  const UpdateImage = async (imageId: number, index: number) => {
    try {
      if (multiImages[index] === undefined) {
        errorNotify('Aucune image sélectionnée');
      } else {
        const formData = new FormData();
        setImageLoadingStates((prevState) => ({ ...prevState, [imageId]: true }));

        const imagefileFieldName = 'newMultiImage';
        await processAndAppendImage(newMultiImage as File, formData, imagefileFieldName);

        const response = await apiRequest({
          route: `update-product-images/${imageId}`,
          method: 'POST',
          body: formData,
        });

        if (response.status === 200) {
          setImageLoadingStates((prevState) => ({ ...prevState, [imageId]: false }));
          UpdateNotify('Image');
          fetchProductData();
          setNewMultiImage(null);
        }
      }
    } catch (error) {
      FrCustomeErrorNorify();
    }
  };


  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Un Produit</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>

            <div className='card-footer d-flex justify-content-between py-6 px-9'>
              <div className="row w-100">
                <div className="col-8 text-start">
                  {
                    isProcessing ?
                      <>
                        <h5 className='text-info'>Images processing... {Math.floor(imageProcessingProgress)} %</h5>
                        <div className="processing">
                          <div className="process" style={{ width: `${imageProcessingProgress}%` }}></div>
                        </div>
                      </>
                      :
                      ''
                  }
                </div>
                <div className="col-4 text-end">
                  <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
                </div>
              </div>
            </div>

            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de produit</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Nom de produit'
                    name='title'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.title}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-3 col-md-3 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Reference</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Reference'
                    name='reference'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reference}
                  />
                  {formik.touched.reference && formik.errors.reference && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.reference}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-3 col-md-3 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Code Barre</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Code Barre'
                    name='barcode'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.barcode}
                  />
                  {formik.touched.barcode && formik.errors.barcode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.barcode}</div>
                    </div>
                  )}
                </div>

              </div>

              <div className='row mb-6'>

                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Prix de produit</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Prix de produit'
                    name='tarif_regulier'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tarif_regulier}
                  />
                  {formik.touched.tarif_regulier && formik.errors.tarif_regulier && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.tarif_regulier}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-3 col-md-3 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                    Disponibilite
                  </label>
                  <div className='form-check form-check-custom form-check-solid form-check-info'>
                    <input
                      type='radio'
                      name='disponibiliteState'
                      id='enstock'
                      className='form-check-input form-check-disponibilite'
                      checked={disponibiliteState === 1}
                      onChange={() => setDisponibiliteState(1)}
                      onBlur={formik.handleBlur}
                      value={1}
                    />
                    <label htmlFor='enstock' className='fs-5 ms-2 me-4 col-form-label fw-bold'>
                      En Stock
                    </label>

                    <input
                      type='radio'
                      name='disponibiliteState'
                      id='rupture'
                      className='form-check-input form-check-deactive form-check-disponibilite'
                      checked={disponibiliteState === 0}
                      onChange={() => setDisponibiliteState(0)}
                      onBlur={formik.handleBlur}
                      value={0}
                    />
                    <label htmlFor='rupture' className='fs-5 ms-2 col-form-label fw-bold'>
                      Rupture
                    </label>

                  </div>
                </div>

                <div className="col-lg-3 col-md-3 fv-row">
                  <Barcode
                    value={values.barcode}
                    format='CODE128'
                    displayValue={false}
                    fontOptions=""
                    width={2}
                    height={65}
                    background="#ffffff"
                    lineColor="#000000"
                    marginTop={1}
                    marginBottom={1}
                    marginLeft={5}
                    marginRight={1}
                  />
                </div>

              </div>

              <div className="row mb-6">
                <div className="col-lg-12 col-md-12 fv-row">
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                  <ReactQuill
                    className="react-quill-editor"
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                    modules={QuillModules}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <div className="col-lg-12 col-md-12 fv-row">
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Informations Complémentaire</label>

                  <ReactQuill
                    className="react-quill-editor"
                    theme="snow"
                    value={infoComplementaire}
                    onChange={setInfoComplementaire}
                    modules={QuillModules}
                  />
                </div>
              </div>

              <div className='row mb-6'>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Marque</label>
                  <select
                    name="marque"
                    className="form-select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.marque}
                  >
                    <option value="" disabled>Marque</option>
                    {marques.map((marque: any, index) => {
                      return (
                        <option key={index} value={marque.id}>
                          {marque.title}
                        </option>
                      );
                    })}
                  </select>

                  {formik.touched.marque && formik.errors.marque && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.marque}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Categorie</label>

                  <select
                    name="categorie"
                    className="form-select"
                    onChange={(e) => {
                      formik.handleChange(e);
                      const selectedCategory = e.target.value;
                      const filteredSubCategories = categories.find((category) => category.id === Number(selectedCategory))?.subCategory || [];
                      setSubCategories(filteredSubCategories);
                    }}
                    value={formik.values.categorie}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" disabled>
                      Categorie
                    </option>
                    {categories.map((categorie) => (
                      <option key={categorie.id} value={categorie.id}>
                        {categorie.title}
                      </option>
                    ))}
                  </select>

                  {formik.touched.categorie && formik.errors.categorie && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.categorie}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Sous Categorie</label>

                  <select
                    name="subcategorie"
                    className="form-select"
                    onChange={formik.handleChange}
                    value={formik.values.subcategorie}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Sous Categorie</option>
                    {subCategories.map((subCategory) => (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.title}
                      </option>
                    ))}
                  </select>

                  {formik.touched.subcategorie && formik.errors.subcategorie && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.subcategorie}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6 d-non'>

                <div className='col-lg-3 col-md-6 fv-row'>
                  <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Poids Kg</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Poids Kg'
                    name='poids_kg'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.poids_kg}
                  />
                  {formik.touched.poids_kg && formik.errors.poids_kg && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.poids_kg}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-3 col-md-6 fv-row'>
                  <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Longueur cm</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Longueur cm'
                    name='longueur_cm'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.longueur_cm}
                  />
                  {formik.touched.longueur_cm && formik.errors.longueur_cm && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.longueur_cm}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-3 col-md-6 fv-row'>
                  <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Largeur cm</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Largeur cm'
                    name='largeur_cm'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.largeur_cm}
                  />
                  {formik.touched.largeur_cm && formik.errors.largeur_cm && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.largeur_cm}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-3 col-md-6 fv-row'>
                  <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Hauteur cm</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Hauteur cm'
                    name='hauteur_cm'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hauteur_cm}
                  />
                  {formik.touched.hauteur_cm && formik.errors.hauteur_cm && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.hauteur_cm}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>

                {/* Images */}

                <div className='col-lg-4 col-md-4 fv-row'>

                  <label className='col-lg-9 col-md-9 col-form-label fw-bold fs-6'>Ajouté Autre Images</label>

                  <input
                    ref={fileInputRef}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const { files } = e.target;
                      if (files) {
                        setFiles(Array.from(files));
                      }
                    }}
                    type="file"
                    name="productImages"
                    accept=".png, .jpg, .jpeg, webp"
                    className='form-control form-control-lg form-control-solid'
                    multiple
                  />
                </div>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    ref={newFileInputRef}
                    type="file"
                    name="image"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImagefile ? (
                    <div className='d-flex align-items-center position-absolute'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImagefile)} alt=''
                          className='w-100 shadow rounded mt-3' style={{ height: '140px' }} />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>

                <div className='col-lg-4 col-md-4 fv-row d-non'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Lien de Video</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Lien de Video'
                    name='video_url'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.video_url}
                  />
                  {formik.touched.video_url && formik.errors.video_url && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.video_url}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>l'Image Actuelle</label>

                <div className='d-flex align-items-center'>
                  <div className='symbolsymbol-70px old-image'>
                    <img
                      src={`${process.env.REACT_APP_API_UPLOAD}/product_images/${values.image}`}
                      className='w-100 shadow rounded' style={{ height: '140px' }}
                      alt=''
                    />
                  </div>
                </div>

              </div>

            </div>

            <div className='card-footer d-flex justify-content-between py-6 px-9'>
              <div className="row w-100">
                <div className="col-8 text-start">
                  {
                    isProcessing ?
                      <>
                        <h5 className='text-info'>Images processing... {Math.floor(imageProcessingProgress)} %</h5>
                        <div className="processing">
                          <div className="process" style={{ width: `${imageProcessingProgress}%` }}></div>
                        </div>
                      </>
                      :
                      ''
                  }
                </div>
                <div className="col-4 text-end">
                  <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>

      {
        products?.productImages && products?.productImages.length > 0 ?
          <div className="card mb-5 mb-xl-10">
            <div
              className='card-header border-0 cursor-pointer'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#sub'
              aria-expanded='true'
              aria-controls='sub'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>List Des Images</h3>
              </div>
            </div>
            <div id='sub' className='collapse show'>

              <div className='card-body border-top p-9 d-flex justify-content-start flex-wrap'>

                {
                  products?.productImages.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <div className="img-card shadow rounded mx-2 mb-5" >
                          <div className="edit-parent d-flex justify-content-between">
                            <div className="delete-img" onClick={() => DeleteImage(item.id)}>
                              <i className='bi bi-trash-fill fs-1 del-icon'></i>
                            </div>

                            <div className="update-img" onClick={() => UpdateImage(item.id, item.id)}>
                              {imageLoadingStates[item.id] ? (
                                <div className="load">
                                  <Loading />
                                </div>
                              ) : (
                                <i className='bi bi-pen-fill fs-4 update-icon'></i>
                              )}
                            </div>
                          </div>
                          <div className="img-parent w-100">
                            {

                              multiImages[item.id] ?
                                <img
                                  key={item.id}
                                  src={URL.createObjectURL(multiImages[item.id])}
                                  alt={`img-${item.id}`}
                                  className='w-100 multi-img rounded'
                                />
                                :
                                <img
                                  key={item.id}
                                  src={`${process.env.REACT_APP_API_UPLOAD}/product_multi_images/${item.image}`}
                                  alt={`img-${item.id}`}
                                  className='w-100 multi-img rounded'
                                />
                            }

                          </div>
                          <div className="action pos-r fixed-bottom d-flex justify-content-center">
                            <input
                              type="file"
                              name={`multiImage-${item.id}`}
                              accept=".png, .jpg, .jpeg, .webp"
                              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                              onChange={(e) => handleMultiImageChange(e, item.id)}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                  )}

              </div>
            </div>
          </div>
          :
          ''
      }
    </>
  )
}

export default UpdateProduct