import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { formatPrice } from '../../helpers/ApiRequest';
import { OrderProps } from '../../helpers/Props';
// import './OrderItem.css'
import './OrderFacture.css'
import { calculateTotalPrice } from '../../helpers/Helpers';

interface Order {
  order: OrderProps | undefined
  totalPrice: number
}

const OrderItems: React.FC<Order> = (({ order, totalPrice = 0 }: any) => {


  return (
    <div className="hb-container">
      <div className={`card hb-card  hb-d-flex hb-justify-content-center `}>
        <div className='card-body py-3'>
          <div className="">
            <div className="row hb-m-row">
              <div className="hb-logo-container">
                <img src={toAbsoluteUrl('/media/logos/HB-icon.png')} alt='' className='hb-logo' />
              </div>
            </div>

            <div className="row row-user">
              <div className="hb-m-client">
                <div className="hb-user-info">
                  <p className='fw-bold hb-user-info-client'><span>Expéditeur :</span></p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>Hbeauty</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>Km 13 Rte El Jadida Douar Lahfafra Casablanca</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>Tél: 05 22 335356</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>RC: 465087 - ICE: 002477493000053</span>
                  </p>
                </div>

                <div className="hb-user-info">
                  <p className='fw-bold hb-user-info-client'><span>Client :</span></p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{`${order?.user.prenom} ${order?.user.nom}`}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.user.telephone}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.livraisonAdresse.rueInfo}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.livraisonAdresse.region}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.livraisonAdresse.ville}</span>
                  </p>
                </div>


                <div className="hb-user-info">
                  {/* <p className='fw-bold hb-user-info-client'><span>Facturation :</span></p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{`${order?.facturationAdresse.prenom} ${order?.facturationAdresse.nom}`}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.facturationAdresse.telephone}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.facturationAdresse.rueInfo}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.facturationAdresse.region}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.facturationAdresse.ville}</span>
                  </p> */}
                </div>
              </div>

            </div>

          </div>
          {/* end container */}


          <div className="row">
            <p className='fw-bold hb-refrence'>Bon de Livraison No {order?.reference}</p>
            <p className=''>Mmme/Mr {order?.user.prenom} {order?.user.nom}</p>
            <p className=''>Merci beaucoup pour votre commande et la confiance que vous nous accordez!</p>
          </div>

          <hr className='hrtag' />

          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4  rounded-star tb-radius hb-t-dark'>Image</th>
                  <th className='ps-4  rounded-star tb-radius hb-t-dark'>Réf</th>
                  <th className='ps-4  rounded-star tb-radius hb-t-dark'>Produit</th>
                  <th className='hb-t-center hb-t-dark'>Qte</th>
                  <th className='ps-4 hb-txt-end hb-t-dark min-with-th-pu'>Prix Unitaire</th>
                  <th className='ps-4 hb-txt-end hb-t-dark min-with-th-total'>Total</th>
                </tr>
              </thead>
              <tbody>
                {order?.orderItems.map((o: any, index: number) => (

                  <tr key={index} className='item-row'>
                    <td className={`hb-p-0 symbol-50 hb-d-flex align-items-center hb-justify-content-center`} id={`this-td${index}`}>

                    </td>

                    <td className='hb-t- hb-p-0'>
                      <span className='text-dark d-block ml-13 fs-order'>
                        {`${o.product ? o.product?.reference : 'auccun'}`}
                      </span>
                    </td>

                    <td className={`hb-p-0`}>
                      <span className='text-dark d-block ml-13 fs-order'>
                        {`${o.product ? o.product?.title : o.pack?.title}`}
                      </span>
                    </td>

                    <td className='hb-t-center hb-p-0'>
                      <span className='fw--bold text-dark d-block fs-5'>
                        {o.quantity}
                      </span>
                    </td>

                    <td className='hb-t-end hbp-0 item-td'>
                      <span className='fw-bold text-dark d-block fs-7'>
                        {formatPrice(String(o.price))}
                      </span>
                    </td>

                    <td className='hb-t-end hbp-0 item-td'>
                      <span className='fw-bold text-dark d-block fs-7'>
                        {formatPrice(String(o.price * o.quantity))}
                      </span>
                    </td>

                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>

          <div className="hr-container">
            <hr className='hrtag' />
            <div className="row price-section-row">

              <div className="price-section-text w-50">
                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-price-text'>Sous-total : </p>
                </div>

                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-price-text'>Expédition : </p>
                </div>

                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-price-text'>Moyen de paiement : </p>
                </div>

                {order?.paymentMethod === 'carte' ?
                  <div className=" hb-d-flex hb-justify-content-start">
                    <p className='hb-price-text'>Remise de 5% : </p>
                  </div>
                  :
                  null
                }

                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-price-text'>Total : </p>
                </div>
              </div>

              <div className="price-section-value  w-50">
                <div className=" hb-d-flex hb-justify-content-end">
                  <span className='hb-price-value fw-bold'>{order ? formatPrice(String(calculateTotalPrice(order.orderItems))) : null} MAD</span>
                </div>

                <div className=" hb-d-flex hb-justify-content-end">
                  <p className='hb-price-value fw-boldd'>
                    {calculateTotalPrice(order.orderItems) > 300 ?
                      'Gratuit' : calculateTotalPrice(order.orderItems) < 300 ?
                        order?.paymentMethod === 'carte' ?
                          '50 MAD' : '50 MAD' : '50 MAD'}</p>
                </div>

                <div className=" hb-d-flex hb-justify-content-end">
                  <p className='hb-price-value fw-boldd'>{order?.paymentMethod === 'carte' ? 'Par Carte bancaire' : 'À la livraison'}</p>
                </div>

                {order?.paymentMethod === 'carte' ?
                  <div className=" hb-d-flex hb-justify-content-end">
                    <p className='hb-price-value fw-boldd'>
                      {order?.paymentMethod === 'carte' ?
                        `${formatPrice(String(calculateTotalPrice(order.orderItems) * 0.05))}`
                        : order !== undefined && `0`} MAD
                    </p>
                  </div>
                  :
                  null
                }

                <div className=" hb-d-flex hb-justify-content-end">
                  <p className='hb-price-value fw-boldd'>
                    {formatPrice(String(totalPrice))} MAD
                  </p>
                </div>
              </div>

            </div>

            {order?.orderNote ?
              <div className="row hb-mt-6">
                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='fw-bold hb-user-info-client'><span>Remarque :</span></p>
                </div>

                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-line'>
                    <span className='fw-bold hb-pricetext hb-order-note'>{order?.orderNote} </span>
                  </p>
                </div>

              </div>
              :
              ''
            }

          </div>
        </div>

      </div>


    </div>
  );
});

export default OrderItems;