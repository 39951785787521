import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CategorieProps } from '../../helpers/Props';
import CategoryItem from './CategoryItem';

interface CategorieListProps {
    categories: CategorieProps[];
}

const CategorieList: React.FC<CategorieListProps> = ({ categories }) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <table className='table align-middle gs-0 gy-4'>
                <thead>
                    <tr className='fw-bold text-muted bg-light'>
                        <th className='ps-4 min-w-300px rounded-start'>Image</th>
                        <th className='ps-4 min-w-200px'>Nom de categorie</th>
                        <th className='ps-4 min-w-200px'>Order</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((item) => (
                        <CategoryItem key={item.id} category={item} />
                    ))}
                </tbody>
            </table>
        </DndProvider>
    );
};

export default CategorieList;
