import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { CategoriesProvider } from './context/CategoriesContext'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import './App.scss'
import { ReOrderCategorieProvider } from './context/ReOrderCardsContext'
import { ReOrderSliderProvider } from './context/ReOrderSliderContext'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <CategoriesProvider>
            <ReOrderCategorieProvider>
              <ReOrderSliderProvider>
                <AuthInit>
                  <ToastContainer />
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </ReOrderSliderProvider>
            </ReOrderCategorieProvider>
          </CategoriesProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>

  )
}

export { App }