import React, { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom';
import { generateSlug, useApiRequest } from '../../helpers/ApiRequest';
import { AddingNotify, DeleteNotify, UpdateNotify, errorNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { CategorieProps } from '../../helpers/Props';
import SubCategoryItem from './SubCategoryItem';
import SubmitButton from '../../components/submitButton/submitButton';
import { compressAndAppendImage } from '../../helpers/imageProccessing/Imagecompressor';

const Schema = Yup.object().shape({
  title: Yup.string().required('Le nom de categorie est obligatoire'),
  description: Yup.string(),
})

const UpdateCategorie: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [isSubloading, setIsSubLoading] = useState(false)

  const { id } = useParams();
  const apiRequest = useApiRequest()
  const [newImage, setNewImage] = useState<File | null>(null);
  const [categorie, setCategorie] = useState<CategorieProps>();
  const [SubExist, setSubExist] = useState(false);
  const [parentTitle, setParentTitle] = useState();
  const [imagefile, setImage] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");
  // const { categories } = useCategoriesContext();

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `categorie-by-id/${id}`,
        method: 'GET',
      });

      setCategorie(response.data);
      if (response.data.subCategory.length > 0) {
        setSubExist(true);
        setParentTitle(response.data.title)
      }

      setValues({
        title: response.data.title,
        description: response.data.description !== null ? response.data.description : '',
        parent: response.data.parent,
        image: response.data.image
      });


    } catch (error) {
      FrCustomeErrorNorify()
    }
  };
  useEffect(() => {
    fetchData();
  }, [])


  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      parent: "",
      image: "",

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      try {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('description', values.description);

        const slug = generateSlug(values.title);
        formData.append('slug', slug);

        if (newImage) {
          await compressAndAppendImage(newImage as File, formData, 'imageFile',0.8)
        }

        const response = await apiRequest({
          route: `update-categorie/${id}`,
          method: 'POST',
          body: formData,
        });

        if (response.status === 200) {
          setIsLoading(false)
          UpdateNotify('Categorie');
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)

      }

      resetForm()
    },
  })

  const DeleteCategorie = async (categorieId: number) => {
    try {
      const response = await apiRequest({
        route: `categories/${categorieId}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Sous Categorie');
        fetchData();
      }
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };

  const SubSchema = Yup.object().shape({
    title: Yup.string().required('Le nom de sous categorie est obligatoire'),
    image: Yup.string().required("L'image de sous categorie est obligatoire"),
    description: Yup.string(),
  })

  const postData = async (formData: FormData) => {
    try {
      setIsSubLoading(true)
      await compressAndAppendImage(subFormik.values.image as unknown as File, formData, 'imageFile',0.8);

      const response = await apiRequest({
        route: `categories`,
        method: 'POST',
        body: formData
      });

      if (response.status === 201) {
        AddingNotify('Sous Categorie')
        fetchData();
        setIsSubLoading(false)
      }

    } catch (error) {
      FrCustomeErrorNorify()
      setIsSubLoading(false)
    }
  }

  const subFormik = useFormik({
    initialValues: {
      title: "",
      image: "",
      description: ""

    },
    validationSchema: SubSchema,
    onSubmit: async (values, { resetForm }) => {

      setIsSubLoading(true)
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('parent', String(id));

      const checkSlug = generateSlug(values.title);

      const checkSubCategorieResponse = await apiRequest({
        route: `check-subcategorie/${checkSlug}`,
        method: 'GET',
      });

      if (checkSubCategorieResponse.status === 200) {
        if (checkSubCategorieResponse.data.message === 'Found') {
          if (Number(checkSubCategorieResponse.data.parent_id) !== Number(id)) {

            const checkSlugParentResponse = await apiRequest({
              route: `check-categorie-by-parent/${checkSlug}/${id}`,
              method: 'GET',
            });

            if (checkSlugParentResponse.status === 200) {
              if (checkSlugParentResponse.data.message === 'Found') {
                errorNotify('Sous Categorie déjâ exist')
                setIsSubLoading(false)

              } else {

                const slug = `${categorie?.slug}-${generateSlug(values.title)}`

                const checkSlugParentResponse = await apiRequest({
                  route: `check-categorie-by-parent/${slug}/${id}`,
                  method: 'GET',
                });

                if (checkSlugParentResponse.status === 200) {
                  if (checkSlugParentResponse.data.message === 'Found') {
                    errorNotify('Sous Categorie déjâ exist')
                    setIsSubLoading(false)

                  } else {
                    const slug = `${categorie?.slug}-${generateSlug(values.title)}`
                    formData.append('slug', slug);
                    postData(formData)
                  }

                } else {
                  FrCustomeErrorNorify()
                  setIsSubLoading(false)
                }
              }

            } else {
              FrCustomeErrorNorify()
              setIsSubLoading(false)
            }


          } else {
            errorNotify('Sous Categorie déjâ exist')
            setIsSubLoading(false)
          }

        } else {

          const checkCategorieResponse = await apiRequest({
            route: `check-categorie/${checkSlug}`,
            method: 'GET',
          });

          if (checkCategorieResponse.status === 200) {
            if (checkCategorieResponse.data.message === 'Found') {
              const slug = `${categorie?.slug}-${generateSlug(values.title)}`
              formData.append('slug', slug);
              postData(formData)

            } else {
              const slug = generateSlug(values.title);
              formData.append('slug', slug);
              postData(formData)
            }

          } else {
            FrCustomeErrorNorify()
            setIsSubLoading(false)
          }
        }

      } else {
        FrCustomeErrorNorify()
        setIsSubLoading(false)
      }

    },
  })


  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Une Categorie</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom De Categorie</label>

                      <input
                        type='text'
                        name='title'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${process.env.REACT_APP_API_UPLOAD}/category_images/${values.image}`}
                            className='w-100 shadow'
                            alt=''
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>

                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                  <textarea
                    rows={6}
                    name='description'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Description'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  ></textarea>
                </div>

                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="image"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImage ? (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>
              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
            </div>
          </form>
        </div>

      </div>

      {SubExist ?

        <div className="card mb-5 mb-xl-10">
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#sub'
            aria-expanded='true'
            aria-controls='sub'
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>List Des Sous-Categories</h3>
            </div>
          </div>
          <div id='sub' className='collapse show'>
            <div className='card-body border-top p-9'>

              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-125px'>Image</th>
                      <th className='ps-4 min-w-100px'>Nouveau Image</th>
                      <th className='ps-4 min-w-125px'>Sous Categorie</th>
                      <th className='min-w-300px'>Description</th>
                      <th className='ps-4 min-w-125px'>Parent</th>
                      <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      categorie?.subCategory.map((sub, index) => {
                        return (
                          <SubCategoryItem
                            deleteSubCategorie={DeleteCategorie}
                            key={index}
                            data={sub}
                            categorieId={categorie.id}
                            className=""
                            fetchData={fetchData}
                          />
                        );
                      }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        : ''
      }

      {/* Start Add Sub Categorie */}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#add-sub'
          aria-expanded='true'
          aria-controls='add-sub'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Ajouter Une Sous Categorie</h3>
          </div>
        </div>

        <div id='add-sub' className='collapse show'>
          <form onSubmit={subFormik.handleSubmit} noValidate className='form'>

            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de sous categorie</label>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        {...subFormik.getFieldProps('title')}
                      />
                      {subFormik.touched.title && subFormik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{subFormik.errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>

                      <input
                        type="file"
                        name="image"
                        accept=".png, .jpg, .jpeg, .webp"
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.files && e.target.files[0]) {
                            subFormik.setFieldValue('image', e.target.files[0]);
                          }
                        }} />
                      {subFormik.touched.image && subFormik.errors.image && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{subFormik.errors.image}</div>
                        </div>
                      )}

                    </div>

                  </div>
                </div>
              </div>


              <div className='row mb-6'>
                <div className='col-lg-12 col-md-12 fv-row'>
                  <label className='col-lg-10 col-md-10 col-form-label fw-bold fs-6'>Description</label>

                  <textarea
                    rows={5}
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Description'
                    {...subFormik.getFieldProps('description')}
                  ></textarea>
                  {subFormik.touched.description && subFormik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{subFormik.errors.description}</div>
                    </div>
                  )}
                </div>

              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <SubmitButton isLoading={isSubloading} buttonLabel='Enregistrer les modifications' />
            </div>
          </form>
        </div>
      </div>
      {/* End Add Sub Categorie */}

    </>
  )
}

export default UpdateCategorie;