import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import { CategorieProps } from '../../helpers/Props';
import { useApiRequest } from '../../helpers/ApiRequest';
import { DeleteNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import CategoriesList from './CategoriesList';

const CategorieOrder: React.FC = () => {
    const [categories, setCategories] = useState<CategorieProps[]>([]);
    const apiRequest = useApiRequest();

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: 'categories-with-parent',
                method: 'GET',
            });

            setCategories(response.data);
        } catch (error) {
            FrCustomeErrorNorify();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={`card`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Liste Des Categorie</span>
                </h3>
                <div className='card-toolbar'>
                    <Link to='/categorie/add-categorie' className='btn btn-sm btn-light-primary'>
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                        Nouveau Categorie
                    </Link>
                    {/* Functionality not implemented yet */}
                    <button className='btn btn-info btn-sm ms-2 fw-bold' disabled>
                        <i className='bi bi-filter fs-4'></i>
                        Change Order (Drag & Drop)
                    </button>
                </div>
            </div>
            <div className='card-body py-3'>
                <CategoriesList categories={categories} />
            </div>
        </div>
    );
};

export default CategorieOrder;
