import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useApiRequest } from '../../helpers/ApiRequest'
import { formatDate } from '../../helpers/ApiRequest';
import { DeleteNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { PackProps } from '../../helpers/Props';
import { Pagination } from '@mui/material'
import axios from 'axios';
import { Loading } from '../../components/Loading';
import { HiOutlineRefresh } from 'react-icons/hi'

interface RequestParams {
  title: string;
  dateDebut?: string;
  dateFin?: string;
  status: number | null;
  page: number;
  itemsPerPage: number;
}

const PackList: React.FC = () => {
  const apiRequest = useApiRequest()
  const [packs, setPacks] = useState<PackProps[]>([]);

  // Pagination state
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)

  // Filter state
  const [packTitle, setPackTitle] = useState('');
  const [inputFilter, setInputFilter] = useState(false)
  const [status, setStatus] = useState<number | null>(null);
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/packs.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      });
      if (response.status === 200) {
        setPacks(response.data['hydra:member']);
        setTotalItems(response.data['hydra:totalItems']);
        setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
      } else {
        FrCustomeErrorNorify()
      }
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  const filterData = async () => {
    setIsFilter(true);
    setIsFilterLoading(true)

    try {

      const params: RequestParams = {
        title: packTitle || '',
        status: status && status === 1 ? status : status && 0,
        page: filterCurrentPage,
        itemsPerPage: 10
      };

      if (dateDebut) {
        params.dateDebut = dateDebut;
      }

      if (dateFin) {
        params.dateFin = dateFin;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/packs.jsonld`, {
        params: params,
        headers: {
          Accept: 'application/ld+json'
        }
      });

      if (response.status === 200) {
        setIsFilterLoading(false)
        setPacks(response.data['hydra:member']);
        setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
      } else {
        FrCustomeErrorNorify()
        setIsFilterLoading(false);
      }
    } catch (error) {
      FrCustomeErrorNorify()
      setIsFilterLoading(false);
    }
  };

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  }

  const handleFilterPageChange = (event: any, value: any) => {
    setFilterCurrentPage(value);
  }

  const resetFilter = () => {
    setPackTitle('')
    setStatus(null);
    setDateDebut('')
    setDateFin('')
    setIsFilter(false)
    setInputFilter(false)
    setCurrentPage(1)
    setFilterCurrentPage(1)
    fetchData();
  }

  const DeletePack = async (pack: number) => {
    try {
      const response = await apiRequest({
        route: `packs/${pack}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Pack');
        fetchData();
      }


    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, totalItems])

  useEffect(() => {
    if (isFilter) {
      filterData();
    }
  }, [filterCurrentPage])

  useEffect(() => {
    if (packTitle !== '' || status !== null || dateDebut !== '' || dateFin !== '') {
      setInputFilter(true);
    }
  }, [packTitle, dateDebut, dateFin, status])


  return (

    <>
      {/* Start Filter Card */}
      <div className={`card mb-4 py-5`}>

        <div className='card-body py-3'>
          <div className="container">
            <div className="row justify-content-start align-items-center">

              {/* Start Pack Name */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Nom de Pack
                </label>
                <input
                  className="form-control "
                  type="text"
                  aria-label="Search"
                  placeholder='Nom de Pack'
                  value={packTitle}
                  onChange={(e) => setPackTitle(e.target.value)} />

              </div>
              {/* End Pack Name */}

              {/* Start Date Debut */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Date Debut
                </label>
                <input
                  type='date'
                  name='dateDebut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date debut'
                  onChange={(e) => setDateDebut(e.target.value)}
                  value={dateDebut}
                />
              </div>
              {/* End Date Debut */}

              {/* Start Date Fin */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Date Fin
                </label>
                <input
                  type='date'
                  name='dateFin'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date Fin'
                  onChange={(e) => setDateFin(e.target.value)}
                  value={dateFin}
                />
              </div>
              {/* End Date Fin */}

              {/* Start Status */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Statut
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>

                  {/* active */}

                  <input
                    type='checkbox'
                    name='status'
                    id='active'
                    className='form-check-input scale-1'
                    checked={status === 1}
                    onChange={() => setStatus(1)}
                    value={1}
                  />
                  <label htmlFor='active' className='ms-3 col-form-label fw-bold fs-5'>
                    Active
                  </label>

                  {/* desactive */}

                  <input
                    type='checkbox'
                    name='status'
                    id='desactive'
                    className='form-check-input ms-4 scale-1'
                    checked={status === 0}
                    onChange={() => setStatus(0)}
                    value={0}
                  />
                  <label htmlFor='desactive' className='ms-3 col-form-label fw-bold fs-5'>
                    Desactive
                  </label>



                </div>
              </div>
              {/* End Status */}

              <div className="row">
                {/* Start Apply Filter */}
                <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                  {
                    !isFilterLoading ?
                      <>
                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                        </label>
                        <button className='btn btn-light-primary me-3 fw-bold' onClick={filterData} disabled={!inputFilter} >
                          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                          Filtrer
                        </button>
                      </>

                      :
                      <div className="me-3 mt-4 d-flex justify-content-start">
                        <Loading />
                      </div>
                  }
                </div>
                {/* End Apply Filter */}
              </div>

              {/* Start Reset Filter */}
              <div className="reset-filter ">
                <div className="ico" onClick={resetFilter}>
                  <HiOutlineRefresh className='filter-icon sshadow' />
                </div>
              </div>
              {/* End Reset Filter */}

            </div>
          </div>
        </div>

      </div>
      {/* Ens Filter Card */}

      {/* Start Orders List Card */}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste Des Packs</span>
          </h3>
          <div className='card-toolbar'>
            <Link to='/pack/add-pack' className='btn btn-sm btn-light-primary'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Nouveau pack
            </Link>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-125px rounded-start'>Image</th>
                  <th className='min-w-250px'>Title</th>
                  <th className='min-w-125px'>Prix</th>
                  <th className='min-w-150px'>Nombre Des Articles</th>
                  <th className='min-w-125px'>Date Debut</th>
                  <th className='min-w-125px'>Date Fin</th>
                  <th className='min-w-125px'>Status</th>
                  <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
                </tr>
              </thead>
              <tbody>
                {packs.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-70px'>
                            <img
                              src={`${process.env.REACT_APP_API_UPLOAD}/pack_images/${item.image}`}
                              className='w-100'
                              alt=''
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-dark  text-primary d-block mb-1 fs-6'>
                          {item.title}
                        </span>
                      </td>

                      <td>
                        <span className='text-info  text-primary d-block mb-1 fs-6'>
                          {item.tarif_regulier} DH
                        </span>
                      </td>

                      <td>
                        <span className='text-info  text-primary d-block mb-1 fs-6'>
                          {item.itemsQuantity} Produit
                        </span>
                      </td>

                      <td>
                        <span className='text-dark  text-primary d-block mb-1 fs-6'>
                          {formatDate(item.dateDebut)}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark  text-primary d-block mb-1 fs-6'>
                          {formatDate(item.dateFin)}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark  text-primary d-block mb-1 fs-6'>
                          {item.status === 1 ?
                            <span className='badge badge-light-success fs-6 px-4'>
                              Active
                            </span>

                            :
                            <span className='badge badge-light-warning fs-6 px-4'>
                              Desactive
                            </span>

                          }
                        </span>
                      </td>
                      <td className='text-end'>
                        <Link to={`/pack/update-pack/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                          <i className='bi bi-pen-fill fs-4'></i>
                        </Link>
                        <button onClick={() => DeletePack(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                          <i className='bi bi-trash-fill fs-4'></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {
          isFilter
            ? <Pagination
              count={filterTotalPages}
              page={filterCurrentPage}
              color='primary'
              onChange={handleFilterPageChange}
              className='d-flex justify-content-center'
            />
            :
            <Pagination
              count={totalPages}
              page={currentPage}
              color='primary'
              onChange={handlePageChange}
              className='d-flex justify-content-center'
            />
        }
      </div>
    </>
  )
}

export default PackList