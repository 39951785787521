import React, { ChangeEvent, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useApiRequest } from '../../helpers/ApiRequest'
import { DeleteNotify, FrCustomeErrorNorify, notify } from '../../helpers/Toastify'
import { FileProps, MarqueProps, ProductProps, UrlParamsProps } from '../../helpers/Props'
import { Loading } from '../../components/Loading';
import { useCategoriesContext } from '../../context/CategoriesContext'
import { HiOutlineRefresh } from 'react-icons/hi'
import { Pagination } from '@mui/material'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import XLSX from "xlsx";
import { referenceGenerator } from '@mbs-dev/react-helpers'
import ExportButton from '../../components/ExportButton'

const ProductList: React.FC = () => {

  const [products, setProducts] = useState<ProductProps[]>([]);
  const apiRequest = useApiRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingExcel, setIsUploadingExcel] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [isExportImages, setIsExportImages] = useState(false);
  const [isExportUpdate, setIsExportUpdate] = useState(false);
  const { categories } = useCategoriesContext();
  const [marques, setMarques] = useState<MarqueProps[]>([]);
  const [lastResponseUrl, setLastResponseUrl] = useState<string | null>(null);
  const storedUrl = localStorage.getItem('lastResponseUrl');
  const storedParams = localStorage.getItem('params');
  let urlParams: UrlParamsProps
  if (storedParams) {
    urlParams = JSON.parse(storedParams);
  }

  // Pagination state
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)

  // Filter state
  const [selectedCategory, setSelectedCategory] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [productReference, setProductReference] = useState('');
  const [productBarcode, setProductBarcode] = useState('');
  const [productDisponibilite, setProductDisponibilite] = useState('');
  const [popularProduct, setPopularProduct] = useState('');
  const [productMarque, setProductMarque] = useState('');
  const [productSubCategory, setProductSubCategory] = useState('');
  const [inputFilter, setInputFilter] = useState(false)
  const stored = localStorage.getItem('storedPage')

  let conditions = productMarque !== '' || productTitle !== '' || productReference !== '' || productBarcode !== '' || productDisponibilite !== '' || popularProduct !== '' || selectedCategory !== ''



  useEffect(() => {
    if (stored) {
      setCurrentPage(Number(stored))
    }
  }, [])

  const fetchMarquesData = async () => {
    try {
      const response = await apiRequest({
        route: 'marques.json',
        method: 'GET',
      });

      setMarques(response.data)
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  const handleCategoryChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedCategory(event.target.value);
  };

  const subCategories = categories.find(category => category.id === Number(selectedCategory))?.subCategory || [];

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/products.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      });
      if (response.status === 200) {
        setProducts(response.data['hydra:member']);
        setTotalItems(response.data['hydra:totalItems']);
        setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
      } else {
        FrCustomeErrorNorify()
      }
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  const filterData = async () => {
    setIsFilter(true);
    setIsLoading(true)

    try {
      const config: AxiosRequestConfig = {
        url: `${process.env.REACT_APP_API_URL}/products.jsonld`,
        params: {
          title: productTitle || null,
          disponibilite: productDisponibilite || null,
          popularProduct: popularProduct || null,
          reference: productReference || null,
          barcode: productBarcode || null,
          marque: productMarque || null,
          categorie: productSubCategory || selectedCategory || null,
          page: filterCurrentPage,
          itemsPerPage: 10
        },
        headers: {
          'Accept': 'application/ld+json',
        },
      }

      const filteredParams = Object.entries(config.params)
        .filter(([key, value]) => value !== null && value !== undefined)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      const response: AxiosResponse = await axios.get(config.url as string,
        {
          params: filteredParams,
          headers: config.headers,
        },
      );

      if (response.status === 200) {
        const requestUrl = `${config.url}?${new URLSearchParams(filteredParams).toString()}`;
        localStorage.setItem('lastResponseUrl', requestUrl);
        localStorage.setItem('params', JSON.stringify(filteredParams));
        // localStorage.setItem('storedPage', String(1))

        setIsLoading(false)
        setProducts(response.data['hydra:member']);
        setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
      } else {
        FrCustomeErrorNorify()
        setIsLoading(false);
      }
    } catch (error) {
      FrCustomeErrorNorify()
      setIsLoading(false);
    }
  };

  const DeleteProduct = async (productId: number) => {
    try {
      const response = await apiRequest({
        route: `products/${productId}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Produit');
        if (conditions) {
          filterData();
        } else {
          fetchData();
        }
      }
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  function handlePageChange(event: any, value: any) {
    setCurrentPage(value);
    localStorage.setItem('storedPage', String(value))
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value);
    localStorage.setItem('storedPage', String(value))
  }

  const filterStoredData = async (url: string) => {
    setIsFilter(true);
    setIsLoading(true)
    if (urlParams.title) {
      setProductTitle(urlParams.title)
    }
    if (urlParams.reference) {
      setProductReference(urlParams.reference)
    }
    if (urlParams.barcode) {
      setProductBarcode(urlParams.barcode)
    }
    if (urlParams.marque) {
      setProductMarque(urlParams.marque)
    }
    if (Number(urlParams.categorie) > 10) {
      setProductSubCategory(urlParams.categorie)
    } else {
      setSelectedCategory(urlParams.categorie)
    }
    if (urlParams.disponibilite) {
      setProductDisponibilite(urlParams.disponibilite)
    }
    if (urlParams.popularProduct) {
      setPopularProduct(urlParams.popularProduct)
    }
    if (urlParams.page) {
      setFilterCurrentPage(urlParams.page)
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Accept: 'application/ld+json'
        }
      });

      if (response.status === 200) {
        setIsLoading(false)
        setProducts(response.data['hydra:member']);
        setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
      } else {
        FrCustomeErrorNorify()
        setIsLoading(false);
      }
    } catch (error) {
      FrCustomeErrorNorify()
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMarquesData();

    if (storedUrl) {
      setLastResponseUrl(storedUrl);
      filterStoredData(storedUrl)
    }
  }, [])

  useEffect(() => {
    if (!storedUrl) {
      fetchData();
    }

  }, [currentPage, totalItems])

  useEffect(() => {
    if (isFilter) {
      filterData();
    }
  }, [filterCurrentPage])

  useEffect(() => {
    if (conditions) {
      setInputFilter(true);
    }
  }, [productMarque, productTitle, productReference, productBarcode, productDisponibilite, popularProduct, selectedCategory])


  const resetFilter = () => {
    setProductTitle('')
    setProductReference('')
    setProductBarcode('')
    setProductDisponibilite('')
    setPopularProduct('')
    setProductMarque('')
    setSelectedCategory('')
    setProductSubCategory('')
    setIsFilter(false)
    setInputFilter(false)
    setCurrentPage(1)
    setFilterCurrentPage(1)
    localStorage.removeItem('storedPage')
    localStorage.removeItem('lastResponseUrl')
    localStorage.removeItem('params')
    setDefaultPage(1);
    fetchData();
  }

  const changeDisponibilite = async (productId: number, disponibilite: string) => {
    try {

      const formData = new FormData()
      formData.append('id', String(productId))
      formData.append('disponibilite', disponibilite)

      const response = await apiRequest({
        route: `update-disponibilite`,
        method: 'POST',
        body: formData
      });

      if (response.status === 200) {
        if (disponibilite === 'enstock') {


          if (conditions) {
            filterData();
          } else {
            fetchData();
          }
          notify("Le produit a été mis en enstock ");
        }
        if (disponibilite === 'rupture') {


          if (conditions) {
            filterData();
          } else {
            fetchData();
          }
          notify("Le produit a été mis en rupture");
        }
      }

    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  const changePopularProduct = async (productId: number, popularProduct: string) => {
    try {

      const formData = new FormData()
      formData.append('id', String(productId))
      formData.append('popularProduct', popularProduct)

      const response = await apiRequest({
        route: `update-popular-product`,
        method: 'POST',
        body: formData
      });

      if (response.status === 200) {
        if (popularProduct === 'enable') {


          if (conditions) {
            filterData();
          } else {
            fetchData();
          }
          notify("Le produit a été mis comme produit populaire");
        }
        if (popularProduct === 'disable') {


          if (conditions) {
            filterData();
          } else {
            fetchData();
          }
          notify("Le produit a été supprimé en tant que produit populaire");
        }
      }

    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  // Export Data
  const exportData = async (route: string, fileName: string) => {
    const response = await apiRequest({
      route: route,
      method: 'GET',
    });

    if (response.status === 200) {
      const naming = `${fileName}-${referenceGenerator(undefined, 'day', 'month', 'year')}.xlsx`;

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(response.data);

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, naming);
    }
  }

  const exportProducts = async () => {
    setIsExport(true)
    await exportData('export-products', 'HB-Produits')
    setIsExport(false)
  }

  const exportProductsImages = async () => {
    setIsExportImages(true)
    await exportData('export-products-images', 'HB-Images')
    setIsExportImages(false)
  }

  const exportProductsForUpdate = async () => {
    setIsExportUpdate(true)
    await exportData('export-for-update', 'HB-ProductsList')
    setIsExportUpdate(false)
  }


  // Update Products using Excel file
  const Schema = Yup.object().shape({
    excelFile: Yup.mixed()
      .required("Sélectionner un fichier Excel")
      .test(
        "fileType",
        "Seuls les fichiers Excel (.xlsx) sont autorisés",
        (value) => {
          if (!value) return true;
          return value && value.name.endsWith(".xlsx");
        }
      )
  });

  const formik = useFormik({
    initialValues: {
      excelFile: ""
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      setIsUploadingExcel(true)

      const formData = new FormData();
      formData.append('excelFile', values.excelFile);

      try {
        const response = await apiRequest({
          route: `upload-excel`,
          method: 'POST',
          body: formData
        });

        if (response.status === 200) {
          setIsUploadingExcel(false)
          notify(response.data.message)
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsUploadingExcel(false)
      }

    },
  })

  const selectedFile = formik.values.excelFile as unknown as FileProps


  return (

    <>
      {/* Start Filter Card */}
      <div className={`card mb-4 py-5`}>

        <div className='card-body py-3'>
          <div className="container">
            <div className="row justify-content-start align-items-center">

              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Reference
                </label>
                <input
                  className="form-control "
                  type="text"
                  aria-label="Search"
                  placeholder='Reference'
                  value={productReference}
                  onChange={(e) => setProductReference(e.target.value)} />

              </div>

              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Nom de Produit
                </label>
                <input
                  className="form-control "
                  type="text"
                  aria-label="Search"
                  placeholder='Nom de Produit'
                  value={productTitle}
                  onChange={(e) => setProductTitle(e.target.value)} />

              </div>

              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Marque</label>
                <select
                  name="marque"
                  className="form-select"
                  value={productMarque}
                  onChange={(e) => setProductMarque(e.target.value)}
                >
                  <option value="">Marque</option>
                  {marques.map((marque: any, index) => {
                    return (
                      <option key={index} value={marque.id}>
                        {marque.title}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Categorie</label>
                <select
                  name="categorie"
                  className="form-select"
                  // value={selectedCategory}
                  // onChange={handleCategoryChange}
                  // {...formik.getFieldProps('categorie')}
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  <option value="" disabled>Categorie</option>
                  {categories.map((categorie: any, index) => {
                    return (
                      <option key={index} value={categorie.id}>
                        {categorie.title}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                <label className='col-lg-10 col-md-10 col-form-label fw-bold fs-6'>Sous Categorie</label>
                <select
                  className="form-select"
                  onChange={(e) => setProductSubCategory(e.target.value)}
                  value={productSubCategory}
                >
                  <option value="">Sous Categorie</option>
                  {subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.title}
                    </option>
                  ))}
                </select>
              </div>

              {/* Start Disponibilite */}
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                  <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                    Code barre
                  </label>
                  <input
                    className="form-control "
                    type="text"
                    aria-label="Search"
                    placeholder='Code barre'
                    value={productBarcode}
                    onChange={(e) => setProductBarcode(e.target.value)} />

                </div>


                <div className="col-md-6 col-lg-6 col-xl-4 col-12">
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                    Disponibilite
                  </label>
                  <div className='form-check form-check-custom form-check-solid form-check-info'>

                    <input
                      type='checkbox'
                      name='disponibilite'
                      id='all'
                      className='form-check-input scale-1'
                      checked={productDisponibilite === ''}
                      onChange={() => setProductDisponibilite('')}
                      value={''}
                    />
                    <label htmlFor='' className='ms-3 col-form-label fw-bold fs-5'>
                      Toutes
                    </label>

                    <input
                      type='checkbox'
                      name='disponibilite'
                      id='enstock'
                      className='form-check-input ms-4 scale-1'
                      checked={productDisponibilite === 'enstock'}
                      onChange={() => setProductDisponibilite('enstock')}
                      value={'enstock'}
                    />
                    <label htmlFor='enstock' className='ms-3 col-form-label fw-bold fs-5'>
                      En Stock
                    </label>


                    <input
                      type='checkbox'
                      name='disponibilite'
                      id='rupture'
                      className='form-check-input ms-4 scale-1'
                      checked={productDisponibilite === 'rupture'}
                      onChange={() => setProductDisponibilite('rupture')}
                      value={'rupture'}
                    />
                    <label htmlFor='rupture' className='ms-3 col-form-label fw-bold fs-5'>
                      Rupture
                    </label>

                  </div>
                </div>

                <div className="col-md-6 col-lg-6 col-xl-4 col-12">
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                    Produit Populaire
                  </label>
                  <div className='form-check form-check-custom form-check-solid form-check-info'>

                    <input
                      type='checkbox'
                      name='popularProduct'
                      id='all'
                      className='form-check-input scale-1'
                      checked={popularProduct === ''}
                      onChange={() => setPopularProduct('')}
                      value={''}
                    />
                    <label htmlFor='' className='ms-3 col-form-label fw-bold fs-5'>
                      Toutes
                    </label>

                    <input
                      type='checkbox'
                      name='popularProduct'
                      id='enable'
                      className='form-check-input ms-4 scale-1'
                      checked={popularProduct === 'enable'}
                      onChange={() => setPopularProduct('enable')}
                      value={'enable'}
                    />
                    <label htmlFor='enable' className='ms-3 col-form-label fw-bold fs-5'>
                      Active
                    </label>


                    <input
                      type='checkbox'
                      name='popularProduct'
                      id='disable'
                      className='form-check-input ms-4 scale-1'
                      checked={popularProduct === 'disable'}
                      onChange={() => setPopularProduct('disable')}
                      value={'disable'}
                    />
                    <label htmlFor='disable' className='ms-3 col-form-label fw-bold fs-5'>
                      Désactive
                    </label>

                  </div>
                </div>
              </div>
              {/* End Disponibilite */}

              <div className="reset-filter ">
                <div className="ico" onClick={resetFilter}>
                  <HiOutlineRefresh className='filter-icon sshadow' />
                </div>
              </div>

            </div>

            <div className="row justify-content-start align-items-center">
              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                {
                  !isLoading ?
                    <>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6 '>
                      </label>
                      <button className='btn btn-light-primary me-3 fw-bold mt-4' onClick={filterData} disabled={!inputFilter} >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                        Filtrer
                      </button>

                    </>

                    :
                    <div className="me-3 mt-4 d-flex justify-content-start">
                      <Loading />
                    </div>
                }
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* End Filter Card */}


      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste Des Produits</span>
          </h3>
          <div className='card-toolbar'>
            <Link to='/product/add-product' className='btn btn-sm btn-primary me-10px'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Nouveau Produit
            </Link>

            <form onSubmit={formik.handleSubmit} noValidate className='d-flex form w-235px h-35px'>
              {/* <div className=' row ms-4 text-end'> */}
              {/* <div className="row text-start mb-3">
                  Mettre à jour la liste des produits à l’aide d’un fichier Excel :
                </div> */}

              <div className="row me-2px">
                <label htmlFor="dropzone-file" className="d-flex w-130px h-12px cursor-pointer radius-10px">
                  <input
                    type="file"
                    id='dropzone-file'
                    name="excelFile"
                    accept=".xlsx"
                    className='form-control form-control-sm form-control-solid mb-3mb-lg-0'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files && e.target.files[0]) {
                        formik.setFieldValue('excelFile', e.target.files[0]);
                      }
                    }} />
                </label>

                <div className='mt-1 pt-0 w-200px position-absolute mt-35px'>
                  <p>
                    {selectedFile !== undefined ? selectedFile.name : null}
                  </p>
                  <div>
                    {formik.touched.excelFile && formik.errors.excelFile && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.excelFile}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="ps-1">
                  {isUploadingExcel ?
                    <div className="">
                      <span className='btn btn-icon btn-light-primary btn-sm'>
                        <Loading />
                      </span>
                    </div>
                    :
                    <button className='d-flex btn btn-info btn-sm ms2 fw-bold' type="submit">
                      <i className='bi bi-cloud-upload fs-4'></i>
                      Enregistrer
                    </button>
                  }

                  {/* <div className='mt-1 pt-0 w-200px'>
                    <p>
                      {selectedFile !== undefined ? selectedFile.name : null}
                    </p>
                    <div>
                      {formik.touched.excelFile && formik.errors.excelFile && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.excelFile}</div>
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>



              {/* </div> */}
            </form>

            <ExportButton
              isExporting={isExport}
              onClick={exportProducts}
              buttonText="Exporter la liste"
              buttonClass="success"
              loadingClass="success"
            />
            <ExportButton
              isExporting={isExportImages}
              onClick={exportProductsImages}
              buttonText="Exporter les images"
              buttonClass="info"
              loadingClass="info"
            />
            <ExportButton
              isExporting={isExportUpdate}
              onClick={exportProductsForUpdate}
              buttonText="Exporter la list pour la modifier"
              buttonClass="dark"
              loadingClass="dark"
            />

          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-150px rounded-start'>Image</th>
                  <th className='min-w-100px'>Reference</th>
                  <th className='min-w-150px'>Article</th>
                  <th className='min-w-150px'>Marque</th>
                  <th className='min-w-100px'>Prix</th>
                  <th className='min-w-150px'>Disponibilite</th>
                  <th className='min-w-100px'>Populaire</th>
                  <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
                </tr>
              </thead>
              <tbody>
                {products.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-50px'>
                            <img
                              src={`${process.env.REACT_APP_API_UPLOAD}/product_images/${item.image}`}
                              className='w-100'
                              alt=''
                            />
                          </div>
                        </div>
                      </td>

                      <td>
                        <span className='text-dark  text-primary d-block mb-1 fs-6'>
                          {item.reference}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark  text-primary d-block mb-1 fs-6'>
                          {item.title}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark  text-primary d-block mb-1 fs-6'>
                          {item.marque.title}
                        </span>
                      </td>

                      <td>
                        <span className='text-info fw-bold text-primary d-block mb-1 fs-6'>
                          {item.tarif_regulier} DH
                        </span>
                      </td>

                      <td className=''>
                        {item.disponibilite === 'enstock' ?
                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-success '>
                            <input
                              className='form-check-input form-check-input-true'
                              type='checkbox'
                              value='rupture'
                              name='disponibilite'
                              onChange={() => changeDisponibilite(item.id, 'rupture')}
                              checked={item.disponibilite === 'enstock' ? true : false}
                            />
                            <label className='form-check-label fw-bold'>En Stock</label>
                          </div>
                          :
                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-danger'>
                            <input
                              className='form-check-input form-check-input-false'
                              type='checkbox'
                              value='enstock'
                              name='disponibilite'
                              onChange={() => changeDisponibilite(item.id, 'enstock')}
                              checked={item.disponibilite === 'enstock'}
                            />
                            <label className='form-check-label fw-bold'>Rupture</label>
                          </div>
                        }
                      </td>

                      <td className=''>
                        {item.popularProduct === 'enable' ?
                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-success '>
                            <input
                              className='form-check-input form-check-input-true'
                              type='checkbox'
                              value='disable'
                              name='popularProduct'
                              onChange={() => changePopularProduct(item.id, 'disable')}
                              checked={item.popularProduct === 'enable' ? true : false}
                            />
                            {/* <label className='form-check-label fw-bold'>En Stock</label> */}
                          </div>
                          :
                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-danger'>
                            <input
                              className='form-check-input form-check-input-false'
                              type='checkbox'
                              value='enable'
                              name='popularProduct'
                              onChange={() => changePopularProduct(item.id, 'enable')}
                              checked={item.popularProduct === 'enable'}
                            />
                            {/* <label className='form-check-label fw-bold'>Rupture</label> */}
                          </div>
                        }
                      </td>

                      {/* <td className='d-none'>
                        {item.disponibilite === 'enstock' ?
                          <span className='badge badge-light-success fs-6 fw-bold px-4 c-pointer' onClick={() => changeDisponibilite(item.id, 'rupture')}>
                            En stock
                          </span>
                          :
                          <span className='badge badge-light-danger fs-6 fw-bold px-4 c-pointer' onClick={() => changeDisponibilite(item.id, 'enstock')}>
                            Rupture
                          </span>
                        }
                      </td> */}

                      <td className='text-end'>
                        <Link to={`/product/update-product/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                          <i className='bi bi-pen-fill fs-4'></i>
                        </Link>
                        <button onClick={() => DeleteProduct(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                          <i className='bi bi-trash-fill fs-4'></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {isFilter
          ? <Pagination
            count={filterTotalPages}
            page={filterCurrentPage}
            color='primary'
            onChange={handleFilterPageChange}
            className='d-flex justify-content-center'
          />
          :
          <Pagination
            count={totalPages}
            page={currentPage}
            color='primary'
            onChange={handlePageChange}
            className='d-flex justify-content-center'
          />
        }
      </div>

      {/* Start Filter Card */}
      <div className={`card mt-2 d-none`}>
        <div className='card-body p-1'>
          <div className="container">
            <div className="row justify-content-center align-items-center">

              <div className="col-4 money d-none">
                <input
                  type="text"
                  placeholder='Numéro de page'
                  min={1}
                  defaultValue={defaultPage}
                  className='form-control form-control-lg form-control-dark'
                  onChange={(e) => {
                    const inputText = e.target.value;
                    const numbersOnly = inputText.replace(/\D/g, '');
                    if (Number(numbersOnly) !== 0 && numbersOnly.length > 0 && Number(numbersOnly) <= Number(totalPages)) {
                      setCurrentPage(Number(numbersOnly));
                      if (isFilter) {
                        setFilterCurrentPage(Number(numbersOnly));
                      }
                      setDefaultPage(Number(numbersOnly));
                      localStorage.setItem('storedPage', numbersOnly)
                    }
                    e.target.value = numbersOnly;
                  }} />
              </div>

              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='row ms-4 text-end'>
                  <div className="row text-start mb-3">
                    Mettre à jour la liste des produits à l’aide d’un fichier Excel :
                  </div>

                  <div className="row">
                    <label htmlFor="dropzone-file" className="d-flex flex-column h-100px w-280px cursor-pointer radius-10px border border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                      <div className="d-flex flex-column align-items-center justify-content-center pb-6 pt-1">
                        <svg className="mb-3 h-20 w-20 text-primary " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                        </svg>
                        <p className="mb-2 text-sm text-dark ">
                          <span className="font-semibold">Cliquez pour charger un fichier Excel</span>
                        </p>
                        <p className="text-xs text-dark ">.xlsx</p>

                      </div>
                      <input
                        type="file"
                        id='dropzone-file'
                        name="excelFile"
                        accept=".xlsx"
                        className='form-control form-control-sm form-control-solid mb-3mb-lg-0 d-none'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.files && e.target.files[0]) {
                            formik.setFieldValue('excelFile', e.target.files[0]);
                          }
                        }} />

                    </label>

                    <div className="col-12 col-lg-4 text-start ps-2">
                      {isUploadingExcel ?
                        <div className="">
                          <span className='btn btn-icon btn-light-primary btn-sm'>
                            <Loading />
                          </span>
                        </div>
                        :
                        <button className='btn btn-primary btn-sm ms2 fw-bold' type="submit">
                          <i className='bi bi-cloud-upload fs-4'></i>
                          Enregistrer les modifications
                        </button>
                      }

                      <div className='mt-5 pt-2'>
                        <p>
                          {selectedFile !== undefined ? selectedFile.name : null}
                        </p>
                        <div>
                          {formik.touched.excelFile && formik.errors.excelFile && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.excelFile}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      {/* End Filter Card */}
    </>
  )
}

export default ProductList