import { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { ReOrderCard } from "./ReOrderCard";
import { ReOrderSliderProps as ReOrderInterface } from "../../helpers/Props";
import { useReOrderSliderContext } from "../../context/ReOrderSliderContext";

export default function ReOrderSlider({
    sliders,
}: {
    sliders: ReOrderInterface[];
}) {
    const [cards, setCards] = useState(sliders);
    const { card, setCard } = useReOrderSliderContext();
    useEffect(() => {
        setCard(cards);
    }, [cards]);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setCards((prevCards: ReOrderInterface[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as ReOrderInterface],
                ],
            })
        );
        setCard((prevCards: ReOrderInterface[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as ReOrderInterface],
                ],
            })
        );

    }, []);

    const renderCard = useCallback(
        (card: { id: number; image: string }, index: number) => {
            return (
                <ReOrderCard
                    key={card.id}
                    index={index}
                    id={card.id}
                    image={card.image}
                    moveCard={moveCard}
                />
            );
        },
        [moveCard]
    );

    return (
        <>
            <div className="max-w-md">
                {cards.map((card, i) => renderCard(card, i))}
            </div>
        </>
    );
}
