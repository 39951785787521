/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { useApiRequest } from '../../../../app/helpers/ApiRequest'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget11: React.FC<Props> = ({ className, chartColor, chartHeight }) => {

  const [todaySalles, setTodaySalles] = useState<any>(null)
  const [monthlySalles, setMonthlySalles] = useState<any>(null)
  // const [AnnualSalles, setAnnualSalles] = useState<any>(null)
  // const [totalOrders, setTotalOrders] = useState<any>(null)
  // const [productsSold, setProductsSold] = useState<any>(null)
  const apiRequest = useApiRequest()

  const getSAlles = async () => {
    try {
      const todayResponse = await apiRequest({
        route: 'today-salles',
        method: 'GET',
      })
      const monthlyResponse = await apiRequest({
        route: 'salles-bymonth',
        method: 'GET',
      })
      // const annualResponse = await apiRequest({
      //   route: 'annual-salles',
      //   method: 'GET',
      // })
      // const ordersResponse = await apiRequest({
      //   route: 'today-orders',
      //   method: 'GET',
      // })
      // const productsSoldResponse = await apiRequest({
      //   route: 'today-products-sold',
      //   method: 'GET',
      // })

      setTodaySalles(todayResponse.data.totalSalles)
      setMonthlySalles(monthlyResponse.data.totalSalles)
      // setAnnualSalles(annualResponse.data.totalSalles)
      // setTotalOrders(ordersResponse.data.totalOrders)
      // setProductsSold(productsSoldResponse.data.productsSold)

    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSAlles()
  }, [])

  // console.log(monthlySalles);


  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  const refreshChart = (data: any) => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, data))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart(monthlySalles)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, monthlySalles])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>Vente</span>
            {/* <span className='text-gray-400 fw-semibold'>Oct 8 - Oct 26 2021</span> */}
          </div>
          <div className={`fw-bold fs-3 text-${chartColor}`}>Ventes du jour: <span className='text-info'>{todaySalles === null || todaySalles === 0 ? todaySalles : parseFloat(todaySalles).toFixed(2)} DH</span></div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, monthlySalles: any): ApexOptions => {
  if (!monthlySalles) {
    return {
      series: [],
      chart: {
        fontFamily: 'inherit',
        type: 'line',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      // ... other default chart options
    }
  }

  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)

  return {
    series: [
      {
        name: 'Total ventes',
        data: [
          monthlySalles['1'],
          monthlySalles['2'],
          monthlySalles['3'],
          monthlySalles['4'],
          monthlySalles['5'],
          monthlySalles['6'],
          monthlySalles['7'],
          monthlySalles['8'],
          monthlySalles['9'],
          monthlySalles['10'],
          monthlySalles['11'],
          monthlySalles['12'],
        ],
      },
    ],

    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    xaxis: {
      categories: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },

    yaxis: {
      labels: {
        formatter: function (value: any) {
          return value === null || value === 0 ? value : parseFloat(value).toFixed(2) + '  DH'
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value: any) {
          return value === null || value === 0 ? value : parseFloat(value).toFixed(2) + '  DH'
        },
      },
    },
    colors: [baseColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export { MixedWidget11 }