import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { generateSlug, useApiRequest } from '../../helpers/ApiRequest';
import { useParams } from 'react-router-dom';
import { UFormatDate } from '../../helpers/ApiRequest';
import { UpdateNotify, errorNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { ProductProps } from '../../helpers/Props';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import SubmitButton from '../../components/submitButton/submitButton';
import { processAndAppendImage } from '../../helpers/imageProccessing/Imagecompressor';

const Schema = Yup.object().shape({
  title: Yup.string().required('Ce champ est obligatoire'),
  tarif_regulier: Yup.number()
    .required('Ce champ est obligatoire')
    .typeError('Ce champ doit être un nombre')
    .positive('Ce champ doit être un nombre positif'),
  dateDebut: Yup.date().required('Ce champ est obligatoire'),
  dateFin: Yup.date().required('Ce champ est obligatoire'),
});

const UpdatePack = () => {
  const [isloading, setIsLoading] = useState(false)
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [status, setStatus] = useState(1);
  const apiRequest = useApiRequest()
  const { id } = useParams();
  const animatedComponents = makeAnimated();
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [isChange, setIsChange] = useState(false);
  const [imagefile, setImage] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");
  const [newImage, setNewImage] = useState<File | null | any>(null);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      tarif_regulier: '',
      dateDebut: '',
      dateFin: '',
    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      setIsLoading(true)

      if (isChange && selectedProducts.length === 0) {
        errorNotify('Ajouter des produis!')
        setIsLoading(false)
        return
      }

      for (let i = 0; i < selectedProducts.length; i++) {
        formData.append("packItem[]", selectedProducts[i]);
      }

      if (isChange) { formData.append('itemsQuantity', String(selectedProducts.length)); }

      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('tarif_regulier', values.tarif_regulier);
      formData.append('status', String(status));
      formData.append('dateDebut', values.dateDebut);
      formData.append('dateFin', values.dateFin);

      try {

        const slug = generateSlug(values.title);

        const response = await apiRequest({
          route: `check-pack-by-sid/${id}/${slug}`,
          method: 'GET',
        });

        if (response.status === 200) {
          if (response.data.message === 'Found') {
            errorNotify('Pack déjâ exist')
            setIsLoading(false)

          } else {
            formData.append('slug', slug);

            if (newImage) {
              const imagefileFieldName = 'imageFile';
              await processAndAppendImage(newImage as File, formData, imagefileFieldName);
            }

            const response = await apiRequest({
              route: `update-pack/${id}`,
              method: 'POST',
              body: formData,
            });

            if (response.status === 200) {
              setIsLoading(false)
              UpdateNotify('Pack');
              fetchPackData();
            }
          }

        } else {
          FrCustomeErrorNorify()
          setIsLoading(false)
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)
      }

    },
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;

  const fetchPackData = useCallback(async () => {
    try {

      const response = await apiRequest({
        route: `packs/${id}.json`,
        method: 'GET',
      });

      setValues({
        title: response.data.title,
        description: response.data.description,
        tarif_regulier: response.data.tarif_regulier,
        dateDebut: UFormatDate(response.data.dateDebut),
        dateFin: UFormatDate(response.data.dateFin),
      });

      setStatus(response.data.status);

      const packItems = response.data.packItems.map((item: any) => ({
        value: item.product.id,
        label: `${item.product.title} | ${item.product.tarif_regulier} DH`,
      }));

      setDefaultOptions(packItems);

    } catch (error) {
      FrCustomeErrorNorify()
    }
  }, [id, setValues]);


  const fetchProductsData = async () => {
    try {
      const response = await apiRequest({
        route: 'products.json?disponibilite=enstock',
        method: 'GET',
      });

      setProducts(response.data)
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };


  useEffect(() => {
    fetchProductsData();
    fetchPackData();
  }, [])

  const options = products.map((product: any) => ({
    value: product.id,
    label: `${product.title} | ${product.tarif_regulier} DH`,
  }));

  const handleChangeSelect = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => (option.value));
    setSelectedProducts(selectedValues);
    setIsChange(true);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Modifier pack</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Titre de Pack
                </label>
                <input
                  type='text'
                  name='title'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Title'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                />
                {touched.title && errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.title}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Prix
                </label>
                <input
                  type='number'
                  name='tarif_regulier'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Prix'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tarif_regulier}
                />
                {touched.tarif_regulier && errors.tarif_regulier && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.tarif_regulier}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Status
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>
                  <input
                    type='radio'
                    name='status'
                    id='active'
                    className='form-check-input form-check-disponibilite'
                    checked={status === 1}
                    onChange={() => setStatus(1)}
                    onBlur={handleBlur}
                    value={1}
                  />
                  <label htmlFor='active' className='ms-2 fs-5 col-form-label fw-bold'>
                    Active
                  </label>

                  <input
                    type='radio'
                    name='status'
                    id='desactive'
                    className='form-check-input ms-4 form-check-deactive form-check-disponibilite'
                    checked={status === 0}
                    onChange={() => setStatus(0)}
                    onBlur={handleBlur}
                    value={0}
                  />
                  <label htmlFor='desactive' className='ms-2 fs-5 col-form-label fw-bold'>
                    Desactive
                  </label>

                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Date de debut de pack
                </label>
                <input
                  type='date'
                  name='dateDebut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de debut de pack'
                  min={new Date().toISOString().split('T')[0]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateDebut}
                />
                {touched.dateDebut && errors.dateDebut && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.dateDebut}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Date de fin de pack
                </label>
                <input
                  type='date'
                  name='dateFin'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de fin de pack'
                  min={values.dateDebut}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateFin}
                  disabled={!values.dateDebut}

                />
                {touched.dateFin && errors.dateFin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.dateFin}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                <input
                  type="file"
                  name="image"
                  accept=".png, .jpg, .jpeg, .webp"
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={handleImageChange} />

                {newImage ? (
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-70px'>
                      <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                    </div>
                  </div>
                ) : (
                  ''
                )}

              </div>


            </div>

            <div className="row mb-6">
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Liste Des Produits
                </label>

                {
                  defaultOptions.length > 0
                    ?
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      defaultValue={defaultOptions}
                      options={options}
                      onChange={handleChangeSelect}
                    />
                    :
                    <span className='indicator-progress fs-4 fw-bold text-primary' style={{ display: 'block' }}>
                      Chargement Des Produits...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                }

              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-12 col-md-12 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <textarea
                  className='form-control border-1 p-0 ps-3 pt-1 pe-10 resize-none min-h-25px'
                  rows={6}
                  placeholder='description'
                  {...formik.getFieldProps('description')}

                ></textarea>

              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
          </div>
        </form>
      </div>
    </div>

  );
};

export default UpdatePack;