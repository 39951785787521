import Compressor from 'compressorjs';
// import sizeOf from 'image-size';
import { useEffect, useRef, useState } from 'react';
// import sharp from 'sharp';
import removeTransparentBackground from './removeTransparentBackground';
// import removeTransparentBackground from 'react-remove-transparent-bg';

interface TransparentDimensions {
  width: number;
  height: number;
}

const ImageCompressor = async (imageFile: File | Blob, quality: number = 0.2) => {
  const options = {
    quality: quality,
    // maxWidth: 800,
    // maxHeight: 800,
    mimeType: 'image/webp',
    // convertTypes:'image/png'
  };

  try {
    const compressedImage = await new Promise((resolve, reject) => {
      new Compressor(imageFile, {
        ...options,
        success: resolve,
        error: reject,
      });
    });

    return compressedImage;
  } catch (error) {
    console.error('Compression error:', error);
    return null;
  }
};


// const calculateTransparentDimensions = async (imageFile: { path: sharp.SharpOptions | undefined; }) => {
//   // Load the image using sharp
//   const image = sharp(imageFile.path);

//   // Convert the image to RGBA format
//   image.toFormat('png');

//   // Ensure the image has an alpha channel (transparency)
//   image.ensureAlpha();

//   // Get the image metadata, including width and height
//   const metadata = await image.metadata();

//   // Calculate the dimensions of the transparent part
//   const transparentDimensions = {
//     width: metadata.width,
//     height: metadata.height,
//   };

//   return transparentDimensions;
// };

// // Function to resize the image
// const resizeImage = async (imageFile: any | File | Blob, width: any, height: any) => {
//   // Load the image using sharp
//   const image = sharp(imageFile.path);

//   // Resize the image while maintaining aspect ratio
//   image.resize({
//     width,
//     height,
//     fit: sharp.fit.cover, // You can choose 'cover', 'contain', or other options
//     position: sharp.strategy.entropy, // You can choose the position strategy
//   });

//   // Convert the image to a web-friendly format (e.g., webp)
//   image.toFormat('webp');

//   // Save the resized image to a buffer
//   const resizedBuffer = await image.toBuffer();

//   // Create a File object from the buffer
//   const resizedImage = new File([resizedBuffer], imageFile.name, {
//     type: 'image/webp', // Set the appropriate MIME type
//   });

//   return resizedImage;
// };



const calculateTransparentDimensions = async (imageFile: File): Promise<TransparentDimensions> => {
  return new Promise<TransparentDimensions>((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(imageFile);

    image.onload = () => {
      const transparentDimensions = {
        width: image.width || 0,
        height: image.height || 0,
      };
      resolve(transparentDimensions);
    };

    image.onerror = () => {
      resolve({ width: 0, height: 0 });
    };
  });
};

// Function to resize the image
const resizeImage = async (
  imageFile: File,
  width: number,
  height: number
): Promise<File | null> => {
  return new Promise<File | null>((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(imageFile);

    image.onload = () => {
      // Create a canvas for resizing
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        resolve(null);
        return;
      }

      // Set canvas dimensions
      canvas.width = width;
      canvas.height = height;

      // Draw the image on the canvas with the new dimensions
      ctx.drawImage(image, 0, 0, width, height);

      // Convert the canvas content to a Blob
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const resizedImage = new File([blob], imageFile.name, {
              type: 'image/webp', // Set the appropriate MIME type
            });
            resolve(resizedImage);
          } else {
            resolve(null);
          }
        },
        'image/webp',
        1 // Image quality (adjust as needed)
      );
    };

    image.onerror = () => {
      resolve(null);
    };
  });
};



const RemoveTransparentBackgroundd = (imagePath: any) => {
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = imagePath;

    img.onload = () => {
      // Create a canvas to process the image
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas dimensions to match the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      ctx?.drawImage(img, 0, 0);

      // Get image data
      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
      const { data }: any = imageData;

      // Calculate dimensions of the non-transparent part
      let minX = canvas.width;
      let minY = canvas.height;
      let maxX = 0;
      let maxY = 0;

      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const pixelIndex = (y * canvas.width + x) * 4; // Each pixel has 4 values: RGBA
          const alpha = data[pixelIndex + 3];

          if (alpha > 0) {
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
          }
        }
      }

      // Calculate new image dimensions based on transparent part dimensions
      const newWidth = maxX - minX + 1;
      const newHeight = maxY - minY + 1;

      // Update state with the new image dimensions
      setImageDimensions({ width: newWidth, height: newHeight });
    };
  }, [imagePath]);

}


const fileNaming = (imagefile: File) => {
  const filename = imagefile.name.replace(' ', '-').slice(0, imagefile.name.lastIndexOf('.')) + '.webp';
  return filename;
}

const processAndAppendImage = async (file: File, formData: FormData, fieldName: any | string, quality: number = 0.2) => {
  const imageWithoutTransparentBg = await removeTransparentBackground(file as File);
  const compressedImage = await ImageCompressor(imageWithoutTransparentBg, quality);
  const filename = file.name.replace(' ', '-').slice(0, file.name.lastIndexOf('.')) + '.webp';

  formData.append(fieldName, compressedImage as File, filename);
};

const compressAndAppendImage = async (file: File, formData: FormData, fieldName: any | string, quality: number = 0.2) => {
  const compressedImage = await ImageCompressor(file, quality);
  const filename = file.name.replace(' ', '-').slice(0, file.name.lastIndexOf('.')) + '.webp';

  formData.append(fieldName, compressedImage as File, filename);
};

const imageProcessing = async (file: File) => {
  const imageWithoutTransparentBg = await removeTransparentBackground(file as File);
  const compressedImage = await ImageCompressor(imageWithoutTransparentBg);

  return compressedImage
};



export default ImageCompressor;
export { calculateTransparentDimensions }
export { resizeImage }
// export { RemoveTransparentBackground }
export { processAndAppendImage }
export { imageProcessing }
export { fileNaming }
export { compressAndAppendImage }


// ==================================


// const calculateTransparentDimensions = async (imageFile: File): Promise<TransparentDimensions> => {
//   const image = new Image();
//   const objectURL = URL.createObjectURL(imageFile);
//   image.src = objectURL;

//   return new Promise<TransparentDimensions>((resolve) => {
//     image.onload = () => {
//       // Calculate the dimensions of the transparent part
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//       if (!ctx) {
//         resolve({ width: 0, height: 0 });
//         return;
//       }

//       canvas.width = image.width;
//       canvas.height = image.height;
//       ctx.drawImage(image, 0, 0);

//       const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

//       let minX = canvas.width;
//       let minY = canvas.height;
//       let maxX = 0;
//       let maxY = 0;

//       for (let y = 0; y < canvas.height; y++) {
//         for (let x = 0; x < canvas.width; x++) {
//           const alpha = imageData[(y * canvas.width + x) * 4 + 3];
//           if (alpha === 0) {
//             // This pixel is transparent
//             if (x < minX) minX = x;
//             if (x > maxX) maxX = x;
//             if (y < minY) minY = y;
//             if (y > maxY) maxY = y;
//           }
//         }
//       }

//       const transparentWidth = maxX - minX + 1;
//       const transparentHeight = maxY - minY + 1;

//       resolve({ width: transparentWidth, height: transparentHeight });
//     };

//     image.onerror = () => {
//       resolve({ width: 0, height: 0 });
//     };
//   });
// };

// // Function to resize the image
// const resizeImage = async (
//   imageFile: File,
//   width: number,
//   height: number
// ): Promise<File | null> => {
//   const objectURL = URL.createObjectURL(imageFile);
//   const image = new Image();
//   image.src = objectURL;

//   return new Promise<File | null>((resolve) => {
//     image.onload = () => {
//       // Create a canvas for resizing
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//       if (!ctx) {
//         resolve(null);
//         return;
//       }

//       // Set canvas dimensions
//       canvas.width = width;
//       canvas.height = height;

//       // Draw the image on the canvas with the new dimensions
//       ctx.drawImage(image, 0, 0, width, height);

//       // Convert the canvas content to a Blob
//       canvas.toBlob(
//         (blob) => {
//           if (blob) {
//             const resizedImage = new File([blob], imageFile.name, {
//               type: 'image/webp', // Set the appropriate MIME type
//             });
//             resolve(resizedImage);
//           } else {
//             resolve(null);
//           }
//         },
//         'image/webp',
//         1 // Image quality (adjust as needed)
//       );
//     };

//     image.onerror = () => {
//       resolve(null);
//     };
//   });
// };

