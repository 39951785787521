import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useApiRequest } from '../../helpers/ApiRequest'
import { toast } from 'react-toastify';
import { formatDate } from '../../helpers/ApiRequest';
import { OrderProps } from '../../helpers/Props';
import OrderAdressesModal from './OrderAdressesModal';
import OrderDetailsModal from './OrderDetailsModal';
import { HiOutlineRefresh } from 'react-icons/hi'
import { KTSVG } from '../../../_metronic/helpers';
import { Loading } from '../../components/Loading';
import { DeleteNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { Pagination } from '@mui/material'
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import OrderItems from './OrderItems';
import { fetchBase64Images } from '../../helpers/Helpers';


const OrderList: React.FC = () => {
  const apiRequest = useApiRequest()
  const [orders, setOrders] = useState<OrderProps[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<any | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [fullName, setFullName] = useState('');
  const [reference, setReference] = useState('');
  const [status, setStatus] = useState<null | string>(null);
  const [orderDate, setOrdersDate] = useState('');
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  // Pagination state
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [inputFilter, setInputFilter] = useState(false)
  const [filterTotalItems, setFilterTotalItems] = useState(0)


  const initialOrderState = orders.map(order => ({
    ...order,
    isLoadingValidate: false,
    isLoadingAnnuler: false,
    isLoadingShipping: false,
    isPrinting: false,
  }));
  const [ordersWithLoading, setOrdersWithLoading] = useState(initialOrderState);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      });

      const updatedOrders = response.data['hydra:member'].map((order: any) => ({ ...order, isLoading: false }));

      setOrdersWithLoading(updatedOrders);
      setTotalItems(response.data['hydra:totalItems']);
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, totalItems])

  const DeleteOrder = async (order: number) => {
    try {
      const response = await apiRequest({
        route: `orders/${order}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Commande');
        fetchData();

      }


    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  const changeOrderStatus = async (orderId: number, status: string) => {
    try {
      // setIsLoading(true)
      const data = { orderStatus: status };

      const response = await apiRequest({
        route: `orders/${orderId}`,
        method: 'PUT',
        body: data
      });

      if (response.status === 200) {
        if (status === 'validate') {
          // setIsLoading(false)
          fetchData();
          toast.success("Commande bien validé");
        }
        if (status === 'shipping') {
          // setIsLoading(false)
          fetchData();
          toast.success("Commande en cours d'expédition");
        }
        if (status === 'annuler') {
          // setIsLoading(false)
          fetchData();
          toast.success("Commande bien annuler");
        }
      }

    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  function handlePageChange(event: any, value: any) {
    setCurrentPage(value);
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value);
  }

  const showLocation = (index: number) => {
    setSelectedOrder(ordersWithLoading[index]);
    setShowModal(true);
  };

  const showDetails = (index: number) => {
    setSelectedOrder(ordersWithLoading[index]);
    setShowDetailsModal(true);
  };

  const hideLocationModal = () => {
    setShowModal(false);
  };

  const hideDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const filterData = async () => {
    setIsFilter(true);
    setIsFilterLoading(true)

    try {

      const params: Record<string, any> = {
        'user.fullName': fullName || '',
        page: filterCurrentPage,
        itemsPerPage: 10
      };

      if (status !== '') {
        params.orderStatus = status;
      }
      if (orderDate !== '') {
        params.dateCreate = orderDate;
      }
      if (reference !== '') {
        params.reference = reference;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders.jsonld`, {
        params: params,
        headers: {
          Accept: 'application/ld+json'
        }
      });

      if (response.status === 200) {
        setIsFilterLoading(false)
        setOrdersWithLoading(response.data['hydra:member']);
        setFilterTotalItems(response.data['hydra:totalItems']);
        setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
      } else {
        FrCustomeErrorNorify()
        setIsFilterLoading(false);
      }
    } catch (error) {
      FrCustomeErrorNorify()
      setIsFilterLoading(false);
    }
  };

  useEffect(() => {
    if (isFilter) {
      filterData();
    }
  }, [filterCurrentPage])

  useEffect(() => {
    if (status !== null || fullName !== '' || reference !== '' || orderDate !== '') {
      setInputFilter(true);
    }
  }, [status, fullName, reference, orderDate])

  const resetFilter = () => {
    setStatus(null);
    setOrdersDate('')
    setFullName('')
    setReference('')
    setIsFilter(false)
    setInputFilter(false)
    setCurrentPage(1)
    setFilterCurrentPage(1)
    fetchData();
    // setIsLoading(false)
    // setOrdersWithLoading(null)
  }

  const generatePDFd = async (reference: string, index: number) => {
    setIsPrintLoading(true);

    const updatedOrders = [...ordersWithLoading];
    updatedOrders[index].isPrinting = true;
    setOrdersWithLoading(updatedOrders);
    const order = ordersWithLoading[index];

    let base64Images = []
    base64Images = await fetchBase64Images(order);

    const element = document.getElementById(`canvas_div_pdf${reference}`);

    if (element) {

      base64Images.forEach((im: any, index: number) => {
        let tdImg = document.getElementById(`this-td${index}`);

        if (tdImg) {
          tdImg.innerHTML = '';
          tdImg.style.maxWidth = '100px';

          const img = document.createElement('img');
          img.src = im;
          img.style.verticalAlign = 'middle';
          img.style.height = '40px';
          img.style.objectFit = 'scale-down';
          img.style.objectFit = 'hb-justify-content-start';
          tdImg.appendChild(img);
        }
      });

      setIsPrintLoading(false);

      html2pdf(element, {

        margin: 5,
        filename: `C-${reference}.pdf`,
        // image: { type: 'webp', quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: 'mm', orientation: 'portrait' },
      }).then(function (pdf: { save: () => void; }) {
        setIsPrintLoading(false);

        const updatedOrders = [...ordersWithLoading];
        updatedOrders[index].isPrinting = false;
        setOrdersWithLoading(updatedOrders);

        pdf.save();
      });
    }
  }


  const generatePDF = async (reference: string, index: number) => {
    setIsPrintLoading(true);

    const updatedOrders = [...ordersWithLoading];
    updatedOrders[index].isPrinting = true;
    setOrdersWithLoading(updatedOrders);
    const order = ordersWithLoading[index];

    let base64Images: any = []
    base64Images = await fetchBase64Images(order);

    const elements = document.querySelectorAll(`#canvas_div_pdf${reference} .item-row`);

    elements.forEach((element: any, rowIndex: number) => {
      const tdImg = element.querySelector('.hb-p-0.symbol-50');

      if (tdImg) {
        tdImg.innerHTML = '';
        tdImg.style.maxWidth = '100px';

        const img = document.createElement('img');
        img.src = base64Images[rowIndex];
        img.style.verticalAlign = 'middle';
        img.style.height = '40px';
        img.style.objectFit = 'scale-down';
        img.style.objectFit = 'hb-justify-content-start';
        tdImg.appendChild(img);
      }
    });

    setIsPrintLoading(false);

    html2pdf(document.getElementById(`canvas_div_pdf${reference}`), {
      margin: 5,
      filename: `C-${reference}.pdf`,
      html2canvas: {
        scale: 2,
        logging: true,
        dpi: 192,
        letterRendering: true,
      },
      jsPDF: { unit: 'mm', orientation: 'portrait' },
    }).then(function (pdf: { save: () => void; }) {
      setIsPrintLoading(false);

      const updatedOrders = [...ordersWithLoading];
      updatedOrders[index].isPrinting = false;
      setOrdersWithLoading(updatedOrders);

      pdf.save();
    });
  };



  return (
    <>

      {/* Start Filter Card */}
      <div className={`card mb-4 py-5`}>

        <div className='card-body py-3'>
          <div className="container">
            <div className="row justify-content-start align-items-center">

              {/* Start Client Name */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  N° de commande
                </label>
                <input
                  className="form-control "
                  type="search" aria-label="Search"
                  placeholder='N° de commande'
                  value={reference}
                  onChange={(e) => {
                    setReference(e.target.value)
                  }} />

              </div>
              {/* End Client Name */}

              {/* Start Client Name */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Nom de client
                </label>
                <input
                  className="form-control "
                  type="search" aria-label="Search"
                  placeholder='Nom de Client'
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value)
                  }} />

              </div>
              {/* End Client Name */}

              {/* Start Order Date */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Date de commande
                </label>
                <input
                  type='date'
                  name='dateDebut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date commande'
                  onChange={(e) => setOrdersDate(e.target.value)}
                  value={orderDate}
                />
              </div>
              {/* End Order Date */}

              {/* Start Status */}
              <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Statut de commande
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>

                  {/* Validate */}
                  <input
                    type='checkbox'
                    name='status'
                    id='validate'
                    className='form-check-input scale-1'
                    checked={status === 'validate'}
                    onChange={() => setStatus('validate')}
                    value={'validate'}
                  />
                  <label htmlFor='validate' className='ms-3 col-form-label fw-bold fs-5'>
                    Validé
                  </label>

                  {/* Pending */}
                  <input
                    type='checkbox'
                    name='status'
                    id='pending'
                    className='form-check-input ms-4 scale-1'
                    checked={status === 'pending'}
                    onChange={() => setStatus('pending')}
                    value={'pending'}
                  />
                  <label htmlFor='pending' className='ms-3 col-form-label fw-bold fs-5'>
                    En cours
                  </label>


                  {/* shipping */}
                  <input
                    type='checkbox'
                    name='status'
                    id='shipping'
                    className='form-check-input ms-4 scale-1'
                    checked={status === 'shipping'}
                    onChange={() => setStatus('shipping')}
                    value={'shipping'}
                  />
                  <label htmlFor='shipping' className='ms-3 col-form-label fw-bold fs-5'>
                    Expédier
                  </label>


                  {/* Annuler */}
                  <input
                    type='checkbox'
                    name='status'
                    id='annuler'
                    className='form-check-input ms-4 scale-1'
                    checked={status === 'annuler'}
                    onChange={() => setStatus('annuler')}
                    value={'annuler'}
                  />
                  <label htmlFor='annuler' className='ms-3 col-form-label fw-bold fs-5'>
                    Annuler
                  </label>

                </div>
              </div>
              {/* End Status */}

              {/* Start Apply Filter */}
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                  {
                    !isFilterLoading ?
                      <>
                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                        </label>
                        <button className='btn btn-light-primary me-3 fw-bold' onClick={filterData} disabled={!inputFilter} >
                          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                          Filtrer
                        </button>
                      </>

                      :
                      <div className="me-3 mt-4 d-flex justify-content-start">
                        <Loading />
                      </div>
                  }
                </div>
              </div>
              {/* End Apply Filter */}

              {/* Start Reset Filter */}
              <div className="reset-filter ">
                <div className="ico" onClick={resetFilter}>
                  <HiOutlineRefresh className='filter-icon sshadow' />
                </div>
              </div>
              {/* End Reset Filter */}

            </div>
          </div>
        </div>

      </div>
      {/* Ens Filter Card */}

      {/* Start Orders List Card */}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste Des Commandes</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-150px rounded-start'>Client</th>
                  <th className='ps-4 min-w-100px rounded-start'>N° Commande</th>
                  <th className='min-w-100px'>Date De Commande</th>
                  <th className='min-w-80px'>Nbr Articles</th>
                  <th className='ps-4 min-w-100px'>Prix Total</th>
                  <th className='min-w-125px'>Status</th>
                  <th className='min-w-100px'>Payment Status</th>
                  <th className='min-w-325px text-center rounded-end pe-5'>Action</th>
                </tr>
              </thead>
              <tbody>
                {ordersWithLoading.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                          {`${item.user.nom} ${item.user.prenom}`}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                          {item.reference}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                          {formatDate(item.dateCreate)}
                        </span>
                      </td>

                      <td className=''>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                          <span className='text-info'>{item.itemsQuantity}</span>
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                          <span className="text-info">{Number(item.totalPrice).toFixed(2)}</span> DH
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>

                          {item.orderStatus === 'pending' && (
                            <span className='badge badge-light-primary fs-6 fw-bold px-4'>
                              En cours
                            </span>
                          )}
                          {item.orderStatus === 'shipping' && (
                            <span className='badge badge-light-info fs-6 fw-bold px-4'>
                              Expédier
                            </span>
                          )}
                          {item.orderStatus === 'annuler' && (
                            <span className='badge badge-light-warning fs-6 fw-bold px-4'>
                              Annuler
                            </span>
                          )}

                          {item.orderStatus === 'validate' && (
                            <span className='badge badge-light-success fs-6 fw-bold px-4'>
                              Validé
                            </span>
                          )}

                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold  d-block mb-1 fs-6'>

                          {
                            item.paymentMethod === 'carte' ?
                              <>
                                {item.paymentStatus === 'pending' && (
                                  <span className='badge badge-light-primary fs-6 fw-bold px-5'>
                                    En attente
                                  </span>
                                )}
                                {item.paymentStatus === 'fail' && (
                                  <span className='badge badge-light-danger fs-6 fw-bold px-5'>
                                    Impayé
                                  </span>
                                )}

                                {item.paymentStatus === 'ok' && (
                                  <span className='badge badge-light-success fs-6 fw-bold px-5'>
                                    Payé
                                  </span>
                                )}
                              </>
                              :
                              <span className='badge badge-light-info fs-6 fw-bold px-5'>
                                A la livraison
                              </span>
                          }

                        </span>
                      </td>

                      <td className='text-end d-non'>

                        {!false &&
                          <>
                            {
                              !item.isLoadingShipping && !item.isLoadingValidate && !item.isLoadingAnnuler ?
                                <div className="dropdown d-inline me-3">
                                  <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="orderStatusDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Statut
                                  </button>
                                  <div className="dropdown-menu mt-2 px-3 py-2 w-100 text-center " aria-labelledby="orderStatusDropdown">
                                    {!item.isLoadingValidate ? (
                                      <button
                                        onClick={() => {
                                          changeOrderStatus(item.id, 'validate');
                                          const updatedOrders = [...ordersWithLoading];
                                          updatedOrders[index].isLoadingValidate = true;
                                          setOrdersWithLoading(updatedOrders);
                                        }}
                                        className="mt-2 dropdown-item btn btn-success btn-sm me-1 fw-bold"
                                        disabled={item.orderStatus === 'validate' || item.orderStatus === 'annuler'}
                                      >
                                        Valider
                                      </button>
                                    ) : (
                                      <button className="mt-2 dropdown-item btn btn-sm me-1 fw-bold" disabled>
                                        <Loading />
                                      </button>
                                    )}

                                    {!item.isLoadingShipping ? (
                                      <button
                                        onClick={() => {
                                          changeOrderStatus(item.id, 'shipping');
                                          const updatedOrders = [...ordersWithLoading];
                                          updatedOrders[index].isLoadingShipping = true;
                                          setOrdersWithLoading(updatedOrders);
                                        }}
                                        className="mt-2 dropdown-item btn btn-info btn-sm me-1 fw-bold"
                                        disabled={item.orderStatus === 'shipping' || item.orderStatus === 'annuler'}
                                      >
                                        Expédition
                                      </button>
                                    ) : (
                                      <button className="mt-2 dropdown-item btn btn-sm me-3 fw-bold" disabled>
                                        <Loading />
                                      </button>
                                    )}

                                    {!item.isLoadingAnnuler ? (
                                      <button
                                        onClick={() => {
                                          changeOrderStatus(item.id, 'annuler');
                                          const updatedOrders = [...ordersWithLoading];
                                          updatedOrders[index].isLoadingAnnuler = true;
                                          setOrdersWithLoading(updatedOrders);
                                        }}
                                        className="mt-2 dropdown-item btn btn-warning btn-sm me-3 fw-bold"
                                        disabled={item.orderStatus === 'annuler'}
                                      >
                                        Annuler
                                      </button>
                                    ) : (
                                      <button className="mt-2 dropdown-item btn btn-sm me-3 fw-bold" disabled>
                                        <Loading />
                                      </button>
                                    )}
                                  </div>
                                </div>

                                :
                                <button className='btn btn-sm me-1 fw-bold'>
                                  <Loading />
                                </button>

                            }
                          </>
                        }

                        {!true &&
                          <>
                            {
                              !item.isLoadingValidate ?
                                <button
                                  onClick={() => {
                                    changeOrderStatus(item.id, 'validate')
                                    const updatedOrders = [...ordersWithLoading];
                                    updatedOrders[index].isLoadingValidate = true;
                                    setOrdersWithLoading(updatedOrders);
                                  }}
                                  className='btn btn-success btn-sm me-1 fw-bold'
                                  disabled={item.orderStatus === 'validate'}
                                >
                                  Valider
                                </button>
                                :
                                <button className='btn btn-sm me-1 fw-bold'>
                                  <Loading />
                                </button>
                            }

                            {
                              !item.isLoadingShipping ?
                                <button
                                  onClick={() => {
                                    changeOrderStatus(item.id, 'shipping')
                                    const updatedOrders = [...ordersWithLoading];
                                    updatedOrders[index].isLoadingShipping = true;
                                    setOrdersWithLoading(updatedOrders);
                                  }}
                                  className='btn btn-info btn-sm me-1 fw-bold'
                                  disabled={item.orderStatus === 'shipping'}
                                >
                                  Expédition
                                </button>
                                :
                                <button className='btn btn-sm me-3 fw-bold'>
                                  <Loading />
                                </button>
                            }

                            {!item.isLoadingAnnuler ?
                              <button
                                onClick={() => {
                                  changeOrderStatus(item.id, 'annuler')
                                  const updatedOrders = [...ordersWithLoading];
                                  updatedOrders[index].isLoadingAnnuler = true;
                                  setOrdersWithLoading(updatedOrders);
                                }}
                                className='btn btn-warning btn-sm me-3 fw-bold'
                                disabled={item.orderStatus === 'annuler'}
                              >
                                Annuler
                              </button>
                              :
                              <button className='btn btn-sm me-3 fw-bold'>
                                <Loading />
                              </button>
                            }
                          </>
                        }

                        <button onClick={() => showDetails(index)} className='btn btn-icon btn-light-info btn-sm  me-3'>
                          <i className='bi bi-eye-fill fs-4'></i>
                        </button>

                        {/* <Link to={`/order/order-details/${item.id}`} className='d-none btn btn-icon btn-light-info btn-sm  me-3'>
                          <i className='bi bi-eye-fill fs-4'></i>
                        </Link> */}

                        {/* <button onClick={() => showLocation(index)} className='btn btn-icon btn-light-primary btn-sm  me-3'>
                          <i className='bi bi-geo-alt fs-4'></i>
                        </button> */}


                        {!item.isPrinting ? (
                          // {/* {!isPrintLoading ? ( */}
                          <button
                            className="btn btn-icon btn-light-dark btn-sm me-3"
                            onClick={() => generatePDF(item.reference, index)}
                          >
                            <i className="bi bi-printer-fill fs-4"></i>
                          </button>
                        ) : (
                          <button className='btn btn-sm me-1 fw-bold'>
                            <Loading />
                          </button>
                        )}

                        <div className="d-none">
                          <div id={`canvas_div_pdf${item.reference}`} className='d-non'>
                            <OrderItems order={item} totalPrice={Number(item.totalPrice)} />
                          </div>
                        </div>

                        <button onClick={() => DeleteOrder(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                          <i className='bi bi-trash-fill fs-4'></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {
          isFilter
            ?
            <Pagination
              count={filterTotalPages}
              page={filterCurrentPage}
              color='primary'
              onChange={handleFilterPageChange}
              className='d-flex justify-content-center'
            />
            :
            <Pagination
              count={totalPages}
              page={currentPage}
              color='primary'
              onChange={handlePageChange}
              className='d-flex justify-content-center'
            />

        }
        {/* <OrderAdressesModal order={selectedOrder} show={showModal} onHide={hideLocationModal} /> */}
        <OrderDetailsModal order={selectedOrder} show={showDetailsModal} onHide={hideDetailsModal} />
      </div>
      {/* End Orders List Card */}

    </>
  )
}

export default OrderList