import React, { useState, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCategoriesContext } from '../../context/CategoriesContext';
import { generateSlug, useApiRequest } from '../../helpers/ApiRequest';
import { AddingNotify, errorNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { compressAndAppendImage } from '../../helpers/imageProccessing/Imagecompressor';
import SubmitButton from '../../components/submitButton/submitButton';


const AddCategorie: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  // const [imagefile, setImage] = useState<File | any>(null);
  // const [file, setFile] = useState<string>("");
  const { categories } = useCategoriesContext();
  const apiRequest = useApiRequest()

  const Schema = Yup.object().shape({
    title: Yup.string().required('Le nom de categorie est obligatoire'),
    image: Yup.string().required("L'image de categorie est obligatoire"),
    description: Yup.string(),
    parent: Yup.string().oneOf(
      categories.map((category) => category.id.toString()),
      'Sélectionnez une catégorie valide'
    ),
  })


  const checkImageAndPostData = async (formData: FormData) => {
    if (formik.values.image) {
      try {
        setIsLoading(true)
        await compressAndAppendImage(formik.values.image as unknown as File, formData, 'imageFile', 0.8);

        const response = await apiRequest({
          route: `categories`,
          method: 'POST',
          body: formData
        });

        if (response.status === 201) {
          AddingNotify('Categorie')
          setIsLoading(false)
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)

      }

    } else {
      errorNotify('Sélectionner une image')
      setIsLoading(false)
    }

  }


  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      parent: "",
      image: "",

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      setIsLoading(true)

      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('parent', values.parent);

      const checkSlug = generateSlug(values.title);
      const parentData = categories.find((c) => c.id === Number(values.parent))


      if (values.parent !== '') {
        const checkSubCategorieResponse = await apiRequest({
          route: `check-subcategorie/${checkSlug}`,
          method: 'GET',
        });

        if (checkSubCategorieResponse.status === 200) {
          if (checkSubCategorieResponse.data.message === 'Found') {
            if (Number(checkSubCategorieResponse.data.parent_id) !== Number(parentData?.id)) {

              const checkSlugParentResponse = await apiRequest({
                route: `check-categorie-by-parent/${checkSlug}/${parentData?.id}`,
                method: 'GET',
              });

              if (checkSlugParentResponse.status === 200) {
                if (checkSlugParentResponse.data.message === 'Found') {
                  errorNotify('Sous Categorie déjâ exist')
                  setIsLoading(false)

                } else {

                  const slug = `${parentData?.slug}-${generateSlug(values.title)}`

                  const checkSlugParentResponse = await apiRequest({
                    route: `check-categorie-by-parent/${slug}/${parentData?.id}`,
                    method: 'GET',
                  });

                  if (checkSlugParentResponse.status === 200) {
                    if (checkSlugParentResponse.data.message === 'Found') {
                      errorNotify('Sous Categorie déjâ exist')
                      setIsLoading(false)

                    } else {
                      const slug = `${parentData?.slug}-${generateSlug(values.title)}`
                      formData.append('slug', slug);
                      checkImageAndPostData(formData)
                    }

                  } else {
                    FrCustomeErrorNorify()
                    setIsLoading(false)
                  }
                }

              } else {
                FrCustomeErrorNorify()
                setIsLoading(false)
              }

            } else {
              errorNotify('Sous Categorie déjâ exist')
              setIsLoading(false)
            }

          } else {

            const checkCategorieResponse = await apiRequest({
              route: `check-categorie/${checkSlug}`,
              method: 'GET',
            });

            if (checkCategorieResponse.status === 200) {
              if (checkCategorieResponse.data.message === 'Found') {
                const slug = `${parentData?.slug}-${generateSlug(values.title)}`
                formData.append('slug', slug);
                checkImageAndPostData(formData)

                console.log('----- concat');
              }
              else {
                const slug = generateSlug(values.title);
                formData.append('slug', slug);
                checkImageAndPostData(formData)
              }

            } else {
              FrCustomeErrorNorify()
              setIsLoading(false)
            }
          }

        } else {
          FrCustomeErrorNorify()
          setIsLoading(false)
        }

      } else {
        const checkCategorieResponse = await apiRequest({
          route: `check-categorie/${checkSlug}`,
          method: 'GET',
        });

        if (checkCategorieResponse.status === 200) {
          if (checkCategorieResponse.data.message === 'Found') {
            errorNotify('Categorie déjâ exist')
            setIsLoading(false)
          }
          else {
            const slug = generateSlug(values.title);
            formData.append('slug', slug);
            checkImageAndPostData(formData)
          }

        } else {
          FrCustomeErrorNorify()
          setIsLoading(false)
        }
      }


    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Une Categorie</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>

          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de categorie</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nom de categorie'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>

                    <input
                      type="file"
                      name="image"
                      accept=".png, .jpg, .jpeg, .webp"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {

                        if (e.target.files && e.target.files[0]) {
                          // setFile(URL.createObjectURL(e.target.files[0]));
                          // setImage(e.target.files[0]);
                          formik.setFieldValue('image', e.target.files[0]);
                        }
                      }} />
                    {formik.touched.image && formik.errors.image && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.image}</div>
                      </div>
                    )}


                  </div>

                </div>
              </div>
            </div>


            <div className='row mb-6'>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <textarea
                  rows={5}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Parent</label>

                <select
                  // name="categorie"
                  className="form-select"
                  value={formik.values.parent}
                  onChange={(event) => {
                    formik.setFieldValue('parent', event.target.value);
                  }}
                >
                  <option value="parent">Parent</option>
                  {categories.map((categorie: any, index) => {
                    return (
                      <option key={index} value={categorie.id}>
                        {categorie.title}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.parent && formik.errors.parent && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.parent}</div>
                  </div>
                )}
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
          </div>

        </form>
      </div>
    </div>
  )
}

export default AddCategorie