import React from 'react';
import { Loading } from './Loading';

interface ExportButtonProps {
  isExporting: boolean;
  onClick: () => void;
  buttonText: string;
  buttonClass: string;
  loadingClass: string;
}

const ExportButton: React.FC<ExportButtonProps> = ({ isExporting, onClick, buttonText, buttonClass, loadingClass }) => {
  return (
    <>
      {isExporting ? (
        <div className="ms-3">
          <span className={`btn btn-icon btn-light-${loadingClass} btn-sm`}>
            <Loading />
          </span>
        </div>
      ) : (
        <button className={`btn btn-light-${buttonClass} btn-sm ms-2 fw-bold`} onClick={onClick}>
          <i className='bi bi-cloud-download-fill fs-4'></i>
          {buttonText}
        </button>
      )}
    </>
  );
};

export default ExportButton