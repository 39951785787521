import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCategoriesContext } from '../../context/CategoriesContext'
import { QuillModules, generateSlug, getFirstWord, stripHtmlTags, useApiCall, useApiRequest } from '../../helpers/ApiRequest'
import { CategorieProps, MarqueProps } from '../../helpers/Props'
import { AddingNotify, FrCustomeErrorNorify } from '../../helpers/Toastify'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ProductsStyle.css'
import { processAndAppendImage } from '../../helpers/imageProccessing/Imagecompressor'
import SubmitButton from '../../components/submitButton/submitButton';
import Barcode from 'react-barcode';

const AddProduct: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const [files, setFiles] = useState<File[]>([]);
  const { categories } = useCategoriesContext();
  const apiRequest = useApiRequest()
  const { ApiCall, axiosProgress } = useApiCall()
  const [marques, setMarques] = useState<MarqueProps[]>([]);
  const [disponibiliteState, setDisponibiliteState] = useState(1);
  const [subCategories, setSubCategories] = useState<CategorieProps[]>([]);
  const [imageProcessingProgress, setImageProcessingProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [postPogress, setPostPogress] = useState<number>(0);
  const [isPost, setIsPost] = useState(false);
  const [infoComplementaire, setInfoComplementaire] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [description, setDescription] = useState('');

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: 'marques.json',
        method: 'GET',
      });

      setMarques(response.data)
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };
  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    setPostPogress(axiosProgress)
  }, [axiosProgress])


  const Schema = Yup.object().shape({
    title: Yup.string().required('Ce champ est obligatoire'),
    reference: Yup.string()
      .required('Ce champ est obligatoire')
      .matches(/^[a-zA-Z0-9]+$/, 'Ce champ doit contenir uniquement des lettres et des chiffres'),
    barcode: Yup.string()
      .required('Ce champ est obligatoire')
      .matches(/^[a-zA-Z0-9]+$/, 'Ce champ doit contenir uniquement des lettres et des chiffres'),
    categorie: Yup.string()
      .required('Ce champ est obligatoire')
      .oneOf(
        categories.map((category) => category.id.toString()),
        'Sélectionnez une catégorie valide'
      ),
    subcategorie: Yup.string()
      .required('Ce champ est obligatoire')
      .oneOf(
        subCategories.map((subCategory) => subCategory.id.toString()),
        'Sélectionnez une sous-catégorie valide'
      ),
    marque: Yup.string()
      .required('Ce champ est obligatoire')
      .oneOf(
        marques.map((marque) => marque.id.toString()),
        'Sélectionnez une marque valide'
      ),
    tarif_regulier: Yup.number()
      .required('Ce champ est obligatoire')
      .typeError('Ce champ doit être un nombre')
      .positive('Ce champ doit être un nombre positif'),
    poids_kg: Yup.string(),
    longueur_cm: Yup.string().matches(/^\d+$/, 'Ce champ doit contenir uniquement des chiffres'),
    largeur_cm: Yup.string().matches(/^\d+$/, 'Ce champ doit contenir uniquement des chiffres'),
    hauteur_cm: Yup.string().matches(/^\d+$/, 'Ce champ doit contenir uniquement des chiffres'),
    video_url: Yup.string(),
    image: Yup.string().required('Ce champ est obligatoire'),
  });


  const formik = useFormik({
    initialValues: {
      title: "",
      reference: "",
      barcode: "",
      categorie: "",
      subcategorie: "",
      marque: "",
      tarif_regulier: "",
      poids_kg: "",
      longueur_cm: "",
      largeur_cm: "",
      hauteur_cm: "",
      video_url: "",
      image: "",
    },

    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      const formData = new FormData();
      setIsLoading(true)

      const meta = stripHtmlTags(description)
      const metaDescription = meta.substring(0, 256)
      const disponibilite = disponibiliteState === 1 ? 'enstock' : 'rupture';

      // ------- Start check Product Slug
      const selectedMarque = marques.find((c) => c.id === Number(values.marque))
      const marqueTitle = getFirstWord(selectedMarque?.title as string)

      const selectedCategorie = categories.find((c) => c.id === Number(values.categorie))
      const categorieTitle = getFirstWord(selectedCategorie?.title as string)

      const slugWithTitle = `${generateSlug(values.title)}`
      const slugWithRef = `${generateSlug(values.title)}-${values.reference}`
      const slugWithMarque = `${generateSlug(values.title)}-${generateSlug(marqueTitle as string)}`
      const slugWithMarqueCategorie = `${generateSlug(values.title)}-${generateSlug(marqueTitle as string)}-${generateSlug(categorieTitle as string)}`

      const checkBySlugResponse = await apiRequest({
        route: `check-product-by-slug/${slugWithTitle}`,
        method: 'GET',
      });

      if (checkBySlugResponse.status === 200) {
        if (checkBySlugResponse.data.message === 'Found') {
          const checkBySlugMarqueResponse = await apiRequest({
            route: `check-product-by-slug-marque/${slugWithMarque}/${selectedMarque?.id}`,
            method: 'GET',
          });

          if (checkBySlugMarqueResponse.status === 200) {
            if (checkBySlugMarqueResponse.data.message === 'Found') {
              const checkBySlugMarqueCatResponse = await apiRequest({
                route: `check-product-by-slug-marque-categorie/${slugWithMarqueCategorie}/${selectedMarque?.id}/${selectedCategorie?.id}`,
                method: 'GET',
              });

              if (checkBySlugMarqueCatResponse.status === 200) {
                if (checkBySlugMarqueCatResponse.data.message === 'Found') {
                  formData.append('slug', slugWithRef);

                } else {
                  formData.append('slug', slugWithMarqueCategorie);
                }

              } else {
                FrCustomeErrorNorify()
                setIsLoading(false)
                return
              }

            } else {
              formData.append('slug', slugWithMarque);
            }

          } else {
            FrCustomeErrorNorify()
            setIsLoading(false)
            return
          }

        } else {
          formData.append('slug', slugWithTitle);
        }
      } else {
        FrCustomeErrorNorify()
        setIsLoading(false)
        return
      }
      // ------- End check Product Slug



      // ------- Start Images Proccesing

      setIsProcessing(true)
      setImageProcessingProgress(10);
      const imagefileFieldName = 'imageFile';
      await processAndAppendImage(values.image as unknown as File, formData, imagefileFieldName);
      setImageProcessingProgress(20);

      if (files.length > 0) {
        const totalTasks = files.length;
        let completedTasks = 0;

        const imagePromises = files.map(async (file) => {
          const fieldName = "productImages[]";
          await processAndAppendImage(file, formData, fieldName);
          completedTasks++;

          const newProgressWidth = (completedTasks / totalTasks) * 90 + 10;
          setImageProcessingProgress(newProgressWidth);
        });

        await Promise.all(imagePromises);
      } else {
        setImageProcessingProgress(100);
      }
      // ------- End Images Proccesing

      setIsProcessing(false)
      setImageProcessingProgress(0);
      setIsPost(true)

      formData.append('title', values.title);
      formData.append('reference', values.reference);
      formData.append('barcode', values.barcode);
      formData.append('meta_description', metaDescription);
      formData.append('description', description);
      formData.append('infoComplementaire', infoComplementaire);
      formData.append('disponibilite', disponibilite);
      formData.append('subcategorie', values.subcategorie);
      formData.append('tarif_regulier', values.tarif_regulier);
      formData.append('categorie', values.categorie);
      formData.append('marque', values.marque);
      formData.append('video_url', values.video_url);
      formData.append('poids_kg', values.poids_kg);
      formData.append('longueur_cm', values.longueur_cm);
      formData.append('largeur_cm', values.largeur_cm);
      formData.append('hauteur_cm', values.hauteur_cm);

      try {
        const response = await ApiCall({
          route: `products`,
          method: 'POST',
          body: formData
        });

        if (response.status === 201) {
          setFiles([])
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          AddingNotify('Produit')
          setIsLoading(false)
          setIsPost(false)
          setPostPogress(0)
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)
      }


    },
  })

  const handleDescriptionChange = (newDescription: string) => {
    const formattedDescription = newDescription.replace('. ', '.<br />').replace(' : ', ': <br />');
    setDescription(formattedDescription);
  };

  const handleInfoChange = (newInfo: string) => {
    const formattedInfo = newInfo.replace('. ', '.<br />').replace(' : ', ': <br />');
    setInfoComplementaire(formattedInfo);
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Un Produit</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de produit</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Nom de produit'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-3 col-md-3 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Reference</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Reference'
                  {...formik.getFieldProps('reference')}
                />
                {formik.touched.reference && formik.errors.reference && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.reference}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-3 col-md-3 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Code Barre</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Code Barre'
                  {...formik.getFieldProps('barcode')}
                />
                {formik.touched.barcode && formik.errors.barcode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.barcode}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-10 col-md-10 col-form-label required fw-bold fs-6'>Prix de produit</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Prix'
                  {...formik.getFieldProps('tarif_regulier')}
                />
                {formik.touched.tarif_regulier && formik.errors.tarif_regulier && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.tarif_regulier}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-3 col-md-3 fv-row'>
                <label className='col-lg-10 col-md-10 col-form-label required fw-bold fs-6'>
                  Disponibilite
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>
                  <input
                    type='radio'
                    name='disponibiliteState'
                    id='enstock'
                    className='form-check-input form-check-disponibilite'
                    checked={disponibiliteState === 1}
                    onChange={() => setDisponibiliteState(1)}
                    onBlur={formik.handleBlur}
                    value={1}
                  />
                  <label htmlFor='enstock' className='fs-5 ms-2 me-4 col-form-label fw-bold'>
                    En Stock
                  </label>

                  <input
                    type='radio'
                    name='disponibiliteState'
                    id='rupture'
                    className='form-check-input form-check-deactive form-check-disponibilite'
                    checked={disponibiliteState === 0}
                    onChange={() => setDisponibiliteState(0)}
                    onBlur={formik.handleBlur}
                    value={0}
                  />
                  <label htmlFor='rupture' className='fs-5 ms-2 col-form-label fw-bold'>
                    Rupture
                  </label>

                </div>
              </div>

              <div className="col-lg-3 col-md-3 fv-row">
                <Barcode
                  value={formik.values.barcode}
                  format='CODE128'
                  displayValue={false}
                  fontOptions=""
                  width={2}
                  height={65}
                  background="#ffffff"
                  lineColor="#000000"
                  marginTop={1}
                  marginBottom={1}
                  marginLeft={5}
                  marginRight={1}
                />
              </div>

            </div>

            <div className="row mb-6">
              <div className="col-lg-12 col-md-12 fv-row">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <ReactQuill
                  className="react-quill-editor"
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                  // onChange={handleDescriptionChange}
                  modules={QuillModules}
                />
              </div>
            </div>

            <div className="row mb-6">
              <div className="col-lg-12 col-md-12 fv-row">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Informations Complémentaire</label>

                <ReactQuill
                  className="react-quill-editor"
                  theme="snow"
                  value={infoComplementaire}
                  onChange={setInfoComplementaire}
                  // onChange={handleInfoChange}
                  modules={QuillModules}
                />
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Marque</label>
                <select
                  className="form-select"
                  {...formik.getFieldProps('marque')}
                >
                  <option value="">Marque</option>
                  {marques.map((marque: any, index) => {
                    return (
                      <option key={index} value={marque.id}>
                        {marque.title}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.marque && formik.errors.marque && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.marque}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Categorie</label>

                <select
                  name="categorie"
                  className="form-select"
                  onChange={(e) => {
                    formik.handleChange(e);
                    const selectedCategory = e.target.value;
                    const filteredSubCategories = categories.find((category) => category.id === Number(selectedCategory))?.subCategory || [];
                    setSubCategories(filteredSubCategories);
                  }}
                  value={formik.values.categorie}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Categorie
                  </option>
                  {categories.map((categorie) => (
                    <option key={categorie.id} value={categorie.id}>
                      {categorie.title}
                    </option>
                  ))}
                </select>
                {formik.touched.categorie && formik.errors.categorie && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.categorie}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Sous Categorie</label>

                <select
                  name="subcategorie"
                  className="form-select"
                  onChange={formik.handleChange}
                  value={formik.values.subcategorie}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Sous Categorie</option>
                  {subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.title}
                    </option>
                  ))}
                </select>
                {formik.touched.subcategorie && formik.errors.subcategorie && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.subcategorie}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6 d-non'>
              <div className='col-lg-3 col-md-6 fv-row'>
                <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Poids Kg</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Poids Kg'
                  {...formik.getFieldProps('poids_kg')}
                />
                {formik.touched.poids_kg && formik.errors.poids_kg && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.poids_kg}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-3 col-md-6 fv-row'>
                <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Longueur cm</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Longueur cm'
                  {...formik.getFieldProps('longueur_cm')}
                />
                {formik.touched.longueur_cm && formik.errors.longueur_cm && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.longueur_cm}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-3 col-md-6 fv-row'>
                <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Largeur cm</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Largeur cm'
                  {...formik.getFieldProps('largeur_cm')}
                />
                {formik.touched.largeur_cm && formik.errors.largeur_cm && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.largeur_cm}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-3 col-md-6 fv-row'>
                <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Hauteur cm</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Hauteur cm'
                  {...formik.getFieldProps('hauteur_cm')}
                />
                {formik.touched.hauteur_cm && formik.errors.hauteur_cm && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.hauteur_cm}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-10 col-md-10 col-form-label fw-bold fs-6'>Miniature de produit</label>

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .webp"
                  required
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {

                    if (e.target.files && e.target.files[0]) {
                      formik.setFieldValue('image', e.target.files[0]);
                    }
                  }}
                />
                {formik.touched.image && formik.errors.image && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.image}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-10 col-md-10 col-form-label fw-bold fs-6'>Autre Images</label>

                <input
                  ref={fileInputRef}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const { files } = e.target;
                    if (files) {
                      setFiles(Array.from(files));
                    }
                  }}
                  type="file"
                  name="productImages"
                  accept=".png, .jpg, .jpeg, webp"
                  className='form-control form-control-lg form-control-solid'
                  multiple
                />
              </div>

              <div className='col-lg-4 col-md-4 fv-row d-non'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Lien de Video</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Lien du Video'
                  {...formik.getFieldProps('video_url')}
                />
                {formik.touched.video_url && formik.errors.video_url && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.video_url}</div>
                  </div>
                )}
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-between py-6 px-9'>
            <div className="row w-100">
              <div className="col-8 text-start">
                {
                  isProcessing ?
                    <>
                      <h5 className='text-info '>Images processing... {Math.floor(imageProcessingProgress)} %</h5>
                      <div className="processing">
                        <div className="process i-process" style={{ width: `${imageProcessingProgress}%` }}></div>
                      </div>
                    </>
                    :
                    ''
                }

                {
                  isPost ?
                    <>
                      <h5 className='text-success '>Request processing... {postPogress} %</h5>
                      <div className="processing">
                        <div className="process r-process" style={{ width: `${postPogress}%` }}></div>
                      </div>
                    </>
                    :
                    ''
                }

              </div>
              <div className="col-4 text-end">
                <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddProduct