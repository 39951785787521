import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useApiRequest } from '../../helpers/ApiRequest';
import { AddingNotify, errorNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import SubmitButton from '../../components/submitButton/submitButton';

const Schema = Yup.object().shape({
  cp_name: Yup.string().required('Ce champ est obligatoire'),
  cp_discount: Yup.number()
    .min(1, 'La remise doit être d\'au moins 1%')
    .max(100, 'La remise ne peut pas dépasser 100%')
    .required('Ce champ est obligatoire')
    .typeError('Veuillez entrer un nombre valide'),
  cp_date_debut: Yup.date().required('Ce champ est obligatoire'),
  cp_date_fin: Yup.date().required('Ce champ est obligatoire'),
});

const AddCodePromo = () => {
  const [status, setStatus] = useState(1);
  const [codePromo, setCodePromo] = useState('');
  const apiRequest = useApiRequest()
  const [isloading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      cp_name: '',
      cp_discount: '',
      cp_date_debut: '',
      cp_date_fin: '',
    },
    validationSchema: Schema,
    onSubmit: async (values) => {

      setIsLoading(true)
      const data = { ...values, cp_status: status };

      const formData = new FormData();
      formData.append('cp_name', data.cp_name);
      formData.append('cp_discount', data.cp_discount);
      formData.append('cp_status', String(data.cp_status));
      formData.append('cp_date_debut', data.cp_date_debut);
      formData.append('cp_date_fin', data.cp_date_fin);

      try {

        const response = await apiRequest({
          route: `code_promos`,
          method: 'POST',
          body: formData
        });

        if (response.status === 201) {
          AddingNotify('Code Promo')
          setIsLoading(false)
        }

      } catch (error) {
        codePromo === 'Found' ? errorNotify('Code promo déjà exist') : FrCustomeErrorNorify()
        setIsLoading(false)
      }

    },
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  const handleCodePromoChange = async (code: string) => {
    try {
      const response = await apiRequest({
        route: `codepromo/cp_name/${code}`,
        method: 'GET',
      });

      setCodePromo(response.data);
    } catch (error) {
    }
  };


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Un Code Promo</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Code Promo
                </label>
                <input
                  type='text'
                  name='cp_name'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Code Promo'
                  onBlur={(event) => {
                    handleBlur(event);
                    handleCodePromoChange(values.cp_name);
                  }}
                  onChange={handleChange}
                  value={values.cp_name}
                />
                {touched.cp_name && errors.cp_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.cp_name}</div>
                  </div>
                )}
                <div className="invalid-feedback d-block">{codePromo === 'Found' ? 'code promo déjà exist' : ''}</div>
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Code Discount
                </label>
                <input
                  type='number'
                  name='cp_discount'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Code Discount'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cp_discount}
                />
                {touched.cp_discount && errors.cp_discount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.cp_discount}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Code Status
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>
                  <input
                    type='radio'
                    name='cp_status'
                    id='active'
                    className='form-check-input form-check-disponibilite'
                    checked={status === 1}
                    onChange={() => setStatus(1)}
                    onBlur={handleBlur}
                    value={1}
                  />
                  <label htmlFor='active' className='ms-2 col-form-label fs-5 fw-bold'>
                    Active
                  </label>

                  <input
                    type='radio'
                    name='cp_status'
                    id='desactive'
                    className='form-check-input form-check-deactive ms-4 form-check-disponibilite'
                    checked={status === 0}
                    onChange={() => setStatus(0)}
                    onBlur={handleBlur}
                    value={0}
                  />
                  <label htmlFor='desactive' className='ms-2 col-form-label fs-5 fw-bold'>
                    Desactive
                  </label>

                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Date de debut de promo
                </label>
                <input
                  type='date'
                  name='cp_date_debut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de debut de promo'
                  min={new Date().toISOString().split('T')[0]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cp_date_debut}
                />
                {touched.cp_date_debut && errors.cp_date_debut && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.cp_date_debut}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Date de fin de promo
                </label>
                <input
                  type='date'
                  name='cp_date_fin'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de fin de promo'
                  min={values.cp_date_debut}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cp_date_fin}
                  disabled={!values.cp_date_debut}

                />
                {touched.cp_date_fin && errors.cp_date_fin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.cp_date_fin}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCodePromo;