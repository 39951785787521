import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import AddCategorie from '../pages/categorie-management/AddCategorie'
import AddMarque from '../pages/marque-management/AddMarque'
import UpdateMarque from '../pages/marque-management/UpdateMarque'
import MarquesList from '../pages/marque-management/MarquesList'
import CategorieList from '../pages/categorie-management/CategorieList'
import AddProduct from '../pages/product-management/AddProduct'
import ProductList from '../pages/product-management/ProductList'
import AddSlider from '../pages/slider-management/AddSlider'
import SlidersList from '../pages/slider-management/SlidersList'
import AddUser from '../pages/user-management/AddUser'
import UsersList from '../pages/user-management/UsersList'
import AddCodePromo from '../pages/code-promo-management/AddCodePromo'
import CodePromoList from '../pages/code-promo-management/CodePromoList'
import UpdateCodePromo from '../pages/code-promo-management/UpdateCodePromo'
import ReviewsList from '../pages/reviews-management/ReviewsList'
import OrderList from '../pages/order-management/OrderList'
import OrderItems from '../pages/order-management/OrderItems'
import UpdateCategorie from '../pages/categorie-management/UpdateCategorie'
import UpdateSlider from '../pages/slider-management/UpdateSlider'
import AddPromotion from '../pages/promotion-management/AddPromotion'
import PromotionList from '../pages/promotion-management/PromotionList'
import UpdatePromotion from '../pages/promotion-management/UpdatePromotion'
import UpdateProduct from '../pages/product-management/UpdateProduct'
import AddPack from '../pages/pack-management/AddPack'
import PackList from '../pages/pack-management/PackList'
import UpdatePack from '../pages/pack-management/UpdatePack'
import EmailsList from '../pages/email-management/EmailsList'
import EmailResponse from '../pages/email-management/EmailResponse'
import OrderFacture from '../pages/order-management/OrderFacture'
import CategorieOrder from '../pages/CategorieOrder/CategorieOrder'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Marque */}
        <Route
          path="/marque/add-marque"
          element={<AddMarque />}
        />

        <Route
          path="/marque/marque-list"
          element={<MarquesList />}
        />

        <Route
          path="/marque/update-marque/:id"
          element={<UpdateMarque />}
        />

        {/* Categorie */}
        <Route
          path="/categorie/add-categorie"
          element={<AddCategorie />}
        />

        <Route
          path="/categorie/categorie-list"
          element={<CategorieList />}
        />

        <Route
          path="/categorie/update-categorie/:id"
          element={<UpdateCategorie />}
        />

        <Route
          path="/categorie/update-order"
          element={<CategorieOrder />}
        />

        {/* codepromo */}
        <Route
          path="/codepromo/add-codepromo"
          element={<AddCodePromo />}
        />

        <Route
          path="/codepromo/codepromo-list"
          element={<CodePromoList />}
        />

        <Route
          path="/codepromo/update-codepromo/:id"
          element={<UpdateCodePromo />}
        />

        {/* Promotion */}
        <Route
          path="/promotion/add-promotion"
          element={<AddPromotion />}
        />

        <Route
          path="/promotion/promotion-list"
          element={< PromotionList />}
        />

        <Route
          path="/promotion/update-promotion/:id"
          element={<UpdatePromotion />}
        />

        {/* Pack */}
        <Route
          path="/pack/add-pack"
          element={<AddPack />}
        />

        <Route
          path="/pack/pack-list"
          element={< PackList />}
        />

        <Route
          path="/pack/update-Pack/:id"
          element={<UpdatePack />}
        />

        {/* product */}
        <Route
          path="/product/add-product"
          element={<AddProduct />}
        />

        <Route
          path="/product/product-list"
          element={<ProductList />}
        />

        <Route
          path="/product/update-product/:id"
          element={<UpdateProduct />}
        />

        {/* slider */}
        <Route
          path="/slider/add-slider"
          element={<AddSlider />}
        />

        <Route
          path="/slider/slider-list"
          element={<SlidersList />}
        />

        <Route
          path="/slider/update-slider/:id"
          element={<UpdateSlider />}
        />

        {/* User */}
        <Route
          path="/user/add-user"
          element={<AddUser />}
        />

        <Route
          path="/user/user-list"
          element={<UsersList />}
        />

        {/* Reviews */}
        {/* <Route
          path="/reviews/add-user"
          element={<AddUser />}
        /> */}

        <Route
          path="/reviews/reviews-list"
          element={<ReviewsList />}
        />

        {/* Order */}

        <Route
          path="/order/order-list"
          element={<OrderList />}
        />

        <Route
          path="/order/order-details/:id"
          element={<OrderFacture />}
        />


        {/* Email */}

        <Route
          path="/email/email-list"
          element={<EmailsList />}
        />

        <Route
          path="/email/email-response/:id"
          element={<EmailResponse />}
        />













        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error' />} />


      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }