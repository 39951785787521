import * as Yup from 'yup'
import { CategorieProps, MarqueProps } from '../Props'

export const VariantSchema = Yup.object().shape({
    label: Yup.string().required('Ce champ est obligatoire'),
    reference: Yup.string()
        .required('Ce champ est obligatoire')
        .matches(/^[a-zA-Z0-9]+$/, 'Ce champ doit contenir uniquement des lettres et des chiffres'),
    barcode: Yup.string()
        .required('Ce champ est obligatoire')
        .matches(/^[a-zA-Z0-9]+$/, 'Ce champ doit contenir uniquement des lettres et des chiffres'),
    sellingPrice: Yup.number()
        .required('Ce champ est obligatoire')
        .typeError('Ce champ doit être un nombre')
        .positive('Ce champ doit être un nombre positif'),
    packing: Yup.number()
        .typeError('Ce champ doit être un nombre')
        .positive('Ce champ doit être un nombre positif'),
    purchasePriceHT: Yup.number()
        // .required('Ce champ est obligatoire')
        .typeError('Ce champ doit être un nombre')
        .positive('Ce champ doit être un nombre positif'),
    purchasePriceTTC: Yup.number()
        // .required('Ce champ est obligatoire')
        .typeError('Ce champ doit être un nombre')
        .positive('Ce champ doit être un nombre positif'),
    image: Yup.string().required('Ce champ est obligatoire'),
    icon: Yup.string().required('Ce champ est obligatoire'),
})

export const ProductSchema = (categories: CategorieProps[], subCategories: CategorieProps[], marques: MarqueProps[]) => Yup.object().shape({
    title: Yup.string().required('Ce champ est obligatoire'),
    reference: Yup.string()
        .matches(/^[a-zA-Z0-9]+$/, 'Ce champ doit contenir uniquement des lettres et des chiffres'),
    barcode: Yup.string()
        .matches(/^[a-zA-Z0-9]+$/, 'Ce champ doit contenir uniquement des lettres et des chiffres'),
    packing: Yup.number()
        .typeError('Ce champ doit être un nombre')
        .positive('Ce champ doit être un nombre positif'),
    categorie: Yup.string()
        .required('Ce champ est obligatoire')
        .oneOf(
            categories.map((category) => category.id.toString()),
            'Sélectionnez une catégorie valide'
        ),
    subcategorie: Yup.string()
        // .required('Ce champ est obligatoire')
        .oneOf(
            subCategories.map((subCategory) => subCategory.id.toString()),
            'Sélectionnez une sous-catégorie valide'
        ),
    marque: Yup.string()
        .required('Ce champ est obligatoire')
        .oneOf(
            marques.map((marque) => marque.id.toString()),
            'Sélectionnez une marque valide'
        ),
    sellingPrice: Yup.number()
        .required('Ce champ est obligatoire')
        .typeError('Ce champ doit être un nombre')
        .positive('Ce champ doit être un nombre positif'),
    purchasePriceHT: Yup.number()
        // .required('Ce champ est obligatoire')
        .typeError('Ce champ doit être un nombre')
        .positive('Ce champ doit être un nombre positif'),
    purchasePriceTTC: Yup.number()
        // .required('Ce champ est obligatoire')
        .typeError('Ce champ doit être un nombre')
        .positive('Ce champ doit être un nombre positif'),
    image: Yup.string().required('Ce champ est obligatoire'),
})

export const BlogSchema = Yup.object().shape({
    title: Yup.string().required('Ce champ est obligatoire'),
    postedBy: Yup.string().required('Ce champ est obligatoire'),
    image: Yup.string().required('Ce champ est obligatoire'),
    videoUrl: Yup.string(),
})

export const promotionSchema = Yup.object().shape({
    price: Yup.number()
        .typeError('Ce champ doit être un nombre')
        .positive('Ce champ doit être un nombre positif'),
    discount: Yup.number()
        .typeError('Veuillez entrer un nombre valide')
        .min(1, 'La remise doit être d\'au moins 1%')
        .max(100, 'La remise ne peut pas dépasser 100%'),
    dateDebut: Yup.date().required('Ce champ est obligatoire'),
    dateFin: Yup.date().required('Ce champ est obligatoire'),
});

export const marqueSchema = (isUpdate: boolean = false) => {
    let schema = Yup.object().shape({
        title: Yup.string().required('Le lastName de marque est obligatoire'),
        description: Yup.string(),
    });

    if (!isUpdate) {
        schema = schema.shape({
            logo: Yup.string().required("L'logo de marque est obligatoire"),
            image: Yup.string().required("L'image de marque est obligatoire"),
        });
    }

    return schema;
};



export const categorySchema = (categories: CategorieProps[], isUpdate: boolean = false) => {
    let schema = Yup.object().shape({
        title: Yup.string().required('Le lastName de la catégorie est obligatoire'),
        description: Yup.string(),
        parent: Yup.string().oneOf(
            categories.map((category) => category.id.toString()),
            'Sélectionnez une catégorie valide'
        ),
    });

    if (!isUpdate) {
        schema = schema.shape({
            image: Yup.string().required("L'image de la catégorie est obligatoire"),
            mobileImage: Yup.string().required("L'image mobile de la catégorie est obligatoire"),
        });
    }

    return schema;
};

export const subCategorySchema = Yup.object().shape({
    title: Yup.string().required('Le lastName de sous categorie est obligatoire'),
    image: Yup.string().required("L'image de sous categorie est obligatoire"),
    description: Yup.string(),
})



export const sliderSchema = (isUpdate: boolean = false) => {
    let schema = Yup.object().shape({
        title: Yup.string().required('Le titre est obligatoire'),
    });

    if (!isUpdate) {
        schema = schema.shape({
            image: Yup.string().required("L'image est obligatoire"),
        });
    }

    return schema;
};


export const adminInfoSchema = Yup.object().shape({
    lastName: Yup.string()
        .required("Veuillez saisir votre lastName"),
    firstName: Yup.string()
        .required("Veuillez saisir votre prénom"),
    telephone: Yup.string()
        .required("Veuillez saisir votre numéro de téléphone")
        .matches(/^\d+$/, "Veuillez entrer un numéro de téléphone valide."),
    email: Yup.string()
        .email("Format d'e-mail non valide. Veuillez entrer une adresse e-mail valide.")
        .required("Veuillez saisir votre email"),
});

export const adminPasswordSchema = Yup.object({
    password: Yup.string().required("Veuillez saisir votre mot de passe actuel"),
    newpassword: Yup.string().required("Veuillez saisir votre mot de passe"),
    confirmPassword: Yup.string().required('Veuillez confirmer votre mot de passe')
        .oneOf([Yup.ref('newpassword'), ''], 'Veuillez confirmer votre mot de passe'),
});

export const emailSchema = Yup.object().shape({
    email: Yup.string().required("L'email est obligatoire"),
    subject: Yup.string().required("Sujet est obligatoire"),
    message: Yup.string().required("Message est obligatoire"),
})

export const codePromoSchema = Yup.object().shape({
    name: Yup.string().required('Ce champ est obligatoire'),
    discount: Yup.number()
        .min(1, 'La remise doit être d\'au moins 1%')
        .max(100, 'La remise ne peut pas dépasser 100%')
        .required('Ce champ est obligatoire')
        .typeError('Veuillez entrer un nombre valide'),
    dateDebut: Yup.date().required('Ce champ est obligatoire'),
    dateFin: Yup.date().required('Ce champ est obligatoire'),
})


export const packSchema = (isUpdate: boolean = false) => {
    let schema = Yup.object().shape({
        title: Yup.string().required('Ce champ est obligatoire'),
        sellingPrice: Yup.number()
            .required('Ce champ est obligatoire')
            .typeError('Ce champ doit être un nombre')
            .positive('Ce champ doit être un nombre positif'),
        dateDebut: Yup.date().required('Ce champ est obligatoire'),
        dateFin: Yup.date().required('Ce champ est obligatoire'),
    });

    if (!isUpdate) {
        schema = schema.shape({
            image: Yup.string().required("L'image est obligatoire"),
        });
    }

    return schema;
};

export const specialOfferSchema = (isUpdate: boolean = false) => {
    let schema = Yup.object().shape({
        title: Yup.string().required('Le titre est obligatoire'),
    });

    if (!isUpdate) {
        schema = schema.shape({
            image: Yup.string().required("L'image est obligatoire"),
        });
    }

    return schema;
};


export const adminSchema = Yup.object().shape({
    email: Yup.string().email("Format d'e-mail non valide. Veuillez entrer une adresse e-mail valide.")
        .required("Veuillez saisir votre email"),
    lastName: Yup.string().required("Veuillez saisir votre lastName"),
    telephone: Yup.string()
        .required("Veuillez saisir votre numéro de téléphone")
        .matches(/^\d+$/, "Veuillez entrer un numéro de téléphone valide."),
    firstName: Yup.string().required("Veuillez saisir votre prénom"),
    password: Yup.string().required("Veuillez saisir votre mot de passe"),
})