export const initProduct = {
    title: "AZERTY",
    reference: "AZERTY",
    packing: "6",
    barcode: "09080706",
    categorie: "",
    subcategorie: "",
    marque: "",
    sellingPrice: "999",
    purchasePriceHT: "999",
    purchasePriceTTC: "999",
    image: "",
}

export const initVariant = {
    label: "",
    reference: "",
    barcode: "",
    packing: "",
    sellingPrice: "",
    purchasePriceHT: "",
    purchasePriceTTC: "",
    image: "",
    icon: "",
}

export const initBlog = {
    title: "",
    image: "",
    videoUrl: "",
    postedBy: "",
}


export const initCategory = {
    title: "",
    description: "",
    parent: "",
    image: "",
}

export const initSubcategory = {
    title: "",
    image: "",
    description: ""
}

export const initSlider = {
    title: "",
    image: "",
}

export const initUpdateAdminInfo = {
    lastName: "",
    firstName: "",
    telephone: "",
    email: "",
}

export const initUpdateAdminPassword = {
    password: "",
    newpassword: "",
    confirmPassword: "",
}

export const initEmail = {
    email: "",
    subject: "",
    message: "",
}

export const initCodePromo = {
    name: '',
    discount: '',
    dateDebut: '',
    dateFin: '',
}

export const initMarque = {
    title: "",
    logo: "",
    image: "",
    description: "",
}

export const initPack = {
    title: '',
    image: '',
    description: '',
    sellingPrice: '',
    dateDebut: '',
    dateFin: '',
}

export const initPromotion = {
    price: '',
    discount: '',
    dateDebut: '',
    dateFin: '',
}

export const initSpecialOffer = {
    title: "",
    image: "",
}

export const initAdmin = {
    lastName: "",
    firstName: "",
    telephone: "",
    email: "",
    password: "",
    role: "ROLE_ADMIN",

}