import React, { createContext, useContext, useState, useEffect } from 'react';
import { ReOrderCategorieProps } from '../helpers/Props';

interface ReOrderCategorieContextProps {
    card: ReOrderCategorieProps[];
    setCard: React.Dispatch<React.SetStateAction<ReOrderCategorieProps[]>>;
}

const ReOrderCategorieContext = createContext<ReOrderCategorieContextProps>({
    card: [],
    setCard: () => {}
});

export const useReOrderCategorieContext = () => useContext(ReOrderCategorieContext);

export const ReOrderCategorieProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [card, setCard] = useState<ReOrderCategorieProps[]>([]);
    // console.log(card);
    

    return (
        <ReOrderCategorieContext.Provider value={{ card, setCard }}>
            {children}
        </ReOrderCategorieContext.Provider>
    );
};
