import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom';
import { useApiRequest } from '../../helpers/ApiRequest';
import { FrCustomeErrorNorify, notify } from '../../helpers/Toastify';
import SubmitButton from '../../components/submitButton/submitButton';

const Schema = Yup.object().shape({
  email: Yup.string().required("L'email est obligatoire"),
  subject: Yup.string().required("Sujet est obligatoire"),
  message: Yup.string().required("Message est obligatoire"),
})

const EmailResponse: React.FC = () => {

  const [isloading, setIsLoading] = useState(false)
  const { id } = useParams();
  const apiRequest = useApiRequest()

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `user_emails/${id}.json`,
        method: 'GET',
      });

      setValues({
        email: response.data.email,
        subject: response.data.subject,
        message: response.data.title,
      })

    } catch (error) {
      FrCustomeErrorNorify()
    }
  };
  useEffect(() => {
    fetchData();
  }, [])


  const formik = useFormik({
    initialValues: {
      email: "",
      subject: "",
      message: "",

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      try {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('email', values.email);
        formData.append('subject', values.subject);
        formData.append('message', values.message);
        const data = { getResponse: 1 }

        const response = await apiRequest({
          route: `email-response`,
          method: 'POST',
          body: formData,
        });


        if (response.status === 200) {
          notify('Message envoyé');
          setIsLoading(false)
          const response = await apiRequest({
            route: `user_emails/${id}`,
            method: 'PUT',
            body: data,
          });
        }


      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)
      }

    },
  })

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Repondre</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Email</label>

                <input
                  type='text'
                  name='email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  disabled
                />
                {touched.email && errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Sujet</label>

                <input
                  type='text'
                  name='subject'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Sujet'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subject}
                // disabled
                />
                {touched.subject && errors.subject && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.subject}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='row mb-6'>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Message</label>

                <textarea
                  className='form-control border-1 p-0 ps-3 pt-1 pe-10 resize-none min-h-25px'
                  rows={6}
                  placeholder='Message'
                  name='message'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}


                ></textarea>
                {touched.message && errors.message && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.message}</div>
                  </div>
                )}
              </div>

            </div>

          </div>

          <div className='card-footer d-flex justify-content-start py-6 px-9'>
            <SubmitButton isLoading={isloading} buttonLabel='Envoyer' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default EmailResponse