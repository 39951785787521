import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useApiRequest } from '../../helpers/ApiRequest'
import { ReOrderSliderProps, sliderProps } from '../../helpers/Props'
import { DeleteNotify, FrCustomeErrorNorify } from '../../helpers/Toastify'
import { useReOrderSliderContext } from '../../context/ReOrderSliderContext'
import ReOrderSliderModal from '../SliderOrder/ReOrderSliderModal'

const SlidersList: React.FC = () => {
  const [sliders, setSliders] = useState<sliderProps[]>([]);
  const apiRequest = useApiRequest()
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { card, setCard } = useReOrderSliderContext();

  const showOrderModal = () => {
    setShowModal(true);
  };

  const hideOrderModal = () => {
    setShowModal(false);
  }

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: 'sliders.json',
        method: 'GET',
      });

      setSliders(response.data)
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };
  useEffect(() => {
    fetchData();
  }, [])

  let data = [];

  if (card.length > 0) {
    data = card
  } else {
    data = sliders
  }

  const ReOrderData = data.map((item: ReOrderSliderProps) => {
    return {
      id: item.id,
      image: item.image,
      orderNumber: item.orderNumber,
    };
  });


  const DeleteSlider = async (sliderId: number) => {
    try {
      const response = await apiRequest({
        route: `sliders/${sliderId}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Slider');
        fetchData();
      }
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  return (

    <>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste Des sliders</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
          </h3>
          <div className='card-toolbar'>
            <Link to='/slider/add-slider' className='btn btn-sm btn-light-primary'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Nouveau Slider
            </Link>
            <button onClick={() => showOrderModal()} className='btn btn-dark btn-sm ms-2 fw-bold'  >
              <i className='bi bi-filter fs-4'></i>
              Change Order
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'>Image</th>
                  <th className='ps-4 min-w-125px'>Title</th>
                  {/* <th className='min-w-200px'>Description</th> */}
                  <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {sliders.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-70px'>
                            <img
                              src={`${process.env.REACT_APP_API_UPLOAD}/slider_images/${item.image}`}
                              className='w-100'
                              alt=''
                            />
                          </div>
                        </div>
                      </td>

                      <td>
                        <span className='text-dark  text-primary d-block mb-1 fs-6'>
                          {item.title}
                        </span>
                      </td>
                      {/* <td>
                        <span className='text-dark  text-primary d-block mb-1 fs-6'>
                          {item.description ? item.description : 'Aucune Description'}
                        </span>
                      </td> */}
                      <td className='text-end'>
                        <Link to={`/slider/update-slider/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                          <i className='bi bi-pen-fill fs-4'></i>
                        </Link>
                        <button onClick={() => DeleteSlider(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                          <i className='bi bi-trash-fill fs-4'></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        <ReOrderSliderModal order={ReOrderData} show={showModal} onHide={hideOrderModal} fetch={() => fetchData()} />
      </div>
    </>

  )
}

export default SlidersList