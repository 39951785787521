import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { userProps } from '../../helpers/Props'
import { HiOutlineRefresh } from 'react-icons/hi'
import { Loading } from '../../components/Loading';
import { Pagination } from '@mui/material'
import axios from 'axios';
import { FrCustomeErrorNorify } from '../../helpers/Toastify'

const UsersList: React.FC = () => {
  const [users, setUsers] = useState<userProps[]>([]);

  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [filterTotalItems, setFilterTotalItems] = useState(0)
  const [inputFilter, setInputFilter] = useState(false)
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<null | string>(null);


  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      });

      setUsers(response.data['hydra:member']);
      setTotalItems(response.data['hydra:totalItems']);
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));

    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  const filterData = async () => {
    setIsFilter(true);
    setIsFilterLoading(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users.jsonld`, {
        params: {
          fullName: fullName || '',
          roles: role || '',
          email: email || '',
          page: filterCurrentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      });

      if (response.status === 200) {
        setUsers(response.data['hydra:member']);
        setFilterTotalItems(response.data['hydra:totalItems']);
        setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
        setIsFilterLoading(false);
      }
      else {
        FrCustomeErrorNorify()
        setIsFilterLoading(false);
      }
    } catch (error) {
      FrCustomeErrorNorify()
      setIsFilterLoading(false);
    }
  };


  function handlePageChange(event: any, value: any) {
    setCurrentPage(value);
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value);
  }


  useEffect(() => {
    fetchData();
  }, [currentPage, totalItems])

  useEffect(() => {
    if (isFilter) {
      filterData();
    }
  }, [filterCurrentPage])

  useEffect(() => {
    if (role !== null || fullName !== '' || email !== '') {
      setInputFilter(true);
    }
  }, [role, fullName, email])

  const resetFilter = () => {
    setRole(null);
    setFullName('')
    setEmail('')
    setIsFilter(false)
    setInputFilter(false)
    setCurrentPage(1)
    setFilterCurrentPage(1)
    fetchData();
  }


  return (

    <>

      {/* Start Filter Card */}
      <div className={`card mb-4 py-5`}>

        <div className='card-body py-3'>
          <div className="container">
            <div className="row justify-content-start align-items-center">

              {/* Start Client Name */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Nom complet
                </label>
                <input className="form-control " type="search" aria-label="Search" placeholder='Nom complet' value={fullName} onChange={(e) => setFullName(e.target.value)} />

              </div>
              {/* End Client Name */}

              {/* Start Email */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Email
                </label>
                <input className="form-control " type="text" aria-label="Email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />

              </div>
              {/* End Email */}



              {/* Start Roles */}
              <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Roles
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>

                  {/* All */}

                  <input
                    type='checkbox'
                    name='role'
                    id='all'
                    className='form-check-input scale-1'
                    checked={role === ''}
                    onChange={() => setRole('')}
                    value={''}
                  />
                  <label htmlFor='' className='ms-3 col-form-label fw-bold fs-5'>
                    Toutes les roles
                  </label>

                  {/* Admin */}

                  <input
                    type='checkbox'
                    name='role'
                    id='ROLE_ADMIN'
                    className='form-check-input ms-4 scale-1'
                    checked={role === 'ROLE_ADMIN'}
                    onChange={() => setRole('ROLE_ADMIN')}
                    value={'ROLE_ADMIN'}
                  />
                  <label htmlFor='ROLE_ADMIN' className='ms-3 col-form-label fw-bold fs-5'>
                    Administrateur
                  </label>


                  {/* User */}

                  <input
                    type='checkbox'
                    name='role'
                    id='ROLE_USER'
                    className='form-check-input ms-4 scale-1'
                    checked={role === 'ROLE_USER'}
                    onChange={() => setRole('ROLE_USER')}
                    value={'ROLE_USER'}
                  />
                  <label htmlFor='ROLE_USER' className='ms-3 col-form-label fw-bold fs-5'>
                    Utilisateur
                  </label>

                </div>
              </div>
              {/* End role */}

              {/* Start Apply Filter */}
              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                {
                  !isFilterLoading ?
                    <>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                      </label>
                      <button className='btn btn-light-primary me-3 fw-bold' onClick={filterData} disabled={!inputFilter} >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                        Filtrer
                      </button>

                    </>

                    :
                    <div className="me-3 mt-4 d-flex justify-content-start">
                      <Loading />
                    </div>
                }
              </div>
              {/* End Apply Filter */}

              {/* Start Reset Filter */}
              <div className="reset-filter ">
                <div className="ico" onClick={resetFilter}>
                  <HiOutlineRefresh className='filter-icon sshadow' />
                </div>
              </div>
              {/* End Reset Filter */}

            </div>
          </div>
        </div>

      </div>
      {/* Ens Filter Card */}

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste Des Utilisateurs</span>
          </h3>
          <div className='card-toolbar'>
            <Link to='/user/add-user' className='btn btn-sm btn-light-primary'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Nouveau Administrateur
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-150px rounded-start'>Nom</th>
                  <th className='ps-4 min-w-150px'>Prenom</th>
                  <th className='ps-4 min-w-125px'>Telephone</th>
                  <th className='ps-4 min-w-125px'>Email</th>
                  <th className='min-w-150px'>Role</th>
                  <th className='min-w-150px'>Connecté par</th>
                  {/* <th className='min-w-100px text-end rounded-end pe-5'>Action</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {users.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        {
                          item.nom ?
                            <span className='text-dark text-primary d-block mb-1 fs-6'>
                              {item.nom}
                            </span>
                            :
                            <span className='badge badge-light-dark fs-8 p-2'>
                              Non ajouté
                            </span>
                        }
                      </td>
                      <td>
                        {
                          item.prenom ?
                            <span className='text-dark text-primary d-block mb-1 fs-6'>
                              {item.prenom}
                            </span>
                            :
                            <span className='badge badge-light-dark fs-8 p-2'>
                              Non ajouté
                            </span>
                        }
                      </td>
                      <td>
                        {
                          item.telephone ?
                            <span className='text-dark text-primary d-block mb-1 fs-6'>
                              {item.telephone}
                            </span>
                            :
                            <span className='badge badge-light-dark fs-8 p-2'>
                              Non ajouté
                            </span>
                        }
                      </td>
                      <td>
                        {
                          item.loginWith === 'facebook' ?
                            <span className='badge badge-light-primary fs-8 p-2'>
                              Connecté par facebook
                            </span>
                            :
                            <span className='text-dark text-primary d-block mb-1 fs-6'>{item.email}</span>
                        }
                      </td>
                      <td>
                        <span className={`${item.roles.includes('ROLE_ADMIN') ? 'text-info' : 'text-dark'}  fw-bold d-block mb-1 fs-6`}>
                          {item.roles.includes('ROLE_ADMIN') ? 'Administrateur' : 'Utilisateur'}
                        </span>
                      </td>

                      <td>
                        {
                          item.loginWith === 'facebook' ?
                            <span className='badge badge-light-primary fs-8 px-3 justify-content-center min-w-50 py-2'>
                              Facebook
                            </span>
                            :
                            item.loginWith === 'google' ?
                              <span className='badge badge-light-success fs-8 px-3 justify-content-center min-w-50 py-2'>
                                Google
                              </span>
                              :
                              item.loginWith === 'email' ?
                                <span className='badge badge-light-info fs-8 px-3 justify-content-center min-w-50 py-2'>
                                  Email
                                </span>
                                :
                                null
                        }
                      </td>

                      {/* <td className='text-end'>
                        <a href='#' className='btn btn-icon btn-light-primary btn-sm me-3'>
                          <i className='bi bi-pen-fill fs-4'></i>
                        </a>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      {
        isFilter
          ? <Pagination
            count={filterTotalPages}
            page={filterCurrentPage}
            color='primary'
            onChange={handleFilterPageChange}
            className='d-flex justify-content-center'
          />
          :
          <Pagination
            count={totalPages}
            page={currentPage}
            color='primary'
            onChange={handlePageChange}
            className='d-flex justify-content-center'
          />
      }
    </>

  )
}

export default UsersList
