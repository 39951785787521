import React from 'react'

export const Loading = () => {
  return (
    <div className="d-flex justify-content-end">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading....</span>
    </div>
  </div>
  )
}