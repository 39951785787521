import { FC } from 'react'
interface ItemProps {
    value: string
    label: string
    style?: string
}

const OrderAdresseItem: FC<ItemProps> = ({ value, label, style }) => {
    return (
        <p className={style}><span className='ms-5 fs-4 min-w-150px d-inline-block'>{label} :</span> <span className='fs-5 fw-bold'>{value}</span></p>
    )
}

export default OrderAdresseItem