import React from 'react';
import { Modal } from 'react-bootstrap';
import OrderAdresseItem from './OrderAdresseItem';

interface OrderDetailsModalProps {
  order: any;
  show: boolean;
  onHide: () => void;
}

const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({ order, show, onHide }) => {

  const facturation = order?.facturationAdresse
  const livraison = order?.livraisonAdresse

  const GetAddress = ({ label, address }: any) => (
    <>
      <h3 className='text-info mb-5'>{`Adresse de ${label}`} :</h3>
      <OrderAdresseItem label='Nom et Prenom' value={`${address?.nom} ${address?.prenom}`} />
      <OrderAdresseItem label='Telephone' value={`${address?.telephone}`} />
      <OrderAdresseItem label='Adresse Email' value={`${address?.email}`} />
      {address?.entreprise && <OrderAdresseItem label={`Nom de l'entreprise`} value={`${address?.entreprise}`} />}
      <OrderAdresseItem label='N° et Nom de rue ' value={`${address?.rueInfo}`} />
      <OrderAdresseItem label='Ville' value={`${address?.ville}`} />
      {address?.region && <OrderAdresseItem label='Region' value={`${address?.region}`} />}
      {address?.codepostal && <OrderAdresseItem label='Code Postal' value={`${address?.codepostal}`} />}
      <OrderAdresseItem label='Pays' value={`${address?.pays}`} />
      {address?.apartement && <OrderAdresseItem label={`Apartement`} value={`${address?.apartement}`} />}
    </>
  )


  return (
    <Modal show={show} onHide={onHide} centered dialogClassName='order-adresse-modal'>
      <Modal.Header closeButton>
        <Modal.Title className='fs-3'>Details de Commande | N°: <span className='text-info'>{order?.reference}</span> <p><span className='fs-4 min-w-150px d-inline-block'>Methode de payemet :</span> <span className='fs-5 fw-bold'>{`${order?.paymentMethod === 'livraison' ? 'À la livraison' : 'Par Carte bancaire'}`}</span></p></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Image</th>
                <th className='ps-4 min-w-300px rounded-start'>Nom Produit / Pack</th>
                <th className='min-w-100px'>Reference</th>
                <th className='min-w-100px'>Quantité</th>
                <th className='ps-4 min-w-100px'>Prix Unitaire</th>
              </tr>
            </thead>
            <tbody>
              {order?.orderItems.map((o: any, index: number) => (
                <tr key={index}>
                  <td className='pb-0'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px'>
                        <img
                          src={`${process.env.REACT_APP_API_UPLOAD}/${o.product
                            ?
                            `product_images/${o.product?.image}`
                            :
                            `pack_images/${o.pack?.image}`}`}
                          className='h-70px w-100px h-lg-70px of-scale'
                          alt=''
                        />
                      </div>
                    </div>
                  </td>
                  <td className='pb-0'>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>
                      {`${o.product ? o.product?.title : o.pack?.title}`}
                    </span>
                  </td>

                  <td className='hb-t- hb-p-0'>
                    {o.product ?
                      <span className='text-info fw-bold d-block ml-13 fs-order'>
                        {o.product?.reference}
                      </span>
                      :
                      <span className='badge badge-light-dark fs-8 p-2'>
                        Auccun
                      </span>
                    }
                  </td>

                  <td className='pb-0'>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>
                      <span className="text-info">{o.quantity}</span>
                    </span>
                  </td>

                  <td className='pb-0'>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>
                      <span className="text-info">{o.price}</span> DH
                    </span>
                  </td>

                </tr>
              ))
              }
            </tbody>
          </table>
        </div>

        {/* <hr className='hr-tag' /> */}
        {/* <h3 className='text-info mb-5'>Autre Informations :</h3> */}
        {/* <div className="content">
          <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Methode de payemet :</span> <span className='fs-5 fw-bold'>{`${order?.paymentMethod === 'livraison' ? 'À la livraison' : 'Par Carte bancaire'}`}</span></p>
          <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Reference :</span> <span className='fs-5 fw-bold'>{`${order?.reference}`}</span></p>
          {order?.orderNote &&
            (<>
              <p>
                <span className='ms-5 fs-4 min-w-150px d-inline-block'>
                  Remarque :
                </span>
              </p>
              <p className='fs-5 fw-bold ms-5'>
                {`${order?.orderNote}`}
              </p>
            </>
            )
          }
        </div> */}

        <hr className='hr-tag' />

        <div className='d-flex justify-content-between'>
          <div>
            <GetAddress label='facturation' address={facturation} />
          </div>
          {/* <hr className='hr-tag' /> */}
          <div>
            <GetAddress label='livraison' address={livraison} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OrderDetailsModal;