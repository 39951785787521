import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useApiRequest } from '../../helpers/ApiRequest';
import { useParams } from 'react-router-dom';
import { UFormatDate } from '../../helpers/ApiRequest';
import { UpdateNotify, FrCustomeErrorNorify, errorNotify } from '../../helpers/Toastify';
import { ProductProps } from '../../helpers/Props';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import SubmitButton from '../../components/submitButton/submitButton';
import { promotionSchema } from '../../helpers/validation/YupSchema';
import { initPromotion } from '../../helpers/validation/FormikHelper';

const UpdatePromotion = () => {
  const [isloading, setIsLoading] = useState(false)
  const [isloadingProducts, setIsLoadingProducts] = useState(false)
  const [status, setStatus] = useState(1);
  const apiRequest = useApiRequest()
  const { id } = useParams();
  const animatedComponents = makeAnimated();
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [isChange, setIsChange] = useState(false);
  const [isPriceFilled, setIsPriceFilled] = useState(false);
  const [isDiscountFilled, setIsDiscountFilled] = useState(false);

  const formik = useFormik({
    initialValues: initPromotion,
    validationSchema: promotionSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      let data;

      if (values.price === "" && values.discount === "") {
        errorNotify('Insérer le prix ou le remise')
        setIsLoading(false)
        return
      }

      if (isChange) {
        data = { ...values, price: parseFloat(values.price), discount: parseInt(values.discount), status: status, product: selectedProducts };
      }
      else {
        data = { ...values, price: parseFloat(values.price), discount: parseInt(values.discount), status: status };
      }

      try {
        const response = await apiRequest({
          route: `promotions/${id}`,
          method: 'PUT',
          body: data
        });

        if (response.status === 200) {
          UpdateNotify('Promotion');
          setIsLoading(false)
          fetchPromotionData();
        }
      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)
      }

    },
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;

  const fetchPromotionData = useCallback(async () => {
    try {
      setIsLoadingProducts(true)

      const response = await apiRequest({
        route: `promotions/${id}.json`,
        method: 'GET',
      });

      if (response.status === 200) {
        await setValues({
          price: response.data.price !== null ? response.data.price : null,
          discount: response.data.discount !== null ? response.data.discount : null,
          dateDebut: UFormatDate(response.data.dateDebut),
          dateFin: UFormatDate(response.data.dateFin),
        });

        setStatus(response.data.status);

        const promotionItems = response.data.product.map((product: any) => ({
          value: `api/products/${product.id}`,
          label: `${product.title} | ${product.tarif_regulier} DH`,
        }));

        response.data.discount !== null ?? setIsDiscountFilled(true)
        response.data.price !== null ?? setIsPriceFilled(true)

        setDefaultOptions(promotionItems);
        setIsLoadingProducts(false)

      } else {
        FrCustomeErrorNorify()
      }

    } catch (error) {
      FrCustomeErrorNorify()
    }
  }, [id, setValues]);



  const fetchProductsData = async () => {
    try {
      const response = await apiRequest({
        route: 'products-by-price',
        method: 'GET',
      });

      if (response.status === 200) {
        setProducts(response.data.products)
      }
    } catch (error) {
      FrCustomeErrorNorify()
      setIsLoadingProducts(false)
    }
  };


  useEffect(() => {
    fetchProductsData();
    fetchPromotionData();
  }, [])


  const options = products.map((product: any) => ({
    value: `api/products/${product.id}`,
    label: `${product.title} | ${product.tarif_regulier} DH`,
  }));

  const handleChangeSelect = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => (option.value));
    setSelectedProducts(selectedValues);
    setIsChange(true);
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'price') {
      setIsPriceFilled(!!value);
      setIsDiscountFilled(false);
      formik.setFieldValue('discount', '');
    } else if (name === 'discount') {
      setIsDiscountFilled(!!value);
      setIsPriceFilled(false);
      formik.setFieldValue('price', '');
    }

    formik.handleChange(e);
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Modifier Promotion</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Prix</label>
                <input
                  type='text'
                  name='price'
                  className={`form-control form-control-lg form-control-solid mb-3 mb-lg-0 ${isDiscountFilled ? 'bg-secondary' : ''}`}
                  placeholder='Prix'
                  // {...formik.getFieldProps('price')}
                  onChange={handleFieldChange}
                  // onChange={() => {
                  //   handleFieldChange
                  //   handleChange
                  // }}
                  onBlur={handleBlur}
                  value={values.price}
                  disabled={isDiscountFilled}
                />
                {formik.touched.price && formik.errors.price && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.price}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Remise
                </label>
                <input
                  type='text'
                  name='discount'
                  min={1}
                  max={100}
                  className={`form-control form-control-lg form-control-solid mb-3 mb-lg-0 ${isPriceFilled ? 'bg-secondary' : ''}`}
                  placeholder='Remise'
                  // {...formik.getFieldProps('discount')}
                  onChange={handleFieldChange}
                  // onChange={() => {
                  //   handleFieldChange
                  //   handleChange
                  // }}
                  onBlur={handleBlur}
                  value={values.discount}
                  disabled={isPriceFilled}
                />
                {touched.discount && errors.discount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.discount}</div>
                  </div>
                )}
              </div>

            </div>


            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Date de debut de promotion
                </label>
                <input
                  type='date'
                  name='dateDebut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de debut de promotion'
                  // min={new Date().toISOString().split('T')[0]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateDebut}
                />
                {touched.dateDebut && errors.dateDebut && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.dateDebut}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Date de fin de promotion
                </label>
                <input
                  type='date'
                  name='dateFin'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de fin de promotion'
                  min={values.dateDebut}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateFin}
                  disabled={!values.dateDebut}

                />
                {touched.dateFin && errors.dateFin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.dateFin}</div>
                  </div>
                )}
              </div>

            </div>

            <div className="row mb-6">
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Remise status
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>
                  <input
                    type='radio'
                    name='status'
                    id='active'
                    className='form-check-input form-check-disponibilite'
                    checked={status === 1}
                    onChange={() => setStatus(1)}
                    onBlur={handleBlur}
                    value={1}
                  />
                  <label htmlFor='active' className='ms-2 col-form-label fs-5 fw-bold'>
                    Active
                  </label>

                  <input
                    type='radio'
                    name='status'
                    id='desactive'
                    className='form-check-input form-check-deactive form-check-disponibilite ms-4'
                    checked={status === 0}
                    onChange={() => setStatus(0)}
                    onBlur={handleBlur}
                    value={0}
                  />
                  <label htmlFor='desactive' className='ms-2 col-form-label fs-5 fw-bold'>
                    Desactive
                  </label>

                </div>
              </div>
            </div>

            <div className="row mb-6">
              <div className='col-lg-8 col-md-8 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Produits
                </label>

                {
                  isloadingProducts
                    ?
                    <span className='indicator-progress fs-4 fw-bold text-primary' style={{ display: 'block' }}>
                      Chargement Des Produits...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    :
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      defaultValue={defaultOptions}
                      options={options}
                      onChange={handleChangeSelect}
                    />
                }

              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
          </div>
        </form>
      </div>
    </div>

  );
};

export default UpdatePromotion;