import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  StatisticsWidget5,
  ListsWidget1,
} from '../../../_metronic/partials/widgets'
import { useEffect, useState } from 'react'
import { useApiRequest } from '../../helpers/ApiRequest'

const DashboardPage = () => {

  const [todaySalles, setTodaySalles] = useState<any>(null)
  const [AnnualSalles, setAnnualSalles] = useState<any>(null)
  const [totalOrders, setTotalOrders] = useState<any>(null)
  const [productsSold, setProductsSold] = useState<any>(null)
  // const [myDate, setMydate] = useState<any>(null)
  const apiRequest = useApiRequest()

  const getSAlles = async () => {
    try {
      const todayResponse = await apiRequest({
        route: 'today-salles',
        method: 'GET',
      })
      const annualResponse = await apiRequest({
        route: 'annual-salles',
        method: 'GET',
      })
      const ordersResponse = await apiRequest({
        route: 'today-orders',
        method: 'GET',
      })
      const productsSoldResponse = await apiRequest({
        route: 'today-products-sold',
        method: 'GET',
      })

      setTodaySalles(todayResponse.data.totalSalles)
      setAnnualSalles(annualResponse.data.totalSalles)
      setTotalOrders(ordersResponse.data.totalOrders)
      setProductsSold(productsSoldResponse.data.productsSold)

    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  // useEffect(() => {
  //   const currentDate = new Date()
  //   const year = currentDate.getFullYear()
  //   const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  //   const day = String(currentDate.getDate()).padStart(2, '0')
  //   const dated = `${year}-${month}-${day}`
  //   // console.log(dated)
  //   setMydate(dated)
  // }, [])

  useEffect(() => {
    getSAlles()
  }, [])

  // console.log(AnnualSalles);


  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3 my-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin003.svg'
            color='body-white'
            iconColor='dark'
            title='Revenus Quotidiens'
            // myDate={myDate}
            description={`${todaySalles=== null || todaySalles=== 0 ? todaySalles : parseFloat(todaySalles).toFixed(2)} DH`}
            titleColor='gray-900'
            descriptionColor='dark'
          />
        </div>

        <div className='col-xl-3 my-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
            color='secondary'
            iconColor='dark'
            title='Commandes Du Jour'
            // myDate={myDate}
            description={`${totalOrders} Commande`}
            titleColor='dark'
            descriptionColor='dark'
          />
        </div>

        <div className='col-xl-3 my-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen008.svg'
            color='primary'
            iconColor='white'
            title='Produits Vendus'
            description={`${productsSold} Produits`}
            titleColor='white'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3 my-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/arrows/arr070.svg'
            color='dark'
            iconColor='gray-100'
            title='Revenus Annuels'
            description={`${AnnualSalles === null || AnnualSalles === 0 ? AnnualSalles : parseFloat(AnnualSalles).toFixed(2)} DH`}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>
      </div>
      {/* end::Row */}

      <div className='  '>
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='400px'
        />
      </div>

      <div className=''>
        <ListsWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-8 d-none'>
        {/* begin::Col */}
        <div className='col-xl-4'>
          <ListsWidget1 className='card-xl-stretch mb-xl-8' />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-8 d-none'>
          <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8 d-none'>
        <div className='col-xxl-4'>
          <MixedWidget2
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='200px'
            strokeColor='#cb1e46'
          />
        </div>
        <div className='col-xxl-4 d-none'>
          <ListsWidget5 className='card-xxl-stretch' />
        </div>
        <div className='col-xxl-4'>
          <MixedWidget10
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
          <MixedWidget11
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8 d-none'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8  d-none'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
          {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
        </div>
      </div>
      {/* end::Row */}

    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }