import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReOrderWrapper from './ReOrderWrapper';
import SubmitButton from '../../components/submitButton/submitButton';
import { useFormik } from 'formik';
import { useApiRequest } from '../../helpers/ApiRequest';
import { notify } from '../../helpers/Toastify';
import { sliderProps } from '../../helpers/Props';

interface ReOrderSliderModalProps {
  order: any;
  show: boolean;
  onHide: () => void;
  fetch: () => void;

}

const ReOrderSliderModal: React.FC<ReOrderSliderModalProps> = ({ order, show, onHide, fetch }) => {
  const [isloading, setIsLoading] = useState(false)
  const apiRequest = useApiRequest()
  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      setIsLoading(true)

      const ReOrderData = order.map((item: sliderProps, index: number) => {
        return {
          id: item.id,
          order: index,
        };
      });

      // console.log(ReOrderData);
      setIsLoading(false)
      // return

      const response = await apiRequest({
        route: `slider/update-order`,
        method: 'POST',
        body: ReOrderData
      });

      if (response.status === 200) {
        notify(response.data.message)
        setIsLoading(false)
        onHide()
        fetch()
      }

    },
  });

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className='fs-3'>Slider Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <ReOrderWrapper sliders={order} />
          <SubmitButton buttonLabel='Enregistrer' isLoading={isloading} />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ReOrderSliderModal;