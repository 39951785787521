import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom';
import { useApiRequest } from '../../helpers/ApiRequest';
import { UpdateNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { compressAndAppendImage } from '../../helpers/imageProccessing/Imagecompressor';
import SubmitButton from '../../components/submitButton/submitButton';

const sliderSchema = (isUpdate: boolean = false) => {
  let schema = Yup.object().shape({
    title: Yup.string()
    // description: Yup.string(),
  });

  if (!isUpdate) {
    schema = schema.shape({
      image: Yup.string().required("L'image est obligatoire"),
    });
    schema = schema.shape({
      mobileImage: Yup.string().required("L'image est obligatoire"),
    }).test("fileType", "Unsupported file format", (value) => {
      return value && ["image/jpeg", "image/png", "image/svg", "image/jpg", "image/webp"].includes(value.type)
    });
  }

  return schema;
};

const UpdateSlider: React.FC = () => {

  const { id } = useParams();
  const apiRequest = useApiRequest()
  const [newImage, setNewImage] = useState<File | null>(null);
  const [newMobileImage, setNewMobileImage] = useState<File | null>(null)
  const [isloading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `sliders/${id}.json`,
        method: 'GET',
      });

      setValues({
        title: response.data.title,
        // description: response.data.description,
        image: response.data.image,
        mobileImage: response.data.mobileImage
      })

    } catch (error) {
      FrCustomeErrorNorify()
    }
  };
  useEffect(() => {
    fetchData();
  }, [])


  const formik = useFormik({
    initialValues: {
      title: "",
      // description: "",
      image: "",
      mobileImage: ""
    },
    validationSchema: sliderSchema(true),
    onSubmit: async (values, { resetForm }) => {

      try {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('title', values.title);
        // formData.append('description', values.description);

        if (newImage) {
          await compressAndAppendImage(newImage as File, formData, 'imageFile', 0.8)
        }

        if (newMobileImage) {
          await compressAndAppendImage(newMobileImage as File, formData, 'mobileImageFile', 0.8)
        }

        const response = await apiRequest({
          route: `update-slider/${id}`,
          method: 'POST',
          body: formData,
        });

        if (response.status === 200) {
          setIsLoading(false)
          navigate('/slider/slider-list');
          UpdateNotify('Slider');
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)
      }

      resetForm()
    },
  })

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };

  const handleMobileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewMobileImage(e.target.files[0])
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Modifier Un Slider</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Title</label>

                    <input
                      type='text'
                      name='title'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Title'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image  | Desktop</label>
                <input
                  type="file"
                  name="image"
                  accept=".png, .jpg, .jpeg, .webp"
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={handleImageChange} />

                {newImage ? (
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-70px'>
                      <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-70px'>
                    <img
                      src={`${process.env.REACT_APP_API_UPLOAD}/slider_images/${values.image}`}
                      className='w-100 shadow'
                      alt=''
                    />
                  </div>
                </div>

              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image | Mobile</label>
                <input
                  type="file"
                  name="mobileImage"
                  accept=".png, .jpg, .jpeg, .webp"
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={handleMobileImageChange} />

                {newMobileImage ? (
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-70px'>
                      <img src={URL.createObjectURL(newMobileImage)} alt='' className='w-100 shadow mt-2' />
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-70px'>
                    <img
                      src={`${process.env.REACT_APP_API_UPLOAD}/slider_images/${values.mobileImage}`}
                      className='w-100 shadow'
                      alt=''
                    />
                  </div>
                </div>

              </div>
            </div>

            {/* <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <input
                  type='text'
                  name='description'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
              </div>
            </div> */}

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SubmitButton isLoading={isloading} buttonLabel='Enregistrer les modifications' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default UpdateSlider