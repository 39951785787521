import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDatex, useApiRequest, formatPrice } from '../../helpers/ApiRequest';
import { OrderProps } from '../../helpers/Props';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { FrCustomeErrorNorify } from '../../helpers/Toastify';
import './OrderFacture.css'
import axios from 'axios';
import { calculateTotalPrice, fetchBase64ImagesState } from '../../helpers/Helpers';

const OrderFacture: React.FC = () => {
  const apiRequest = useApiRequest();
  const [order, setOrder] = useState<OrderProps>();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [base64Images, setBase64Images] = useState<(any | null)[]>([]);


  const { id } = useParams();

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `orders/${id}.json`,
        method: 'GET',
      });

      setOrder(response.data);
      setTotalPrice(Number(response.data.totalPrice));
    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  const getBase64Z = async (imageUrl: string, entity: string) => {
    try {
      const formData = new FormData()
      formData.append('imageUrl', imageUrl)
      formData.append('entity', entity)

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/convert-blob`, formData,
        {
          responseType: 'blob',
        });

      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {

          return reader.result
        }
      }

    } catch (error) {
      FrCustomeErrorNorify()
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (order) {
      fetchBase64ImagesState(order, setBase64Images);

    }
  }, [order]);


  return (
    <div className="hb-container">
      <div className={`card hb-card  hb-d-flex hb-justify-content-center `}>
        <div className='card-body py-3'>
          <div className="">
            <div className="row hb-m-row">
              <div className="hb-logo-container">
                <img src={toAbsoluteUrl('/media/logos/HB-icon.png')} alt='' className='hb-logo' />
              </div>
            </div>

            <div className="row row-user">
              <div className="hb-m-client">
                <div className="hb-user-info">
                  <p className='fw-bold hb-user-info-client'><span>Expéditeur :</span></p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>Hbeauty</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>Km 13 Rte El Jadida Douar Lahfafra Casablanca</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>Tél: 05 22 335356</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>RC: 465087- ICE: 002477493000053</span>
                  </p>
                </div>

                <div className="hb-user-info">
                  <p className='fw-bold hb-user-info-client'><span>Client :</span></p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{`${order?.user.prenom} ${order?.user.nom}`}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.user.telephone}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.livraisonAdresse.rueInfo}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.livraisonAdresse.region}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.livraisonAdresse.ville}</span>
                  </p>
                </div>

                <div className="hb-user-info">
                  {/* <p className='fw-bold hb-user-info-client'><span>Facturation :</span></p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{`${order?.facturationAdresse.prenom} ${order?.facturationAdresse.nom}`}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.facturationAdresse.telephone}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.facturationAdresse.rueInfo}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.facturationAdresse.region}</span>
                  </p>
                  <p className='hb-info-value-p'>
                    <span className='hb-info-value'>{order?.facturationAdresse.ville}</span>
                  </p> */}
                </div>
              </div>

            </div>

          </div>
          {/* end container */}


          <div className="row">
            <p className='fw-bold hb-refrence'>Bon de Livraison No {order?.reference}</p>
            <p className=''>Mmme/Mr {order?.user.prenom} {order?.user.nom}</p>
            <p className=''>Merci beaucoup pour votre commande et la confiance que vous nous accordez!</p>
          </div>

          <hr className='hrtag' />

          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4  rounded-star tb-radius hb-t-dark'>Image</th>
                  <th className='ps-4  rounded-star tb-radius hb-t-dark'>Produit</th>
                  <th className='ps-4  rounded-star tb-radius hb-t-dark'>Réf</th>
                  <th className='hb-t-center hb-t-dark'>Qte</th>
                  <th className='ps-4 hb-txt-end hb-t-dark min-with-th-pu'>Prix Unitaire</th>
                  <th className='ps-4 hb-txt-end hb-t-dark min-with-th-total'>Total</th>
                </tr>
              </thead>
              <tbody>
                {order?.orderItems.map((o: any, index: number) => (

                  <tr key={index} className='item-row'>
                    <td className={`hb-p-0`}>
                      {/* <div className='hb-d-flex align-items-center'> */}
                      {/* <div className='symbol symbol-50px'> */}
                      {/* <img
                            src={`${process.env.REACT_APP_API_UPLOAD}/${o.product
                              ?
                              `product_images/${o.product?.image}`
                              :
                              `pack_images/${o.pack?.image}`}`}
                            className='hb-lg-50px hb-w-100px hb-of-scale'
                            alt=''
                          /> */}

                      <img
                        src={base64Images[index]}
                        className="hb-lg-50px hb-w-100px hb-of-scale"
                        alt=""
                      />

                      {/* </div> */}
                      {/* </div> */}
                    </td>

                    <td className={`hb-p-0`}>
                      <span className='text-dark d-block ml-13 fs-order'>
                        {`${o.product ? o.product?.title : o.pack?.title}`}
                      </span>
                    </td>

                    <td className='hb-t- hb-p-0'>
                      <span className='text-dark d-block ml-13 fs-order'>
                        {`${o.product ? o.product?.reference : 'auccun'}`}
                      </span>
                    </td>

                    <td className='hb-t-center hb-p-0'>
                      <span className='fw--bold text-dark d-block fs-5'>
                        {o.quantity}
                      </span>
                    </td>

                    <td className='hb-t-end hbp-0 item-td'>
                      <span className='fw--bold text-dark d-block fs-7'>
                        {formatPrice(o.price)}
                      </span>
                    </td>

                    <td className='hb-t-end hbp-0 item-td'>
                      <span className='fw-bold text-dark d-block fs-7'>
                        {formatPrice(String(o.price * o.quantity))}
                      </span>
                    </td>

                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>


          <div className="hr-container">
            <hr className='hrtag' />
            <div className="row price-section-row">

              <div className="price-section-text w-50">
                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-price-text'>Sous-total : </p>
                </div>

                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-price-text'>Expédition : </p>
                </div>

                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-price-text'>Moyen de paiement : </p>
                </div>

                {order?.paymentMethod === 'carte' ?
                  <div className=" hb-d-flex hb-justify-content-start">
                    <p className='hb-price-text'>Remise de 5% : </p>
                  </div>
                  :
                  null
                }

                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-price-text'>Total : </p>
                </div>
              </div>

              <div className="price-section-value  w-50">
                <div className=" hb-d-flex hb-justify-content-end">
                  {/* <p className='hb-price-value fw-boldd'>{formatPrice(String(order?.totalPrice))} MAD</p> */}
                  {/* <span className='hb-price-value fw-bold'>{totalPrice > 300 ? formatPrice(String(totalPrice)) : formatPrice(String((totalPrice) - 50))} MAD</span> */}
                  <span className='hb-price-value fw-bold'>{order ? formatPrice(String(calculateTotalPrice(order.orderItems))) : null} MAD</span>
                </div>

                <div className=" hb-d-flex hb-justify-content-end">
                  <p className='hb-price-value fw-boldd'>
                    {(totalPrice) > 300 ?
                      'Gratuit' : (totalPrice) < 300 ?
                        order?.paymentMethod === 'carte' ?
                          'Gratuit' : '50 MAD' : 'Gratuit'}</p>
                </div>

                <div className=" hb-d-flex hb-justify-content-end">
                  <p className='hb-price-value fw-boldd'>{order?.paymentMethod === 'carte' ? 'Par Carte bancaire' : 'À la livraison'}</p>
                </div>

                {order?.paymentMethod === 'carte' ?
                  <div className=" hb-d-flex hb-justify-content-end">
                    <p className='hb-price-value fw-boldd'>
                      {order?.paymentMethod === 'carte' ?
                        `${formatPrice(String(calculateTotalPrice(order.orderItems) * 0.05))}`
                        : order !== undefined && `0`} MAD
                    </p>
                    {/* <p className='hb-price-value fw-boldd'>{formatPrice(String(((totalPrice + 50) * 0.05)))} MAD</p> */}
                    {/* <p className='hb-price-value fw-boldd'>{totalPrice > 300 ? formatPrice(String(totalPrice + String(Number(order.totalPrice) - (Number(totalPrice) * 0.05)))) : formatPrice(String(Number(totalPrice) + 50))} MAD</p> */}
                  </div>
                  :
                  null
                }

                <div className=" hb-d-flex hb-justify-content-end">
                  <p className='hb-price-value fw-boldd'>
                    { // (totalPrice) > 300 ?
                      //   formatPrice(String(totalPrice)) : (totalPrice) < 300 ?
                      //     order?.paymentMethod === 'carte' ?
                      //       formatPrice(String(totalPrice)) : formatPrice(String(totalPrice + 50)) : formatPrice(String(totalPrice + 50))
                      formatPrice(String(totalPrice))
                    } MAD
                  </p>
                  {/* <p className='hb-price-value fw-boldd'>{totalPrice > 300 ? formatPrice(String(totalPrice)) : formatPrice(String(totalPrice + 50))} MAD</p> */}
                </div>
              </div>

            </div>

            {order?.orderNote ?
              <div className="row hb-mt-6">
                {/* <hr className='hrtag' /> */}

                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='fw-bold hb-user-info-client'><span>Remarque :</span></p>
                </div>

                <div className=" hb-d-flex hb-justify-content-start">
                  <p className='hb-line'>
                    <span className='fw-bold hb-pricetext hb-order-note'>{order?.orderNote} </span>
                  </p>
                </div>

              </div>
              :
              ''
            }

          </div>
        </div>

      </div>

      <div className='cardfooter hb-d-none hb-card-footer hb-align-items-end fixedbottom hb-d-flex chb-justify-content-end py-6 px-9'>
        <div className="container">
          <hr className='hrtag' />
          <div className="row hb-d-none">
            <p className='hb-infotext hb-data  hb-d-flex hb-justify-content-center hb-mb-10'>

              <span className='hb-data-text fw-bold'>I.C.E N° :</span>
              <span className='hb-data-value fw-bold'>002477493000053</span>

              <span className='separate fw-bold'> - </span>

              <span className='hb-data-text fw-bold'>RC N° :</span>
              <span className='hb-data-value fw-bold'>465087</span>

              <span className='separate fw-bold'> - </span>

              <span className='hb-data-text fw-bold'>Tel :</span>
              <span className='hb-data-value fw-bold'>05 22 335356</span>
            </p>
          </div>

          <div className="row hb-d-none">
            <p className="hb-infotext hb-data  hb-d-flex hb-justify-content-center">
              <span className='hb-data-text fw-bold'>Email :</span>
              <span className='hb-data-value fw-bold'>info@hbeauty.ma</span>

              <span className='separate fw-bold'> - </span>

              <span className='hb-data-text fw-bold'>Adresse :</span>
              <span className='hb-data-value fw-bold'>Km 13 Rte El Jadida Douar Lahfafra Casablanca </span>
            </p>
          </div>

          <div className="row row-user">
            <div className="hb-m-client">
              <div className="hb-com-info hbeauty">
                <p className=''>
                  <span className='hb-info-value'>Hbeauty</span>
                </p>
              </div>

              <div className="hb-com-info">
                <p className=''>
                  <span className='hb-info-value'>Km 13 Rte El Jadida Douar Lahfafra</span>
                </p>
                <p className=''>
                  <span className='hb-info-value'>Casablanca</span>
                </p>
                <p className=''>
                  <span className='hb-info-value'>Tél :05 22 335356</span>
                </p>
              </div>

              <div className="hb-com-info">
                <p className=''>
                  <span className='hb-info-value'>RC : 149351</span>
                </p>
                <p className=''>
                  <span className='hb-info-value'>IF : 01602789 </span>
                </p>
                <p className=''>
                  <span className='hb-info-value'>ICE : 000012487000023</span>
                </p>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default OrderFacture;