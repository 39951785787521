import React, { createContext, useContext, useState, useEffect } from 'react';
import { ReOrderSliderProps } from '../helpers/Props';

interface ReOrderSliderContextProps {
    card: ReOrderSliderProps[];
    setCard: React.Dispatch<React.SetStateAction<ReOrderSliderProps[]>>;
}

const ReOrderSliderContext = createContext<ReOrderSliderContextProps>({
    card: [],
    setCard: () => {}
});

export const useReOrderSliderContext = () => useContext(ReOrderSliderContext);

export const ReOrderSliderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [card, setCard] = useState<ReOrderSliderProps[]>([]);
    // console.log(card);
    

    return (
        <ReOrderSliderContext.Provider value={{ card, setCard }}>
            {children}
        </ReOrderSliderContext.Provider>
    );
};
